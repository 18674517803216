/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface MessagingState {
    viewDocumentAcksId: string;
}

const messagingDefaultState: MessagingState = {
    viewDocumentAcksId: undefined,
};

const { reducer: messagingReducer, actions: messagingActions } = createSlice({
    name: 'messaging',
    initialState: messagingDefaultState,
    reducers: {
        ViewDocumentAcks: (state, action: PayloadAction<string | null>) => {
            state.viewDocumentAcksId = action.payload;
        },
    },
});

export { messagingReducer, messagingActions, messagingDefaultState };
