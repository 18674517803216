import { useCallback } from 'react';
import { ActionCreator } from 'redux';
import { Action } from '@reduxjs/toolkit';

import { TA, TD } from '../../store/async-actions.js';
import useDispatch from './useDispatch.js';

export type ActionDispatcher<FN> = FN extends (...args: infer A) => infer R ? (...args: A) => R : never;

export type Dispatcher<FN> = FN extends (...args: infer A) => TA<infer R> ? (...args: A) => Promise<R> : never;

export default function useDispatcher<FN extends (...args: any[]) => Action<any>>(
    fn: FN extends ActionCreator<ReturnType<FN>> ? FN : never
): ActionDispatcher<FN>;

export default function useDispatcher<FN>(fn: FN extends (...args: any[]) => TA<any> ? FN : never): Dispatcher<FN>;

export default function useDispatcher(fn) {
    const dispatch: TD = useDispatch();

    return useCallback(
        (...args) => {
            const action = fn(...args);

            return dispatch(action);
        },
        [fn, dispatch]
    );
}
