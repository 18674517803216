import { ToolTypes } from '@labradorsports/constants';
import { createLines, defaultMove } from '../utils/index.js';
import { PluginContext } from './api.js';

export default {
    types: [ToolTypes.ASSIGN],
    generateAnchors: ({ play, frame }) => {
        const anchors = [];

        if (!play.fieldSetup && frame.assignments) {
            anchors.unshift(
                ...frame.assignments.map((asn) => {
                    return {
                        type: ToolTypes.ASSIGN,
                        id: asn.id,
                        target: asn.target,
                        ...asn.anchors[0],
                        space: 'screen' as const,
                    };
                })
            );
        }

        return anchors;
    },

    generateLines: ({ play, frame }) => {
        if (!play.fieldSetup && frame.assignments && play.viewOptions.DEFENSE.assignments) {
            return frame.assignments.flatMap(createLines);
        }

        return [];
    },

    handleDragEnd: ({ api, frame }, anchor, offset) => {
        const closest = (() => {
            const exclude = frame.hiddenGoalies();
            if (typeof anchor.target === 'number') exclude.push(frame.getPlayer(anchor.target));

            if (frame.balls.length === 1) {
                // New assigments start with a defender, existing ones get attached to an attacker
                return typeof anchor.id === 'number'
                    ? frame.findClosestAttacker(offset, exclude)
                    : frame.findClosestDefender(offset, exclude);
            }

            // For multiple balls, allow assignment to go to anyone
            return frame.findClosestPlayer(offset, exclude, [ToolTypes.BLUEPLAYER, ToolTypes.ORANGEPLAYER]);
        })();

        if (closest) {
            if (typeof anchor.id === 'number') {
                api.updateAssignment(anchor.id, anchor.target, closest.id);
            } else {
                api.updateAssignment(null, closest.id);
            }
        }
    },

    handleDrag({ frame }, anchor, offset) {
        const closest =
            frame.balls.length === 1
                ? frame.findClosestAttacker(offset, frame.hiddenGoalies())
                : frame.findClosestPlayer(offset, [frame.getPlayer(anchor.target)].concat(frame.hiddenGoalies()), [
                      ToolTypes.BLUEPLAYER,
                      ToolTypes.ORANGEPLAYER,
                  ]);

        if (closest) {
            frame.updateAssignment(anchor.id, closest.id);
        }
    },

    getDefaultMove({ frame }, type, offset) {
        const closest = frame.findClosestPlayer(offset, frame.hiddenGoalies(), frame.getTargetForToolType(type));

        if (!closest) return null;

        return defaultMove(frame.field, closest, type);
    },
} as PlayPlugin<PluginContext>;
