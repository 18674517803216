import { Component } from 'react';
import { TextField, OutlinedTextFieldProps } from '@mui/material';
import InputMask from 'react-input-mask';

// eslint-disable-next-line react/prefer-stateless-function
class Masked extends Component<any> {
    override render() {
        const {
            mask,
            maskChar,
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            inputRef,
            ...rest
        } = this.props;

        return (
            <InputMask mask={mask} maskChar={maskChar ?? '_'} alwaysShowMask={false} inputRef={inputRef} {...rest} />
        );
    }
}

interface OwnProps {
    mask?: string;
    maskChar?: string;
}

const StyledTextField: FC<Omit<OutlinedTextFieldProps, 'variant'> & OwnProps> = ({
    label,
    mask,
    maskChar,
    inputProps: passedDomInputProps = {},
    InputProps: passedInputProps = {},
    InputLabelProps: passedInputLabelProps = {},
    value,
    ...textFieldProps
}) => {
    const inputProps = {
        ...passedInputProps,
    };

    const domInputProps = {
        ...passedDomInputProps,
    };

    if (mask) {
        inputProps.inputComponent = Masked;
        domInputProps.mask = mask;
        domInputProps.maskChar = maskChar;
    }

    if (typeof label === 'undefined' || label === '') {
        textFieldProps.sx = {
            ...(textFieldProps.sx || {}),
            '& legend': {
                display: 'none',
            },
        };
    }

    return (
        <TextField
            variant="outlined"
            margin="normal"
            label={label}
            InputProps={inputProps}
            // eslint-disable-next-line react/jsx-no-duplicate-props
            inputProps={domInputProps}
            InputLabelProps={passedInputLabelProps}
            value={value}
            {...textFieldProps}
        />
    );
};

export default StyledTextField;
