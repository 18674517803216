import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material/styles/index.js';

import { isSafari } from '@labradorsports/utils';

const theme = createTheme({
    palette: {
        primary: {
            main: '#003E5E',
        },
        secondary: {
            main: '#FF6700',
            contrastText: '#fff',
        },
        success: {
            main: '#72bf00',
            contrastText: '#fff',
        },
    },
    components: {
        MuiInputLabel: {
            styleOverrides: {
                formControl: {
                    textTransform: 'uppercase',
                    '&$focused': {
                        color: '#00bcd4',
                    },
                },
            },
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    '&$error': {
                        color: 'rgb(244, 67, 54)',
                    },
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    '&$focused:not(.Mui-error) .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#00bcd4',
                    },
                    backgroundColor: 'white',
                },
                notchedOutline: {
                    textTransform: 'uppercase',
                },
            },
        },
        MuiTabs: {
            styleOverrides: {
                indicator: {
                    height: '3px',
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    whiteSpace: 'nowrap',
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                containedPrimary: {
                    '&$disabled': {
                        color: isSafari() ? '#fff' : undefined,
                    },
                },
            },
        },
        MuiFormControl: {
            styleOverrides: {
                marginNormal: {
                    marginTop: '8px',
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                select: {
                    minWidth: '0px',
                },
            },
        },
        MuiDialog: {
            defaultProps: {
                style: { maxWidth: '100%' },
            },
            styleOverrides: {
                container: {
                    maxWidth: '100%',
                },
            },
        },
    },
});

export const ThemeProvider: FC = ({ children }) => <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
