import { SimpleModal } from '../index.js';

interface Props {
    open: boolean;
    text: string;
    confirm: () => any;
    cancel: () => any;
}

const ConfirmDeleteModal: FC<Props> = ({ text, confirm, cancel, open }) => {
    const delButtons = [
        {
            text: 'Cancel',
        },
        {
            text: 'Delete',
            variant: 'contained',
            action: confirm,
            style: {
                backgroundColor: '#dc3545',
                color: 'white',
            },
        },
    ];

    return <SimpleModal text={text} buttons={delButtons} open={open} onClose={cancel} />;
};

export default ConfirmDeleteModal;
