import { movePath, arcPath } from '@labradorsports/utils';

interface Props {
    radius: number;
    completion: number;
}

const polarToCartesian = (radius: number, angleInDegrees: number) => {
    const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;

    return {
        x: radius + 1 + radius * Math.cos(angleInRadians),
        y: radius + 1 + radius * Math.sin(angleInRadians),
    };
};

const CircleProgressBar: FC<Props> = ({ radius, completion }) => {
    const startAngle = 0;

    // End angle is negative to go counter-clockwise
    const endAngle = -(completion / 100) * 360;
    const start = polarToCartesian(radius, startAngle);
    const end = polarToCartesian(radius, endAngle);

    // Subtract endAngle to make it positive
    const largeArcFlag = startAngle - endAngle > 180;

    return (
        <svg
            className="circle-progress-bar"
            style={{
                width: `${radius * 2 + 3}px`,
                height: `${radius * 2 + 3}px`,
            }}
        >
            <circle r={radius} cx={radius + 1} cy={radius + 1} />
            {completion >= 100 ? (
                <circle className="progress" r={radius} cx={radius + 1} cy={radius + 1} />
            ) : (
                <path
                    className="progress"
                    d={`${movePath(start.x, start.y)}${arcPath(radius, true, end.x, end.y, largeArcFlag)}`}
                />
            )}
        </svg>
    );
};

export default CircleProgressBar;
