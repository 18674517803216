import { createContext, useContext } from 'react';

export const SiteContext = createContext<SiteSpec>({
    env: null,
    Config: null,
    PlayConfig: null,
    ToolboxConfig: null,
    Images: null,
    Plugins: null,
});

export const useSite = (): SiteSpec => {
    return useContext(SiteContext);
};
