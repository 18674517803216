import { SocialIcon } from '../index.js';

const SocialIcons: FC = () => (
    <>
        <SocialIcon type="facebook" />
        <SocialIcon type="instagram" />
        <SocialIcon type="twitter" />
        <SocialIcon type="youtube" />
    </>
);

export default SocialIcons;
