import { GeneralErrors, createException } from '@labradorsports/utils';
import Logger from '../shared/logger.js';

export const addCordovaHandler = (logger: Logger, event: string, handler: AnyFunction) => {
    // Capture and handle any errors in cordova event handlers
    document.addEventListener(event, async (...args) => {
        try {
            await handler(...args);
        } catch (err) {
            logger.exception(createException(GeneralErrors.UNKNOWN_ERROR, { nestedError: err }));
        }
    });
};

export const awaitDeviceReady = (logger: Logger) =>
    new Promise((resolve) => {
        addCordovaHandler(logger, 'deviceready', resolve);
    });

export const isTablet = (): boolean => {
    return Math.min(window.innerWidth, window.innerHeight) > 576;
};

export type FirstArg<T extends AnyFunction> = Parameters<T>[0];
export type FirstParamArg<T extends AnyFunction> = Parameters<FirstArg<T>>;
export const promisify =
    <T extends AnyFunction>(handler: T, logger: Logger): ((arg?: FirstArg<T>) => Promise<FirstParamArg<T>[0]>) =>
    () =>
        new Promise((resolve, reject) => {
            awaitDeviceReady(logger)
                .then(() => {
                    handler(resolve, reject);
                })
                .catch((error) => {
                    throw createException(GeneralErrors.UNKNOWN_ERROR, { nestedError: error });
                });
        });
