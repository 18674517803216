import {
    Assign,
    MovePlayer,
    PassBall,
    SetPick,
    Shoot,
    Comment,
    WaveMove,
    ArrowMoveIcon,
    PickMoveIcon,
    Rebound,
    SquiggleMoveIcon,
    DrawRectangle,
    DrawEllipse,
} from '@labradorsports/assets';
import { Sites } from '@labradorsports/constants';
import SportConfigs from '@labradorsports/sports';
import PlayConfig from './play-config.js';
import { _3out2inIcon, _4out1inIcon, _5outIcon } from './images.js';

const { Sports } = SportConfigs[Sites.hoopslab];

const { ToolTypes, Formations } = PlayConfig;

const Tools = {
    Move: {
        name: 'Move',
        type: ToolTypes.MOVE,
        icon: MovePlayer,
    },
    Dribble: {
        name: 'Dribble',
        type: ToolTypes.DRIBBLE,
        icon: WaveMove,
    },
    Pick: {
        name: 'Pick',
        type: ToolTypes.PICK,
        icon: SetPick,
    },
    Pass: {
        name: 'Pass',
        icon: PassBall,
        type: ToolTypes.PASS,
    },
    Shoot: {
        name: 'Shoot',
        type: ToolTypes.SHOOT,
        icon: Shoot,
    },
    Option: {
        name: 'Option',
        type: ToolTypes.OPTION,
        icon: Assign,
    },
    Rebound: {
        name: 'Rebound',
        type: ToolTypes.REBOUND,
        icon: Rebound,
    },
    Assign: {
        name: 'Assign',
        type: ToolTypes.ASSIGN,
        icon: Assign,
    },
    Comment: {
        name: 'Comment',
        icon: Comment,
        type: ToolTypes.COMMENT,
    },
    Rectangle: {
        name: 'Rectangle',
        icon: DrawRectangle,
        type: ToolTypes.RECTANGLE,
    },
    Ellipse: {
        name: 'Ellipse',
        icon: DrawEllipse,
        type: ToolTypes.ELLIPSE,
    },
};

const drillToolGroups: any = [
    {
        ...Tools.Move,
        id: 'MOVE',
        tools: [Tools.Pick, Tools.Dribble],
    },
    {
        ...Tools.Pass,
        id: 'OFFENSE_PASS',
        tools: [Tools.Shoot, Tools.Option, Tools.Rebound],
    },
    Tools.Assign,
    Tools.Comment,
];

// Not using pre-defined Tools.Move to separate OMOVE vs DMOVE
const offenseToolGroups = [
    {
        name: 'Move',
        id: 'OFFENSE_MOVE',
        type: ToolTypes.OMOVE,
        icon: MovePlayer,
        tools: [Tools.Pick, Tools.Dribble],
    },
    {
        ...Tools.Pass,
        id: 'OFFENSE_PASS',
        tools: [Tools.Shoot, Tools.Option, Tools.Rebound],
    },
];

const defenseToolGroups = [
    {
        name: 'Move',
        type: ToolTypes.DMOVE,
        icon: MovePlayer,
    },
    Tools.Assign,
];

const otherToolGroups = [
    Tools.Comment,
    {
        name: 'Shapes',
        type: ToolTypes.RECTANGLE,
        icon: DrawRectangle,
        tools: [Tools.Rectangle, Tools.Ellipse],
    },
];

const moveShapes = [
    {
        type: ToolTypes.MOVE,
        icon: ArrowMoveIcon,
    },
    {
        type: ToolTypes.PICK,
        icon: PickMoveIcon,
    },
    {
        type: ToolTypes.DRIBBLE,
        icon: SquiggleMoveIcon,
    },
];

const formations = [
    {
        icon: _3out2inIcon,
        name: '3 out 2 in',
        type: Formations._3out2in,
    },
    {
        icon: _4out1inIcon,
        name: '4 out 1 in',
        type: Formations._4out1in,
    },
    {
        icon: _5outIcon,
        name: '5 out',
        type: Formations._5out,
    },
];

export const formationsTools = {
    [Sports.HighSchoolBBall]: formations,
    [Sports.MensCollegeBBall]: formations,
    [Sports.WomensCollegeBBall]: formations,
};

export default {
    offenseToolGroups,
    defenseToolGroups,
    otherToolGroups,
    drillToolGroups,
    moveShapes,
    formationsTools,
};
