import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useStore } from 'react-redux';

import { useMutableState, objectGet } from '@labradorsports/utils';
import { RootState, Selectors } from '../../store/index.js';

/*
 * Given a key path for the redux state, check the redux store value against the previously loaded value
 * If they do not match, trigger the given loader and update the loaded state accordingly
 * dedupeKey can be used to deduplicate a state key which is used for multiple separate loaders
 * noOlderThan sets a maximum age for a value to be acceptable (in hours)
 */
const propLoader =
    (state: RootState, store: any, getLoadedState, setLoadedState) =>
    async (key: string, actionCreator: (...args: any) => any, { noOlderThan = 24, dedupeKey = '' } = {}) => {
        const prevState = getLoadedState();

        const dedupedKey = dedupeKey ? `${key}.${dedupeKey}` : key;
        const { value: prevValue, loadedAt } = prevState[dedupedKey] ?? {};
        const value = objectGet(state, key);

        if (value && (prevValue !== value || loadedAt < new Date().getTime() - noOlderThan * 1000 * 60 * 60)) {
            await store.dispatch(actionCreator());

            setLoadedState({
                [dedupedKey]: {
                    value,
                    loadedAt: new Date().getTime(),
                },
            });
        }
    };

type ActiveHandler = (
    loadProp: ReturnType<typeof propLoader>,
    state: RootState,
    store: any,
    setLoadedState: (state: any) => void
) => Promise<any> | void;

/*
 * A list of route paths/matchers with data loading methods
 * When the data loader is called with a matching route, the corresponding loader(s) will be called
 * active will be called on every route navigation where the destination path matches
 * exit will be called when a navigation away from the matching route happens
 */
interface RouteHandler {
    pathPattern: string;
    active?: ActiveHandler;
    forceUpdate?: ActiveHandler;
    exit?: ActiveHandler;
}

const pathMatches = (pathPattern: string, pathname: string) => {
    const result = pathPattern === pathname || new RegExp(`^${pathPattern}$`).test(pathname);
    return result;
};

export default function useStateLoader(routeHandlers: RouteHandler[]) {
    const [getLoadedState, setLoadedState] = useMutableState<any>({});
    const store = useStore();
    const location = useLocation();
    const prevState = useRef(null);
    const prevLocation = useRef(null);

    const runLoaders = async (
        type: 'active' | 'exit' | 'forceUpdate',
        predicate?: (pathPattern: string) => boolean
    ) => {
        const loaders = routeHandlers
            .filter((handler) => type in handler)
            .filter(({ pathPattern }) => (predicate ? predicate(pathPattern) : true))
            .map((handler) => handler[type]);

        loaders.reduce(async (accumulator, loader) => {
            await accumulator;

            const state = store.getState();

            return loader(propLoader(state, store, getLoadedState, setLoadedState), state, store, setLoadedState);
        }, Promise.resolve());
    };

    const processRoute = async (location: any) => {
        const { pathname: prevPath } = getLoadedState();

        if (prevPath && location && prevPath !== location.pathname) {
            // pattern matches previous path but not current path
            await runLoaders(
                'exit',
                (pathPattern) => pathMatches(pathPattern, prevPath) && !pathMatches(pathPattern, location.pathname)
            );
        }

        await runLoaders('active', (pathPattern) => pathMatches(pathPattern, location.pathname));

        setLoadedState({
            pathname: location.pathname,
        });
    };

    useEffect(() => {
        const unsubscribe = store.subscribe(() => {
            const newState = store.getState();
            // When the user logs out, reset loaded state so re-login will load necessary data
            if (prevState.current?.auth.user && !newState.auth.user) {
                const loadedState = getLoadedState();
                Object.keys(loadedState).forEach((k) => setLoadedState({ [k]: undefined }));
                runLoaders('exit');
            }

            if (
                prevState.current &&
                ((!Selectors.profileLoaded(prevState.current) && Selectors.profileLoaded(newState)) ||
                    (!prevState.current.auth.authReady && newState.auth.authReady))
            ) {
                processRoute(location);
            }

            prevState.current = newState;
        });

        return () => {
            unsubscribe();

            // run all exit loaders on unmount
            runLoaders('exit');
        };
    }, []);

    useEffect(() => {
        prevLocation.current = location;
        processRoute(location);
    }, [location]);

    return async () => {
        const { pathname: prevPath } = getLoadedState();

        await runLoaders('forceUpdate', (pathPattern) => pathMatches(pathPattern, prevPath));

        await processRoute(location);
    };
}
