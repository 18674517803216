import { Sites, SpecialAnchorTypes, ToolBaseTypes } from '@labradorsports/constants';
import SportConfigs from '@labradorsports/sports';
import { arraySlidingWindow } from '@labradorsports/utils';
import { Move } from '../../models/index.js';
import { PluginContext } from '../../plugins/api.js';
import { createLines, snapMoveToPlayerLine, defaultMove, applyOffset } from '../../utils/index.js';
import PlayConfig from '../play-config.js';

const { PlayerPositions } = SportConfigs[Sites.footballlab];
const { ToolTypes } = PlayConfig;

function getDefaultMove(frame, closest, type) {
    const move = defaultMove(frame.field, closest, type, closest.id);

    const closestOffset = (offset) => applyOffset(closest.origin, offset);

    if (
        [PlayerPositions.WIDE_RECEIVER, PlayerPositions.RUNNING_BACK, PlayerPositions.TIGHT_END].includes(
            closest.props.role
        )
    ) {
        if (type === ToolTypes.PRESNAP) {
            const xOffset = closest.origin.x > frame.field.width / 2 ? -5 : 5;
            move.anchors[0] = closestOffset({
                x: xOffset,
            });
        } else {
            move.anchors[0] = closestOffset({
                y: -5,
            });
        }
    }

    if (
        [
            PlayerPositions.CENTER,
            PlayerPositions.LEFT_TACKLE,
            PlayerPositions.LEFT_GUARD,
            PlayerPositions.RIGHT_TACKLE,
            PlayerPositions.RIGHT_GUARD,
        ].includes(closest.props.role)
    ) {
        move.anchors[0] =
            type === ToolTypes.PRESNAP
                ? closestOffset({
                      x: -5,
                  })
                : closestOffset({
                      y: -2,
                  });

        move.type = ToolTypes.BLOCK;
    }

    if (closest.props.role === PlayerPositions.QUARTERBACK) {
        move.anchors[0] = closestOffset({
            y: 2,
        });
    }

    move.anchors = move.anchors.map((anc: any) => {
        return frame.field.clampCoords(anc);
    });

    return move;
}

export default {
    baseType: ToolBaseTypes.MOVE,
    types: [ToolTypes.PRESNAP, ToolTypes.DMOVE, ToolTypes.OMOVE, ToolTypes.MOVE],
    specialAnchorTypes: [SpecialAnchorTypes.MOVE_ELBOW],
    generateAnchors: ({ play }) => {
        const anchors = [];

        if (!play.fieldSetup) {
            play.frameSnapshots.forEach((frame, frameIdx) => {
                anchors.unshift(
                    ...frame.visibleMoves(play.viewOptions).flatMap((mv) => {
                        return mv.anchors.map((anc: any, idx: number) => {
                            return {
                                type: mv.type,
                                id: idx,
                                target: mv.target,
                                frameIdx,
                                ...anc,
                            };
                        });
                    })
                );
            });
        }

        return anchors;
    },

    generateSelectedAnchors: ({ play, frame }, selected) => {
        if (selected instanceof Move && play.config.MoveConfig[selected.type].baseType === ToolBaseTypes.MOVE) {
            return arraySlidingWindow([selected.origin, ...selected.anchors], (anchor, nextAnchor, idx) => {
                return {
                    target: selected.target,
                    type: SpecialAnchorTypes.MOVE_ELBOW,
                    id: idx,
                    frameIdx: frame.idx,
                    x: (anchor.x + nextAnchor.x) / 2,
                    y: (anchor.y + nextAnchor.y) / 2,
                    props: {
                        moveType: selected.type,
                        anchorCount: selected.anchors.length,
                    },
                };
            });
        }

        return [];
    },

    generateLines: ({ play }) => {
        if (play.fieldSetup) return [];

        return play.frameSnapshots.flatMap((frame, frameIdx) =>
            frame
                .visibleMoves(play.viewOptions)
                .flatMap(createLines)
                .map((line) => ({
                    ...line,
                    frameIdx,
                }))
        );
    },

    handleDragEnd: (context, anchor, offset) => {
        const { api, fieldViewport, play } = context;
        const frameIdx =
            {
                [ToolTypes.PRESNAP]: 0,
                [SpecialAnchorTypes.MOVE_ELBOW]: anchor.frameIdx,
            }[anchor.type] ?? 2;

        const frame = play.frameSnapshots[frameIdx];

        if (anchor.type === SpecialAnchorTypes.MOVE_ELBOW) {
            const move = frame.getMove(anchor.target);

            api.updateMove(move);
        } else if (typeof anchor.id === 'number') {
            const move = frame.getMove(anchor.target);
            const newMove = move.copy();

            if (anchor.type === ToolTypes.OMOVE || anchor.type === ToolTypes.DMOVE) {
                newMove.type = ToolTypes.MOVE;
            }

            // For moves, attempt to snap to a line
            if (anchor.type === ToolTypes.MOVE) {
                const snapLine = snapMoveToPlayerLine(frame, fieldViewport, offset, anchor.target);

                // Snap to the line, and mark the move to remove the player
                if (snapLine) {
                    newMove.props.targetLine = snapLine.id;
                    newMove.updateAnchor(anchor.id, snapLine.origin, play.field);
                } else {
                    newMove.props.targetLine = null;
                    newMove.updateAnchor(anchor.id, offset, play.field);
                }
            } else {
                newMove.updateAnchor(anchor.id, offset, play.field);
            }

            api.updateMove(newMove, frameIdx);
        } else {
            const closest = frame.findClosestPlayer(
                offset,
                frame.hiddenGoalies(),
                frame.getTargetForToolType(anchor.type)
            );

            if (closest) {
                const newMove = getDefaultMove(frame, closest, anchor.type);

                if (anchor.type === ToolTypes.OMOVE || anchor.type === ToolTypes.DMOVE) {
                    newMove.type = ToolTypes.MOVE;
                }

                api.updateMove(newMove, frameIdx);
            }
        }
    },

    handleDrag({ fieldViewport, play }, anchor, offset, clamped) {
        const frameIdx =
            {
                [ToolTypes.PRESNAP]: 0,
                [SpecialAnchorTypes.MOVE_ELBOW]: anchor.frameIdx,
            }[anchor.type] ?? 2;
        const frame = play.frameSnapshots[frameIdx];

        if (anchor.type === SpecialAnchorTypes.MOVE_ELBOW) {
            const move = frame.getMove(anchor.target);

            if (move.anchors.length === anchor.props.anchorCount) {
                frame.insertAnchor(anchor.target, anchor.id, clamped);
            } else {
                frame.updateAnchor(anchor.target, anchor.id, clamped);
            }
        } else if (anchor.type === ToolTypes.MOVE) {
            const snapLine = snapMoveToPlayerLine(frame, fieldViewport, offset, anchor.target);

            const target = snapLine ? snapLine.origin : clamped;

            frame.updateAnchor(anchor.target, anchor.id, target);
        } else {
            // All other move types
            frame.updateAnchor(anchor.target, anchor.id, clamped);
        }
    },

    getDefaultMove({ play }, type, offset) {
        const frameIdx = type === ToolTypes.PRESNAP ? 0 : 2;
        const frame = play.frameSnapshots[frameIdx];
        const closest = frame.findClosestPlayer(offset, frame.hiddenGoalies(), frame.getTargetForToolType(type));

        if (!closest) return null;

        return getDefaultMove(frame, closest, type);
    },
} as PlayPlugin<PluginContext>;
