import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

import { getSettings } from './environment.js';

const config = getSettings().firebaseConfig;

initializeApp(config);

const firestore = getFirestore();

export default firestore;
