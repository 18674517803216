import { Fragment } from 'react';

import { range } from '@labradorsports/utils';

/**
 * Field dimensions:
 *  53 x 120 (yards)
 *  */
const MensFullFieldBackdrop: FC<any> = ({ fieldViewport, backgroundImage, viewOptions }) => {
    const fieldWidth = 53;
    const fieldHeight = 120;

    return (
        <g className="field-backdrop">
            {range(101).map((i) => {
                const yard = i + 10;

                return yard % 5 === 0 ? (
                    <line
                        key={yard}
                        x1={fieldViewport.getPixelX(0)}
                        y1={fieldViewport.getPixelY(yard)}
                        x2={fieldViewport.getPixelX(fieldWidth)}
                        y2={fieldViewport.getPixelY(yard)}
                    />
                ) : (
                    <Fragment key={yard}>
                        {/* Left */}
                        <line
                            x1={fieldViewport.getPixelX(fieldWidth / 2 - 7)}
                            y1={fieldViewport.getPixelY(yard)}
                            x2={fieldViewport.getPixelX(fieldWidth / 2 - 6)}
                            y2={fieldViewport.getPixelY(yard)}
                        />

                        {/* Right */}
                        <line
                            x1={fieldViewport.getPixelX(fieldWidth / 2 + 6)}
                            y1={fieldViewport.getPixelY(yard)}
                            x2={fieldViewport.getPixelX(fieldWidth / 2 + 7)}
                            y2={fieldViewport.getPixelY(yard)}
                        />
                    </Fragment>
                );
            })}

            <image
                className="team-logo"
                xlinkHref={backgroundImage}
                width={fieldViewport.getPixelLength(fieldWidth / 2)}
                height={fieldViewport.getPixelLength(fieldWidth / 2)}
                x={
                    fieldViewport.flipped
                        ? fieldViewport.getPixelX(fieldWidth / 2 + fieldWidth / 4)
                        : fieldViewport.getPixelX(fieldWidth / 2 - fieldWidth / 4)
                }
                y={
                    fieldViewport.flipped
                        ? fieldViewport.getPixelY(fieldHeight / 2 + fieldWidth / 4)
                        : fieldViewport.getPixelY(fieldHeight / 2 - fieldWidth / 4)
                }
                transform={`rotate(${fieldViewport.flipped ? 180 : 0}, ${fieldViewport.getPixelX(
                    fieldWidth / 2
                )} ${fieldViewport.getPixelY(fieldHeight / 2)})`}
            />
            {/* Field Outline */}
            <rect
                x={fieldViewport.flipped ? fieldViewport.getPixelX(fieldWidth) : fieldViewport.getPixelX(0)}
                y={fieldViewport.flipped ? fieldViewport.getPixelY(fieldHeight) : fieldViewport.getPixelY(0)}
                width={fieldViewport.getPixelLength(fieldWidth)}
                height={fieldViewport.getPixelLength(fieldHeight)}
                fill="none"
            />

            <text
                dx={fieldViewport.flipped ? fieldViewport.getPixelX(0) - 5 : fieldViewport.getPixelX(fieldWidth) - 5}
                dy={fieldViewport.flipped ? fieldViewport.getPixelY(0) - 5 : fieldViewport.getPixelY(fieldHeight) - 5}
                textAnchor="end"
                strokeWidth="0"
                fill="#aaa"
                fontSize=".8em"
            >
                FootballLab.com
            </text>
        </g>
    );
};

export default MensFullFieldBackdrop;
