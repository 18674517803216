import { movePath } from '@labradorsports/utils';

/**
 * Field dimensions:
 *  30 x 66 (yards)
 *  */
const BoxFullFieldBackdrop: FC<any> = ({ fieldViewport, backgroundImage, viewOptions }) => {
    const fieldWidth = 30;
    const fieldHeight = 66;

    const topGoalY = 5;

    const bottomGoalY = 61;

    return (
        <g className="field-backdrop">
            <g className="optional-lines">
                {/* Optional field lines */}
                {viewOptions.fieldLines?.GOAL ? (
                    <>
                        <line
                            x1={fieldViewport.getPixelX(0)}
                            y1={fieldViewport.getPixelY(bottomGoalY)}
                            x2={fieldViewport.getPixelX(fieldWidth)}
                            y2={fieldViewport.getPixelY(bottomGoalY)}
                        />
                        <line
                            x1={fieldViewport.getPixelX(0)}
                            y1={fieldViewport.getPixelY(topGoalY)}
                            x2={fieldViewport.getPixelX(fieldWidth)}
                            y2={fieldViewport.getPixelY(topGoalY)}
                        />
                    </>
                ) : null}
            </g>

            <image
                className="team-logo"
                xlinkHref={backgroundImage}
                width={fieldViewport.getPixelLength(fieldWidth / 2)}
                height={fieldViewport.getPixelLength(fieldWidth / 2)}
                x={
                    fieldViewport.flipped
                        ? fieldViewport.getPixelX(fieldWidth * 0.75)
                        : fieldViewport.getPixelX(fieldWidth * 0.25)
                }
                y={fieldViewport.flipped ? fieldViewport.getPixelY(fieldHeight / 2 - 5) : fieldViewport.getPixelY(13)}
                transform={`rotate(${fieldViewport.flipped ? 0 : 180}, ${fieldViewport.getPixelX(
                    fieldWidth / 2
                )} ${fieldViewport.getPixelY(20.5)})`}
            />
            <image
                className="team-logo"
                xlinkHref={backgroundImage}
                width={fieldViewport.getPixelLength(fieldWidth / 2)}
                height={fieldViewport.getPixelLength(fieldWidth / 2)}
                x={
                    fieldViewport.flipped
                        ? fieldViewport.getPixelX(fieldWidth * 0.75)
                        : fieldViewport.getPixelX(fieldWidth * 0.25)
                }
                y={
                    fieldViewport.flipped
                        ? fieldViewport.getPixelY(fieldHeight - 13)
                        : fieldViewport.getPixelY(fieldHeight / 2 + 5)
                }
                transform={`rotate(${fieldViewport.flipped ? 180 : 0}, ${fieldViewport.getPixelX(
                    fieldWidth / 2
                )} ${fieldViewport.getPixelY(fieldHeight - 20.5)})`}
            />

            {/* Field Outline */}
            <line
                x1={fieldViewport.getPixelX(7)}
                y1={fieldViewport.getPixelY(0)}
                x2={fieldViewport.getPixelX(fieldWidth - 7)}
                y2={fieldViewport.getPixelY(0)}
            />
            <line
                x1={fieldViewport.getPixelX(0)}
                y1={fieldViewport.getPixelY(7)}
                x2={fieldViewport.getPixelX(0)}
                y2={fieldViewport.getPixelY(fieldHeight - 7)}
            />
            <line
                x1={fieldViewport.getPixelX(fieldWidth)}
                y1={fieldViewport.getPixelY(7)}
                x2={fieldViewport.getPixelX(fieldWidth)}
                y2={fieldViewport.getPixelY(fieldHeight - 7)}
            />
            <line
                x1={fieldViewport.getPixelX(7)}
                y1={fieldViewport.getPixelY(fieldHeight)}
                x2={fieldViewport.getPixelX(fieldWidth - 7)}
                y2={fieldViewport.getPixelY(fieldHeight)}
            />

            {/* Top left */}
            <path
                fill="none"
                d={`${movePath(
                    fieldViewport.getPixelX(0),
                    fieldViewport.getPixelY(7)
                )} A ${fieldViewport.getPixelLength(7)} ${fieldViewport.getPixelLength(
                    7
                )} -45 0 1 ${fieldViewport.getPixelX(7)} ${fieldViewport.getPixelY(0)}`}
            />
            {/* Top right */}
            <path
                fill="none"
                d={`${movePath(
                    fieldViewport.getPixelX(fieldWidth),
                    fieldViewport.getPixelY(7)
                )} A ${fieldViewport.getPixelLength(7)} ${fieldViewport.getPixelLength(
                    7
                )} 45 0 0 ${fieldViewport.getPixelX(fieldWidth - 7)} ${fieldViewport.getPixelY(0)}`}
            />
            {/* Bottom left */}
            <path
                fill="none"
                d={`${movePath(
                    fieldViewport.getPixelX(0),
                    fieldViewport.getPixelY(fieldHeight - 7)
                )} A ${fieldViewport.getPixelLength(7)} ${fieldViewport.getPixelLength(
                    7
                )} 45 0 0 ${fieldViewport.getPixelX(7)} ${fieldViewport.getPixelY(fieldHeight)}`}
            />
            {/* Bottom right */}
            <path
                fill="none"
                d={`${movePath(
                    fieldViewport.getPixelX(fieldWidth),
                    fieldViewport.getPixelY(fieldHeight - 7)
                )} A ${fieldViewport.getPixelLength(7)} ${fieldViewport.getPixelLength(
                    7
                )} -45 0 1 ${fieldViewport.getPixelX(fieldWidth - 7)} ${fieldViewport.getPixelY(fieldHeight)}`}
            />

            {/* Top Field Lines */}
            <line
                x1={fieldViewport.getPixelX(0)}
                y1={fieldViewport.getPixelY(fieldHeight / 2 - 4)}
                x2={fieldViewport.getPixelX(fieldWidth)}
                y2={fieldViewport.getPixelY(fieldHeight / 2 - 4)}
            />

            {/* Bottom Field Lines */}
            <line
                x1={fieldViewport.getPixelX(0)}
                y1={fieldViewport.getPixelY(fieldHeight / 2 + 4)}
                x2={fieldViewport.getPixelX(fieldWidth)}
                y2={fieldViewport.getPixelY(fieldHeight / 2 + 4)}
            />

            {/* Center */}
            <circle
                cx={fieldViewport.getPixelX(fieldWidth / 2)}
                cy={fieldViewport.getPixelY(fieldHeight / 2)}
                r={fieldViewport.getPixelLength(0.6)}
                fill="none"
            />
            <line
                x1={fieldViewport.getPixelX(fieldWidth / 2 - 0.6)}
                y1={fieldViewport.getPixelY(fieldHeight / 2 - 1)}
                x2={fieldViewport.getPixelX(fieldWidth / 2 - 0.6)}
                y2={fieldViewport.getPixelY(fieldHeight / 2 + 1)}
            />
            <line
                x1={fieldViewport.getPixelX(fieldWidth / 2 + 0.6)}
                y1={fieldViewport.getPixelY(fieldHeight / 2 - 1)}
                x2={fieldViewport.getPixelX(fieldWidth / 2 + 0.6)}
                y2={fieldViewport.getPixelY(fieldHeight / 2 + 1)}
            />

            {/* Top Goal */}
            <line
                x1={fieldViewport.getPixelX(fieldWidth / 2 - 3)}
                y1={fieldViewport.getPixelY(topGoalY)}
                x2={fieldViewport.getPixelX(fieldWidth / 2 + 3)}
                y2={fieldViewport.getPixelY(topGoalY)}
            />

            {/* Bottom Goal */}
            <line
                x1={fieldViewport.getPixelX(fieldWidth / 2 - 3)}
                y1={fieldViewport.getPixelY(bottomGoalY)}
                x2={fieldViewport.getPixelX(fieldWidth / 2 + 3)}
                y2={fieldViewport.getPixelY(bottomGoalY)}
            />

            {/* Substitution Area */}
            <line
                x1={fieldViewport.getPixelX(fieldWidth - 2)}
                y1={fieldViewport.getPixelY(fieldHeight / 2 - 10)}
                x2={fieldViewport.getPixelX(fieldWidth)}
                y2={fieldViewport.getPixelY(fieldHeight / 2 - 10)}
            />
            <line
                x1={fieldViewport.getPixelX(fieldWidth - 2)}
                y1={fieldViewport.getPixelY(fieldHeight / 2 - 10)}
                x2={fieldViewport.getPixelX(fieldWidth - 2)}
                y2={fieldViewport.getPixelY(fieldHeight / 2 + 10)}
            />
            <line
                x1={fieldViewport.getPixelX(fieldWidth - 2)}
                y1={fieldViewport.getPixelY(fieldHeight / 2 + 10)}
                x2={fieldViewport.getPixelX(fieldWidth)}
                y2={fieldViewport.getPixelY(fieldHeight / 2 + 10)}
            />

            <text
                dx={fieldViewport.getPixelX(fieldWidth / 2)}
                dy={fieldViewport.flipped ? fieldViewport.getPixelY(0) - 5 : fieldViewport.getPixelY(fieldHeight) - 5}
                textAnchor="middle"
                strokeWidth="0"
                fill="#aaa"
                fontSize=".8em"
            >
                LacrosseLab.com
            </text>
        </g>
    );
};
export default BoxFullFieldBackdrop;
