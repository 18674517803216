import { useState } from 'react';
import { Button } from '@mui/material';
import { useSelector } from 'react-redux';

import { StyledTextField } from '@labradorsports/components';
import { useSite } from '@labradorsports/utils';
import { HelmetWrapper } from '../../shared/structural/index.js';
import { useDispatcher } from '../../shared/hooks/index.js';
import { RootState, authActions, Selectors, useLoginMutation, LoginPopoverForms } from '../../store/index.js';

interface Props {
    changeForm?: (form?: string) => any;
}

const LoginForm: FC<Props> = ({ changeForm }) => {
    const { Config } = useSite();
    const [login] = useLoginMutation();
    const setLoginEmail = useDispatcher(authActions.SetLoginEmail);
    const accountFormError = useSelector(Selectors.accountFormError);
    const email = useSelector((state: RootState) => state.auth.loginEmail);
    const [password, setPassword] = useState('');

    const confirmLogin = () => {
        login({ email, password });
    };

    const emailChange = (event: any) => {
        setLoginEmail(event.target.value);
    };

    const passwordChange = (event: any) => {
        setPassword(event.target.value);
    };

    const keyPress = (event: any) => {
        if (event.key === 'Enter') {
            confirmLogin();
        }
    };

    const showSignup = () => {
        changeForm(LoginPopoverForms.SIGNUP);
    };

    const showResetPassword = () => {
        changeForm(LoginPopoverForms.FORGOT_PASSWORD);
    };

    const canonical = `${Config.SiteConfig.Url}/login`;

    return (
        <div className="login-form">
            <HelmetWrapper title="Login">
                <link rel="canonical" href={canonical} />
                <meta property="og:url" content={canonical} />
            </HelmetWrapper>
            <h2>Log In</h2>
            <div>Welcome back! To access your team, please log back in.</div>
            <StyledTextField value={email} onChange={emailChange} label="Email" onKeyPress={keyPress} fullWidth />
            <StyledTextField
                type="password"
                value={password}
                onChange={passwordChange}
                label="Password"
                onKeyPress={keyPress}
                fullWidth
            />
            {accountFormError ? <span className="error-text">{accountFormError.message}</span> : undefined}
            <div>
                <div className="small">Trouble logging in?</div>
                <Button tabIndex={1} onClick={showResetPassword}>
                    Reset Password
                </Button>
            </div>
            <div className="row">
                <div className="col text-start">
                    <div className="small">No account?</div>
                    <Button tabIndex={1} onClick={showSignup}>
                        Sign Up
                    </Button>
                </div>
                <div className="col text-end">
                    <Button variant="contained" onClick={confirmLogin} color="primary">
                        Login
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default LoginForm;
