import { useEffect, useState } from 'react';

import { SimpleModal } from '@labradorsports/components';
import { useSite } from '@labradorsports/utils';

const AboutModal: FC = () => {
    const [open, setOpen] = useState(false);
    const { Config } = useSite();

    useEffect(() => {
        window.addEventListener('keypress', (e) => {
            if (e.key === 'V' && e.ctrlKey) {
                setOpen(true);
            }
        });

        window.addEventListener('about-modal', () => {
            setOpen(true);
        });
    }, []);

    return (
        <SimpleModal onClose={() => setOpen(false)} open={open}>
            <p>{Config.SiteConfig.SiteName}</p>
            <p>
                Version: {VERSION}-{BUILD}
            </p>
        </SimpleModal>
    );
};

export default AboutModal;
