import { useAssets } from '@labradorsports/assets';
import { getSVGPoints } from '@labradorsports/utils';
import { FieldViewport } from '../models/index.js';

export interface Props {
    fieldViewport: FieldViewport;
    fieldSetup: boolean;
}

const Goal: FC<Props> = ({ fieldViewport, fieldSetup }) => {
    const { GoalIcon } = useAssets();
    // goalWidth === 2, so goalHeight can be simplified to this based on this calculation: h = (Math.sqrt(3) / 2) * w
    const goalHeight = Math.sqrt(3);

    const goalTriangle = [
        {
            x: fieldViewport.getPixelLength(-1),
            y: 0,
        },
        {
            x: fieldViewport.getPixelLength(1),
            y: 0,
        },
        {
            x: 0,
            y: fieldViewport.getPixelLength(goalHeight),
        },
    ];

    const width = fieldSetup ? 44 : fieldViewport.getPixelLength(6);
    return fieldSetup ? (
        <image
            xlinkHref={GoalIcon}
            width={width}
            height={width}
            transform={`translate(${-width / 2}, ${-width / 2})`}
        />
    ) : (
        <>
            <circle cx={0} cy={0} r={fieldViewport.getPixelLength(3)} fill="transparent" />
            <polygon points={getSVGPoints(goalTriangle)} fill="transparent" />
        </>
    );
};

export default Goal;
