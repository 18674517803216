import PlayConfig from './play-config.js';
import ToolboxConfig from './toolbox.js';
import * as Images from './images.js';
import Plugins from './plugins.js';

export default {
    PlayConfig,
    ToolboxConfig,
    Images,
    Plugins,
};
