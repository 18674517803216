import { useAssets } from '@labradorsports/assets';
import { useSite } from '@labradorsports/utils';

type SocialIconType = 'facebook' | 'twitter' | 'instagram' | 'youtube';

interface Props {
    type: SocialIconType;
}

const SocialIcon: FC<Props> = ({ type }) => {
    const { Config } = useSite();
    const { InstagramLogo, FacebookLogo, YouTubeLogo, TwitterLogo } = useAssets();

    const SocialIconConfig: {
        [k in SocialIconType]: {
            icon: string;
            url: string;
            alt: string;
        };
    } = {
        facebook: {
            icon: FacebookLogo,
            url: Config.SiteConfig.FacebookUrl,
            alt: 'Facebook logo',
        },
        twitter: {
            icon: TwitterLogo,
            url: Config.SiteConfig.TwitterUrl,
            alt: 'Twitter logo',
        },
        instagram: {
            icon: InstagramLogo,
            url: Config.SiteConfig.InstagramUrl,
            alt: 'Instagram logo',
        },
        youtube: {
            icon: YouTubeLogo,
            url: Config.SiteConfig.YouTubeUrl,
            alt: 'YouTube logo',
        },
    };

    const config = SocialIconConfig[type];

    return (
        <a className="social-icon" href={config.url} target="_blank" rel="noreferrer noopener">
            <img src={config.icon} alt={config.alt} />
        </a>
    );
};

export default SocialIcon;
