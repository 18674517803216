import { Sites } from '@labradorsports/constants';

import Lacrosse from './lacrosse.js';
import Basketball from './basketball.js';
import Football from './football.js';

export default {
    [Sites.lacrosselab]: Lacrosse,
    [Sites.hoopslab]: Basketball,
    [Sites.footballlab]: Football,
};
