import {
    FieldPiecesPlugin,
    BallsPlugin,
    PlayersPlugin,
    MovesPlugin,
    PassesPlugin,
    ShotsPlugin,
    OptionsPlugin,
    AssignmentsPlugin,
    ShadingPlugin,
    FieldCommentsPlugin,
    SnapLinesPlugin,
} from '../plugins/index.js';

export default [
    FieldPiecesPlugin,
    BallsPlugin,
    PlayersPlugin,
    MovesPlugin,
    PassesPlugin,
    ShotsPlugin,
    OptionsPlugin,
    AssignmentsPlugin,
    ShadingPlugin,
    FieldCommentsPlugin,
    SnapLinesPlugin,
];
