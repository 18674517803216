import { Component, CSSProperties, DetailedHTMLProps } from 'react';
import { Checkbox as MuiCheckbox, FormControlLabel, Radio, CheckboxProps as MuiCheckboxProps } from '@mui/material';

export interface CheckboxProps extends MuiCheckboxProps {
    boxType?: 'check' | 'radio';
    label?: string;
    inputClassName?: string;
    labelClassName?: string;
    labelComponent?: Component | DetailedHTMLProps<any, any>;
    labelStyle?: CSSProperties;
    size?: 'small' | 'medium';
}

const Checkbox: FC<CheckboxProps> = ({
    label = '',
    labelComponent,
    labelClassName,
    className = '',
    inputClassName,
    labelStyle = {},
    style,
    boxType = 'check',
    ...rest
}) => {
    const check =
        boxType === 'check' ? (
            <MuiCheckbox style={{ padding: '12px', ...style }} className={inputClassName} color="primary" {...rest} />
        ) : (
            <Radio style={{ padding: '12px', ...style }} className={inputClassName} color="primary" {...rest} />
        );

    if (labelComponent) {
        return (
            // eslint-disable-next-line jsx-a11y/label-has-associated-control, jsx-a11y/label-has-for
            <label
                className={`d-flex align-items-center flex-grow-1 ${className}`}
                style={{
                    marginLeft: '-11px',
                    cursor: 'pointer',
                    marginBottom: '0px',
                }}
            >
                {check}
                {labelComponent}
            </label>
        );
    }

    return (
        <FormControlLabel
            classes={{ root: className, label: labelClassName }}
            label={label}
            control={check}
            style={{ marginBottom: '0px', ...labelStyle }}
        />
    );
};

export default Checkbox;
