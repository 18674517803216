import { Fragment } from 'react';

import { arcPath, halfCirclePath, movePath } from '@labradorsports/utils';

/**
 * Field dimensions:
 *  70 x 60 (yards)
 *  */
const WomensHalfFieldBackdrop: FC<any> = ({ fieldViewport, backgroundImage, viewOptions }) => {
    const fieldWidth = 70;
    const fieldHeight = 60;

    const fanHeight = Math.sqrt(60.5); // x^2 + x^2 = 8^2;

    const goalY = 45;
    const goalFan =
        movePath(fieldViewport.getPixelX(fieldWidth / 2 - fanHeight), fieldViewport.getPixelY(goalY - fanHeight + 3)) +
        arcPath(
            fieldViewport.getPixelLength(9.5),
            false,
            fieldViewport.getPixelX(fieldWidth / 2 + fanHeight),
            fieldViewport.getPixelY(goalY - fanHeight + 3)
        );

    return (
        <g className="field-backdrop">
            <g className="optional-lines">
                {/* Optional field lines */}
                {viewOptions.fieldLines?.GOAL ? (
                    <line
                        x1={fieldViewport.getPixelX(0)}
                        y1={fieldViewport.getPixelY(goalY)}
                        x2={fieldViewport.getPixelX(fieldWidth)}
                        y2={fieldViewport.getPixelY(goalY)}
                    />
                ) : null}

                {viewOptions.fieldLines?.FOOTBALL ? (
                    <>
                        {[5, 10, 15, 25, 30, 35, 40, 45, 50, 55].map((yardsFromCenter) => (
                            <Fragment key={yardsFromCenter}>
                                {/* Left */}
                                <line
                                    x1={fieldViewport.getPixelX(fieldWidth / 2 - 7)}
                                    y1={fieldViewport.getPixelY(yardsFromCenter)}
                                    x2={fieldViewport.getPixelX(fieldWidth / 2 - 5)}
                                    y2={fieldViewport.getPixelY(yardsFromCenter)}
                                />

                                {/* Right */}
                                <line
                                    x1={fieldViewport.getPixelX(fieldWidth / 2 + 7)}
                                    y1={fieldViewport.getPixelY(yardsFromCenter)}
                                    x2={fieldViewport.getPixelX(fieldWidth / 2 + 5)}
                                    y2={fieldViewport.getPixelY(yardsFromCenter)}
                                />
                            </Fragment>
                        ))}
                    </>
                ) : null}
            </g>

            <image
                className="team-logo"
                xlinkHref={backgroundImage}
                width={fieldViewport.getPixelLength(fieldWidth / 2)}
                height={fieldViewport.getPixelLength(fieldWidth / 2)}
                x={fieldViewport.flipped ? fieldViewport.getPixelX(fieldWidth - 17.5) : fieldViewport.getPixelX(17.5)}
                y={fieldViewport.flipped ? fieldViewport.getPixelY(50) : fieldViewport.getPixelY(10)}
                transform={`rotate(${fieldViewport.flipped ? 180 : 0}, ${fieldViewport.getPixelX(
                    fieldWidth / 2
                )} ${fieldViewport.getPixelY(30)})`}
            />

            {/* Field Outline */}
            <rect
                x={fieldViewport.flipped ? fieldViewport.getPixelX(fieldWidth) : fieldViewport.getPixelX(0)}
                y={fieldViewport.flipped ? fieldViewport.getPixelY(fieldHeight) : fieldViewport.getPixelY(0)}
                width={fieldViewport.getPixelLength(fieldWidth)}
                height={fieldViewport.getPixelLength(fieldHeight)}
                fill="none"
            />

            {/* Bottom Field Lines */}
            <line
                x1={fieldViewport.getPixelX(0)}
                y1={fieldViewport.getPixelY(20)}
                x2={fieldViewport.getPixelX(fieldWidth)}
                y2={fieldViewport.getPixelY(20)}
            />

            {/* Center */}
            <line
                x1={fieldViewport.getPixelX(fieldWidth / 2)}
                y1={fieldViewport.getPixelY(0)}
                x2={fieldViewport.getPixelX(fieldWidth / 2 + 2.5)}
                y2={fieldViewport.getPixelY(1.5)}
            />
            <line
                x1={fieldViewport.getPixelX(fieldWidth / 2)}
                y1={fieldViewport.getPixelY(0)}
                x2={fieldViewport.getPixelX(fieldWidth / 2 - 2.5)}
                y2={fieldViewport.getPixelY(1.5)}
            />
            <path
                fill="none"
                d={halfCirclePath(
                    fieldViewport.getPixelLength(10),
                    fieldViewport.flipped
                        ? fieldViewport.getPixelX(fieldWidth / 2 + 10)
                        : fieldViewport.getPixelX(fieldWidth / 2 - 10),
                    fieldViewport.getPixelY(0),
                    !fieldViewport.flipped
                )}
            />

            {/* Bottom Goal */}
            <line
                x1={fieldViewport.getPixelX(fieldWidth / 2 - 12)}
                y1={fieldViewport.getPixelY(goalY)}
                x2={fieldViewport.getPixelX(fieldWidth / 2 + 12)}
                y2={fieldViewport.getPixelY(goalY)}
            />
            <path
                fill="none"
                d={halfCirclePath(
                    fieldViewport.getPixelLength(12),
                    fieldViewport.flipped
                        ? fieldViewport.getPixelX(fieldWidth / 2 + 12)
                        : fieldViewport.getPixelX(fieldWidth / 2 - 12),
                    fieldViewport.getPixelY(goalY),
                    fieldViewport.flipped
                )}
            />
            <circle
                cx={fieldViewport.getPixelX(fieldWidth / 2)}
                cy={fieldViewport.getPixelY(goalY)}
                r={fieldViewport.getPixelLength(3)}
                fill="white"
            />
            <line
                x1={fieldViewport.getPixelX(fieldWidth / 2 - 3)}
                y1={fieldViewport.getPixelY(goalY)}
                x2={fieldViewport.getPixelX(fieldWidth / 2 - fanHeight)}
                y2={fieldViewport.getPixelY(goalY - fanHeight + 3)}
            />
            <line
                x1={fieldViewport.getPixelX(fieldWidth / 2 + 3)}
                y1={fieldViewport.getPixelY(goalY)}
                x2={fieldViewport.getPixelX(fieldWidth / 2 + fanHeight)}
                y2={fieldViewport.getPixelY(goalY - fanHeight + 3)}
            />
            <path fill="none" d={goalFan} />

            {/* Substitution Area */}
            <line
                x1={fieldViewport.getPixelX(fieldWidth)}
                y1={fieldViewport.getPixelY(0)}
                x2={fieldViewport.getPixelX(fieldWidth + 2)}
                y2={fieldViewport.getPixelY(0)}
            />
            <line
                x1={fieldViewport.getPixelX(fieldWidth + 2)}
                y1={fieldViewport.getPixelY(0)}
                x2={fieldViewport.getPixelX(fieldWidth + 2)}
                y2={fieldViewport.getPixelY(5)}
            />
            <line
                x1={fieldViewport.getPixelX(fieldWidth)}
                y1={fieldViewport.getPixelY(5)}
                x2={fieldViewport.getPixelX(fieldWidth + 2)}
                y2={fieldViewport.getPixelY(5)}
            />

            <text
                dx={fieldViewport.flipped ? fieldViewport.getPixelX(0) - 5 : fieldViewport.getPixelX(fieldWidth) - 5}
                dy={fieldViewport.flipped ? fieldViewport.getPixelY(0) - 5 : fieldViewport.getPixelY(fieldHeight) - 5}
                textAnchor="end"
                strokeWidth="0"
                fill="#aaa"
                fontSize=".8em"
            >
                LacrosseLab.com
            </text>
        </g>
    );
};

export default WomensHalfFieldBackdrop;
