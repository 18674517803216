/* eslint-disable react-hooks/rules-of-hooks */
import { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import { install, applyUpdate } from '@lcdp/offline-plugin/runtime.js';

import { StyledSnackbar } from '@labradorsports/components';
import { useSite } from '@labradorsports/utils';
import { useLogger } from '../../providers/index.js';

const UpdateHandler: FC = () => {
    if (!DEPLOY) return null;

    const logger = useLogger();
    const { Config } = useSite();

    const [hasUpdate, setHasUpdate] = useState(false);
    useEffect(() => {
        install({
            onUpdateReady: () => {
                logger.log('ServiceWorker update available');
                applyUpdate();
            },
            onUpdated: () => {
                logger.log('ServiceWorker update complete - notification shown');
                setHasUpdate(true);
            },
        });
    }, []);

    const refresh = () => {
        logger.log('Notification refresh clicked');
        window.location.reload();
    };

    return (
        <StyledSnackbar
            onClose={() => setHasUpdate(false)}
            message={`A new version of ${Config.SiteConfig.SiteName} is available. Please refresh to update.`}
            action={[
                <Button key="0" color="secondary" onClick={refresh}>
                    Refresh
                </Button>,
            ]}
            open={hasUpdate}
        />
    );
};

export default UpdateHandler;
