import { HudlLogo } from '@labradorsports/assets';
import { getVimeoVideoID, getYouTubeVideoID, isHudlUrl } from '@labradorsports/utils';
import { getAssetUrl } from '../../shared/utils.js';
import { asyncWrapper, TD, ThunkContext } from '../async-actions.js';
import { RootState } from '../state.js';

export const getVideoDetails = (videoUrl: string) => {
    return asyncWrapper(
        'getVideoDetails',
        async (dispatch: TD, state: RootState, { cff }: ThunkContext) => {
            if (isHudlUrl(videoUrl)) {
                return {
                    thumbnailUrl: getAssetUrl(HudlLogo),
                    title: 'Hudl Video',
                    description: '',
                };
            }

            if (getYouTubeVideoID(videoUrl) || getVimeoVideoID(videoUrl)) {
                const details = await cff.fetch('playbook/getVideoDetails', {
                    videoLink: videoUrl,
                });

                return details;
            }

            return null;
        },
        { showLoading: false }
    );
};

export const getVersion = () => {
    return asyncWrapper('getVersion', async (dispatch: TD, state: RootState, { cff, logger }: ThunkContext) => {
        const { version, deployed } = await cff.fetch('main/version');

        logger.log('getVersion', { version, deployed });

        return [version, new Date(deployed)];
    });
};
