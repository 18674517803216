import './404.html';
import '@sport/favicon';
import '@sport/res';
import './main.scss';

import './firebase-config.js';

import 'core-js/stable/array/flat-map';
import { hydrateRoot, createRoot } from 'react-dom/client';
import { BrowserRouter, HashRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { CssBaseline } from '@mui/material';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';

import { Config, TestUrl, RenderingConfig } from '@sport';
import { AppWrapper, SiteContent, AppContent, GlobalsProvider, getSettings, initEnv } from '@labradorsports/ui';
import { serializeObjectPaths, SiteContext, stateDeserializers } from '@labradorsports/utils';
import './app/assets.js';

const Router = APP ? HashRouter : BrowserRouter;
const Content = APP ? AppContent : SiteContent;

const rootElement = document.getElementById('react');

const settings = getSettings();

const cache = createCache({ key: 'css' });

if (APP) {
    window.__SSR_STATE__ = {};
}

const rootContent = (
    <CacheProvider value={cache}>
        <HelmetProvider>
            <SiteContext.Provider
                value={{
                    Config,
                    ...RenderingConfig,
                    Images: {
                        ...RenderingConfig.Images,
                        Logo: Config.SiteConfig.Logo,
                    },
                    env: {
                        ...initEnv(Config, TestUrl),
                        ...settings,
                    },
                }}
            >
                <GlobalsProvider initialState={serializeObjectPaths(window.__SSR_STATE__, stateDeserializers)}>
                    <Router>
                        <AppWrapper>
                            <CssBaseline />
                            <Content />
                        </AppWrapper>
                    </Router>
                </GlobalsProvider>
            </SiteContext.Provider>
        </HelmetProvider>
    </CacheProvider>
);

if (rootElement.hasChildNodes()) {
    hydrateRoot(rootElement, rootContent);
} else {
    const root = createRoot(rootElement);
    root.render(rootContent);
}
