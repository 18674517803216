import { SimpleModal, MailTo } from '@labradorsports/components';
import { Exception } from '@labradorsports/utils';

interface Props {
    error: Exception;
}

const CrashPage: FC<Props> = ({ error }) => {
    const refresh = () => {
        // Pause to allow more time for error logs to reach the server
        setTimeout(() => {
            if (APP) {
                window.location.href = 'index.html';
            } else {
                window.location.reload();
            }
        }, 2000);
    };

    return (
        <SimpleModal open onClose={refresh}>
            <p>
                Something went wrong.
                <br />
                Press OK to refresh the page.
            </p>
            <p className="small">
                If this keeps happening, please contact us at <MailTo email="support@labradorsports.com" />
            </p>
            <p className="small text-muted">Error code: {error.code}</p>
        </SimpleModal>
    );
};

export default CrashPage;
