import { Sites, Addons, Plans, GlobalPublicSite, PlanTeamSizes } from './common.js';

export const Sports = {
    HighSchoolBBall: 'HS_BASKETBALL',
    MensCollegeBBall: 'MENS_COLLEGE_BASKETBALL',
    WomensCollegeBBall: 'WOMENS_COLLEGE_BASKETBALL',
};

export const SiteConfig = {
    Site: Sites.hoopslab,
    SiteName: 'Hoops Lab',
    Sport: 'basketball',
    FriendlyUrl: 'Hoops-Lab.com',
    Url: 'https://basketball.labradorsports.com',
    PublicUrl: `${GlobalPublicSite}/basketball`,
    InstagramUrl: 'https://www.instagram.com/lacrosselab_sports/',
    FacebookUrl: 'https://www.facebook.com/LacrosseLab/',
    TwitterUrl: 'https://twitter.com/lacrosse_lab',
    YouTubeUrl: 'https://www.youtube.com/channel/UCnDsmzWjusDL_Rsth8Hs5Qw',
    DefaultDescription:
        'The most comprehensive software available to basketball coaches. Create and share your basketball playbook with your players.',
    GooglePlayUrl: 'https://play.google.com/store/apps/details?id=com.labradorsports.hoopslab',
    AppStoreUrl: 'https://apps.apple.com/la/app/hoops-lab/id1576985969',
};

export const TestUrl = 'https://hoops-lab-test.firebaseapp.com';

export const AddonConfig = {
    [Addons.PLAYERS]: {
        Name: 'Roster Expansion',
        MonthlyPrice: 3,
        AnnualPrice: 30,
        ExtraSlots: 2,
    },
    [Addons.COACHES]: {
        Name: 'Additional Coach',
        MonthlyPrice: 3,
        AnnualPrice: 30,
        ExtraSlots: 1,
    },
    [Addons.ADMINS]: {
        Name: 'Additional Admin',
        MonthlyPrice: 4,
        AnnualPrice: 30,
        ExtraSlots: 1,
    },
};

export const TeamSizes: {
    [plan: string]: PlanTeamSizes;
} = {
    [Plans.BASIC]: {
        Admin: 1,
        Coach: 3,
        Player: 13,
    },
    [Plans.COMPLETE]: {
        Admin: 1,
        Coach: 3,
        Player: 13,
    },
    [Plans.COLLEGIATE]: {
        Admin: 1,
        Coach: 6,
        Player: 25,
    },
    [Plans.CLUB_COMPLETE]: {
        Admin: 1,
        Coach: 3,
        Player: 13,
    },
    [Plans.PERSONAL]: {
        Admin: 1,
        Coach: 6,
        Player: null,
    },
};

export const Features = {
    Roster: true,
    DepthChart: true,
    Evaluation: true,
    Schedule: true,
    ScoutingReport: true,
    Community: true,
    PlayDesigner: true,
    TeamPlaybook: true,
    TeamAdmin: true,
    TeamDocuments: true,
};
