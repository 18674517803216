import FieldPiece from './field-piece.js';
import Field from './field.js';
import FrameSnapshot from './frame-snapshot.js';

// Coordinates should always be in units of the field (not pixels)
// a FieldViewport will be used to translate those coordinates into screen space
export default class Play {
    config: PlayConfigSpec;

    balls: any[];

    players: FieldPiece[];

    field: Field;

    fieldType: string;

    type: string;

    sport: string;

    fieldSetup: boolean;

    frames: any[];

    frameSnapshots: FrameSnapshot[];

    viewOptions: any;

    unifiedField: boolean;

    plugins: Plugin[];

    constructor(config: PlayConfigSpec, field: Field, play?: any) {
        if (!field || !config) {
            throw new Error('Config and field are required');
        }

        this.config = config;
        this.field = field;

        if (!play) {
            return;
        }

        this.fieldType = play.fieldType;
        this.sport = play.sport;
        this.type = play.type;

        this.balls = play.balls;
        this.players = play.initial.map((plr) => new FieldPiece(plr));
        this.fieldSetup = play.fieldSetup;
        this.viewOptions = play.viewOptions;
        this.unifiedField = play.unifiedField;

        this.frames = play.frames;

        this.frameSnapshots = [];

        this.frameSnapshots[0] = new FrameSnapshot(
            this.config,
            this.field,
            this.sport,
            this.type,
            0,
            this.players,
            this.balls,
            play.frames[0]
        );

        for (let i = 1; i < play.frames.length; i += 1) {
            this.frameSnapshots[i] = this.frameSnapshots[i - 1].generateNextFrame(play.frames[i]);
        }
    }

    copy(): Play {
        const newPlay = new Play(this.config, this.field);
        Object.assign(newPlay, this);

        return newPlay;
    }

    getFrame(frameIdx: number): FrameSnapshot {
        if (frameIdx > this.frames.length) {
            throw new Error(`Tried to access frame [${frameIdx}] of play with only ${this.frames.length} frames`);
        }

        return this.frameSnapshots[frameIdx];
    }

    updatePlayData(play: any): void {
        const newSnapshots: FrameSnapshot[] = [];

        Object.assign(this, play);

        // If the players have changed, we need to re-calculate all frames
        let seenChange =
            play.initial !== this.players || play.frames[0] !== this.frames[0] || play.balls !== this.balls;

        if (play.initial !== this.players) {
            this.players = play.initial.map((plr) => new FieldPiece(plr));
        }

        // Copy/generate first frame as a starting point
        newSnapshots[0] = seenChange
            ? new FrameSnapshot(
                  this.config,
                  this.field,
                  this.sport,
                  this.type,
                  0,
                  play.initial.map((plr: any) => {
                      return new FieldPiece(plr);
                  }),
                  play.balls.map((ball: any) => {
                      return { ...ball };
                  }),
                  play.frames[0]
              )
            : this.frameSnapshots[0];

        for (let i = 1; i < play.frames.length; i += 1) {
            const frame = play.frames[i];
            if (frame === this.frames[i] && !seenChange) {
                newSnapshots[i] = this.frameSnapshots[i];
            } else {
                seenChange = true;
                newSnapshots[i] = newSnapshots[i - 1].generateNextFrame(frame);
            }
        }

        this.frameSnapshots = newSnapshots;
    }
}
