import { applyTimezoneOffset } from './utils.js';

export const serializeDate = (dateStr: string) => applyTimezoneOffset(new Date(dateStr));

export const deserializeDate = (value) => applyTimezoneOffset(new Date(value), 240);

const processDate = { serialize: serializeDate, deserialize: deserializeDate };

const fieldProcessors = {
    'community.play.sharedOn': processDate,
    'community.play.createdDate': processDate,
    'community.profile.sharedPlays[*].sharedOn': processDate,
    'community.profile.sharedPlays[*].createdDate': processDate,
    'community.playComments[*].createdDate': processDate,
    'community.plays[*].sharedOn': processDate,
    'community.plays[*].createdDate': processDate,
    'community.newPlays[*].sharedOn': processDate,
    'community.newPlays[*].createdDate': processDate,
    'community.popularPlays[*].sharedOn': processDate,
    'community.popularPlays[*].createdDate': processDate,
    'community.trendingPlays[*].sharedOn': processDate,
    'community.trendingPlays[*].createdDate': processDate,
};

export const stateSerializers = Object.fromEntries(
    Object.entries(fieldProcessors).map(([k, { serialize }]) => [k, serialize])
);
export const stateDeserializers = Object.fromEntries(
    Object.entries(fieldProcessors).map(([k, { deserialize }]) => [k, deserialize])
);
