export function findBestPair(list: any[], predicate: (itemA: any, itemB: any) => number): [any, any] {
    let itemA = list[0];
    let itemB = list[1];
    let best = predicate(itemA, itemB);

    list.forEach((item, idx) => {
        for (let i = idx + 1; i < list.length; i += 1) {
            const rank = predicate(item, list[i]);
            if (rank > best) {
                itemA = item;
                itemB = list[i];
                best = rank;
            }
        }
    });

    return [itemA, itemB];
}

export function areClose(num1: number, num2: number, precision = 0.02): boolean {
    return Math.abs(num1 - num2) < precision;
}

export function isNumberBetween(x: number, n1: number, n2: number): boolean {
    const min = Math.min(n1, n2);
    const max = Math.max(n1, n2);

    return min < x && x < max;
}

// Mutates arguments
export function updateExistingOrCreateNew(existingItems: any[], item: any) {
    if (item.id !== undefined) {
        const existing = existingItems.find((it: any) => {
            return it.id === item.id;
        });

        if (existing) {
            Object.assign(existing, item);
        } else {
            existingItems.push(item);
        }
    } else {
        let nextId = 0;
        const findExisting = (it: any) => it.id === nextId;
        while (existingItems.find(findExisting)) nextId += 1;

        item.id = nextId;
        existingItems.push(item);
    }
}
