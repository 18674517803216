import { SpecialAnchorTypes, ToolBaseTypes, ToolTypes } from '@labradorsports/constants';
import { PluginContext } from '../plugins/api.js';
import {
    createLines,
    generatePassAnchors,
    generatePassSelectedAnchors,
    getDefaultPassMove,
    handlePassDrag,
    handlePassDragEnd,
} from '../utils/index.js';

export default {
    baseType: ToolBaseTypes.PASS,
    types: [ToolTypes.PASS, SpecialAnchorTypes.PASS_START],
    generateAnchors: generatePassAnchors,
    generateSelectedAnchors: generatePassSelectedAnchors,
    handleDragEnd: handlePassDragEnd,
    handleDrag: handlePassDrag,

    generateLines: ({ play, frame }) => {
        if (play.fieldSetup) return [];

        return frame.passes?.flatMap(createLines);
    },

    getDefaultMove: getDefaultPassMove,
} as PlayPlugin<PluginContext>;
