import { ComponentType, RefObject, useEffect, useRef, useState } from 'react';
import { Button, ClickAwayListener } from '@mui/material';

import { noPropagation } from '@labradorsports/utils';

const DropdownIcon = () => (
    <svg width="10" height="30" fill="#777">
        <circle r="3.5" cx="5" cy="5" />
        <circle r="3.5" cx="5" cy="15" />
        <circle r="3.5" cx="5" cy="25" />
    </svg>
);

interface Option {
    text: string;
    onClick?: () => any;
    href?: string;
}

interface Props {
    options: Option[];
    getMenuPosition?: (menuHeight: number) => 'bottom' | 'top';
    icon?: string;
    DropdownComponent?: ComponentType<{
        optionClick: AnyFunction;
        options: Option[];
    }>;
    dropdownProps?: any;
    containerRef?: RefObject<HTMLDivElement>;
}

const DropdownMenu: FC<Props> = ({
    options,
    getMenuPosition,
    icon,
    DropdownComponent,
    dropdownProps = {},
    containerRef,
}) => {
    const backupRef = useRef<HTMLDivElement>();
    const menuButtonRef = containerRef ?? backupRef;
    const [open, setOpen] = useState(false);
    const [position, setPosition] = useState('bottom');
    const menuRef = useRef<HTMLDivElement>();

    useEffect(() => {
        const handler = () => {
            setOpen(false);
        };

        window.addEventListener('dropdown-opened', handler);

        return () => window.removeEventListener('dropdown-opened', handler);
    }, []);

    const onClick = (opt: Option) =>
        noPropagation((evt: any) => {
            if (opt.onClick) {
                opt.onClick();
                setOpen(false);
            }
        });

    const menuButtonClick = noPropagation(() => {
        window.dispatchEvent(new Event('dropdown-opened'));

        requestAnimationFrame(() => {
            setOpen(!open);

            requestAnimationFrame(() => {
                const menuHeight = menuRef.current?.offsetHeight;
                setPosition(getMenuPosition ? getMenuPosition(menuHeight) : 'bottom');
            });
        });
    });

    const clickAway = (evt: any) => {
        if (!menuButtonRef.current?.contains(evt.target)) {
            setOpen(false);
        }
    };

    return (
        <div className={`dropdown-menu-container ${open ? 'menu-open' : ''}`} ref={menuButtonRef}>
            {icon ? (
                <img className="dropdown-button" src={icon} onClick={menuButtonClick} alt="Dropdown menu icon" />
            ) : (
                <Button className="dropdown-button" onClick={menuButtonClick}>
                    <DropdownIcon />
                </Button>
            )}
            {open ? (
                <ClickAwayListener onClickAway={clickAway}>
                    <div className={`dropdown-panel menu-position-${position}`} ref={menuRef}>
                        {DropdownComponent ? (
                            <DropdownComponent optionClick={onClick} options={options} {...dropdownProps} />
                        ) : (
                            options.map((opt, idx) => (
                                <a key={idx} className="dropdown-menu-item" href={opt.href} onClick={onClick(opt)}>
                                    {opt.text}
                                </a>
                            ))
                        )}
                    </div>
                </ClickAwayListener>
            ) : null}
        </div>
    );
};

export default DropdownMenu;
