import { ConfirmDeleteModal } from '@labradorsports/components';

interface Props {
    open: boolean;
    selectedItems: any[];
    confirm: () => any;
    cancel: () => any;
}

const ConfirmDeleteItemsModal: FC<Props> = ({ selectedItems, open, confirm, cancel }) => {
    let text;

    if (selectedItems.length === 1) {
        if (selectedItems[0].sport) {
            text = 'Are you sure you want to delete this play?. This is irreversible.';
        } else {
            text =
                'Are you sure you want to delete this folder?. All plays within the folder will be moved to the root of the playbook.';
        }
    } else {
        text =
            'Are you sure you want to delete these items? This is irreversible. Plays within deleted folders will be moved to the root of the playbook.';
    }

    return <ConfirmDeleteModal text={text} open={open} confirm={confirm} cancel={cancel} />;
};

export default ConfirmDeleteItemsModal;
