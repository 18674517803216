import {
    Assign,
    MovePlayer,
    PassBall,
    SetPick,
    Shoot,
    Comment,
    GroundBall,
    WaveMove,
    ArrowMoveIcon,
    PickMoveIcon,
    SquiggleMoveIcon,
    DrawRectangle,
    DrawEllipse,
} from '@labradorsports/assets';
import { Sites } from '@labradorsports/constants';
import SportConfigs from '@labradorsports/sports';
import PlayConfig from './play-config.js';
import {
    _231Icon,
    _33Icon,
    _222Icon,
    _141Icon,
    CircleIcon,
    WomensCircleIcon,
    _3out2inIcon,
    _4out1inIcon,
    _5outIcon,
    _232Icon,
} from './images.js';

const { Sports } = SportConfigs[Sites.lacrosselab];

const { ToolTypes, Formations } = PlayConfig;

const Tools = {
    Move: {
        name: 'Move',
        type: ToolTypes.MOVE,
        icon: MovePlayer,
    },
    Move2: {
        name: 'Move 2',
        type: ToolTypes.MOVE2,
        icon: WaveMove,
    },
    Pick: {
        name: 'Pick',
        type: ToolTypes.PICK,
        icon: SetPick,
    },
    Pass: {
        name: 'Pass',
        icon: PassBall,
        type: ToolTypes.PASS,
    },
    Shoot: {
        name: 'Shoot',
        type: ToolTypes.SHOOT,
        icon: Shoot,
    },
    Option: {
        name: 'Option',
        type: ToolTypes.OPTION,
        icon: Assign,
    },
    Groundball: {
        name: 'Ground Ball',
        type: ToolTypes.GROUNDBALL,
        icon: GroundBall,
    },
    Assign: {
        name: 'Assign',
        type: ToolTypes.ASSIGN,
        icon: Assign,
    },
    Comment: {
        name: 'Comment',
        icon: Comment,
        type: ToolTypes.COMMENT,
    },
    Rectangle: {
        name: 'Rectangle',
        icon: DrawRectangle,
        type: ToolTypes.RECTANGLE,
    },
    Ellipse: {
        name: 'Ellipse',
        icon: DrawEllipse,
        type: ToolTypes.ELLIPSE,
    },
};

const drillToolGroups: any = [
    {
        ...Tools.Move,
        id: 'MOVE',
        tools: [Tools.Pick, Tools.Move2],
    },
    {
        ...Tools.Pass,
        id: 'OFFENSE_PASS',
        tools: [Tools.Shoot, Tools.Option, Tools.Groundball],
    },
    Tools.Assign,
    Tools.Comment,
];

// Not using pre-defined Tools.Move to separate OMOVE vs DMOVE
const offenseToolGroups: any = [
    {
        name: 'Move',
        id: 'OFFENSE_MOVE',
        type: ToolTypes.OMOVE,
        icon: MovePlayer,
        tools: [Tools.Pick, Tools.Move2],
    },
    {
        ...Tools.Pass,
        id: 'OFFENSE_PASS',
        tools: [Tools.Shoot, Tools.Option, Tools.Groundball],
    },
];

const defenseToolGroups = [
    {
        name: 'Move',
        type: ToolTypes.DMOVE,
        icon: MovePlayer,
    },
    Tools.Assign,
];

const otherToolGroups = [
    Tools.Comment,
    {
        name: 'Shapes',
        type: ToolTypes.RECTANGLE,
        icon: DrawRectangle,
        tools: [Tools.Rectangle, Tools.Ellipse],
    },
];

const moveShapes = [
    {
        type: ToolTypes.MOVE,
        icon: ArrowMoveIcon,
    },
    {
        type: ToolTypes.PICK,
        icon: PickMoveIcon,
    },
    {
        type: ToolTypes.MOVE2,
        icon: SquiggleMoveIcon,
    },
];

export const formationsTools = {
    [Sports.MensLax]: [
        {
            icon: _231Icon,
            name: '2-3-1',
            type: Formations._231,
        },
        {
            icon: _33Icon,
            name: '3-3',
            type: Formations._33,
        },
        {
            icon: _222Icon,
            name: '2-2-2',
            type: Formations._222,
        },
        {
            icon: _141Icon,
            name: '1-4-1',
            type: Formations._141,
        },
        {
            icon: CircleIcon,
            name: 'Circle',
            type: Formations.Circle,
        },
    ],
    [Sports.WomensLax]: [
        {
            icon: _232Icon,
            name: '2-3-2',
            type: Formations._232,
        },
        {
            icon: WomensCircleIcon,
            name: 'Circle',
            type: Formations.WomensCircle,
        },
    ],
    [Sports.BoxLax]: [
        {
            icon: _3out2inIcon,
            name: '3 out 2 in',
            type: Formations._3out2in,
        },
        {
            icon: _4out1inIcon,
            name: '4 out 1 in',
            type: Formations._4out1in,
        },
        {
            icon: _5outIcon,
            name: '5 out',
            type: Formations._5out,
        },
    ],
};

export default {
    offenseToolGroups,
    defenseToolGroups,
    otherToolGroups,
    drillToolGroups,
    moveShapes,
    formationsTools,
};
