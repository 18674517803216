import { useAssets } from '@labradorsports/assets';

const LoadingSpinner: FC = () => {
    const { LoadingIcon } = useAssets();

    return (
        <div className="loading-spinner">
            <img src={LoadingIcon} alt="" />
        </div>
    );
};

export default LoadingSpinner;
