/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { PlaybookTabs } from '@labradorsports/constants';
import { playEditorActions } from '../play-editor/index.js';
import { teamsActions } from '../teams/index.js';
import { authActions } from '../auth/index.js';

export interface ShareConfig {
    itemIds?: string[];
    myPlaybook?: boolean;
}

interface GIFDownload {
    playbookId: string;
    playId: string;
    programId: string;
    nonce: string;
    estimatedWait: number;
    name: string;
    previewUrl?: string;
    gifUrl?: string;
    error?: boolean;
    linkId?: string;
}

export interface PlaybookState {
    teamPlaybookId: string;

    shownPlaybook: string;
    sidePanelShown: boolean;

    playId: string;
    viewerDetailsPlayId: string;
    viewedPlayIds: string[];
    shareConfig: ShareConfig;
    viewershipProfile: any[];

    folderId: string;
    renameItemId: string;

    publicLinkId: string;
    publicLoadPlayError: boolean;

    printPlay: boolean;

    overrideMenuWithBack: boolean;

    gifDownloads: GIFDownload[];
}

const playbookDefaultState: PlaybookState = {
    teamPlaybookId: undefined,
    shownPlaybook: PlaybookTabs.MY,
    sidePanelShown: true,
    playId: undefined,
    shareConfig: undefined,
    viewershipProfile: undefined,
    viewerDetailsPlayId: undefined,
    viewedPlayIds: undefined,
    folderId: undefined,
    renameItemId: undefined,
    publicLinkId: undefined,
    publicLoadPlayError: false,
    printPlay: false,
    overrideMenuWithBack: false,
    gifDownloads: [],
};

const { reducer: playbookReducer, actions: playbookActions } = createSlice({
    name: 'playbook',
    initialState: playbookDefaultState,
    reducers: {
        OpenPlay: (state, action) => {
            state.playId = action.payload;
        },

        ShowViewerDetails: (state, action: PayloadAction<string | null>) => {
            state.viewerDetailsPlayId = action.payload;
        },

        ViewedPlaysLoaded: (state, action: PayloadAction<string[] | null>) => {
            state.viewedPlayIds = action.payload;
        },

        SetShownPlaybook: (state, action) => {
            if (action.payload !== state.shownPlaybook) {
                state.folderId = null;
                state.shownPlaybook = action.payload;
            }
        },

        OpenPlayFolder: (state, action) => {
            state.folderId = action.payload;
        },

        SetRenameItemId: (state, action: PayloadAction<string | null>) => {
            state.renameItemId = action.payload;
        },

        SetSharing: {
            prepare: (payload?: ShareConfig) => ({
                payload,
            }),
            reducer: (state, action: PayloadAction<ShareConfig>) => {
                state.shareConfig = action.payload;
            },
        },

        ShowSidePanel: (state, action) => {
            state.sidePanelShown = action.payload;
        },

        Printing: (state, action) => {
            state.printPlay = !!action.payload;
        },

        PublicLoadPlayError: (state, action) => {
            state.publicLoadPlayError = action.payload;
        },

        OverrideMenuWithBack: (state, action: PayloadAction<boolean | null>) => {
            state.overrideMenuWithBack = action.payload;
        },

        AddGIFDownload: (state, action: PayloadAction<GIFDownload>) => {
            // If a download already exists for this play, don't duplicate it
            const existingDownloads = state.gifDownloads.filter(
                (gd) =>
                    gd.playId !== action.payload.playId ||
                    gd.playbookId !== action.payload.playbookId ||
                    gd.programId !== action.payload.programId
            );
            state.gifDownloads = [...existingDownloads, action.payload];
        },

        RemoveGIFDownload: (state, action: PayloadAction<GIFDownload>) => {
            if (action.payload) {
                state.gifDownloads = state.gifDownloads.filter(
                    (gd) =>
                        gd.playId !== action.payload.playId ||
                        gd.playbookId !== action.payload.playbookId ||
                        gd.programId !== action.payload.programId
                );
            }
        },

        ViewershipProfileLoaded: (state, action: PayloadAction<any[] | null>) => {
            state.viewershipProfile = action.payload;
        },
    },
    extraReducers: {
        [authActions.PurgeProfile.type]: (state) => {
            return {
                ...playbookDefaultState,
                publicLinkId: state.publicLinkId,
                playId: state.shownPlaybook === PlaybookTabs.PUBLIC ? state.playId : undefined,
                shownPlaybook: state.shownPlaybook,
            };
        },

        [playEditorActions.CreatePlay.type]: (state, action) => {
            state.playId = action.payload.id;
        },

        [playEditorActions.PlayLoaded.type]: (state, action) => {
            state.playId = action.payload ? action.payload.id : undefined;
        },

        [playEditorActions.NewPlaySaved.type]: (state, action) => {
            state.playId = action.payload;
        },

        [teamsActions.SetActiveTeam.type]: (state) => {
            state.folderId = null;
            state.shownPlaybook = PlaybookTabs.TEAM;
            state.shareConfig = null;
        },
    },
});

export { playbookReducer, playbookActions, playbookDefaultState };
