import { useState, useEffect, useMemo, createContext, useContext } from 'react';

import { Sites } from '@labradorsports/constants';
import { getSiteForSport } from '@labradorsports/utils';
import { FieldViewport, Field, Play } from '../models/index.js';
import { clampZoomOffset, ZoomOffset } from '../utils/index.js';
import { usePlayEditor } from './playEditor.js';

export interface FieldViewportValue {
    fieldViewport: FieldViewport;
    zoomOffset: ZoomOffset;
    setZoomFactor: (zoomFactor: number) => void;
    setZoomOffset: (zoomOffset: ZoomOffset) => void;
    zoomIn: () => void;
    zoomOut: () => void;
    enableZoomIn: boolean;
    enableZoomOut: boolean;
}

export const FieldViewportContext = createContext<FieldViewportValue>(null);

export const useFieldViewport = (): FieldViewportValue => {
    return useContext(FieldViewportContext);
};

function getDefaultZoomOffset(PlayConfig: PlayConfigSpec, fieldViewport: FieldViewport, field: Field) {
    const { FieldTypes } = PlayConfig;
    // Always focus on the first goal
    const focusGoalie = field.fieldType === FieldTypes.CUSTOM ? null : field.pieces[0];
    const goalieDiffX = focusGoalie ? fieldViewport.getPixelLength(focusGoalie.origin.x - field.width / 2) : 0;
    const goalieDiffY = focusGoalie ? fieldViewport.getPixelLength(focusGoalie.origin.y - field.height / 2) : 0;
    const magnification = fieldViewport.zoomFactor - 1;

    const offset = fieldViewport.flipped
        ? {
              x: -(goalieDiffX * 0.75 - fieldViewport.w * magnification) / 2,
              y: -(goalieDiffY * 0.75 - fieldViewport.h * magnification) / 2,
          }
        : {
              x: (fieldViewport.w * magnification + goalieDiffX * 0.75) / 2,
              y: (fieldViewport.h * magnification + goalieDiffY * 0.75) / 2,
          };

    return clampZoomOffset(fieldViewport, offset);
}

function getDefaultZoomFactor(PlayConfig: PlayConfigSpec, play: Play): number {
    const { PlayTypes } = PlayConfig;
    const { sport, fieldType, unifiedField } = play ?? {};
    const { zoomLevels = [1, 1.5] } = PlayConfig.FieldSettings[sport]?.[fieldType] ?? {};

    if (unifiedField && fieldType === PlayTypes.HALF_FIELD) {
        return zoomLevels[1];
    }

    return getSiteForSport(sport) === Sites.footballlab ? zoomLevels[2] : zoomLevels[0];
}

export const FieldViewportProvider: FC = ({ children }) => {
    const { dimensions, play, playData, setDisableAnimation, PlayConfig } = usePlayEditor();
    const [zoomFactor, setZoomFactor] = useState<number>(null);
    const [zoomOffset, setZoomOffset] = useState<{
        x: number;
        y: number;
    }>({
        x: 0,
        y: 0,
    });

    const { zoomLevels = [1, 1.5] } = PlayConfig.FieldSettings[play?.sport]?.[play?.fieldType] ?? {};

    const fieldViewport = useMemo(
        () =>
            dimensions && play
                ? new FieldViewport(
                      play.field,
                      play.viewOptions.flipField,
                      zoomFactor ?? 1,
                      dimensions.width,
                      dimensions.height
                  )
                : null,
        [play, dimensions, zoomFactor]
    );

    useEffect(() => {
        setZoomFactor(getDefaultZoomFactor(PlayConfig, play));
    }, [playData?.id]);

    useEffect(() => {
        if (fieldViewport) {
            setZoomOffset(getDefaultZoomOffset(PlayConfig, fieldViewport, play.field));
        }
    }, [zoomFactor]);

    const updateZoomFactor = (newZoomFactor: number) => {
        setDisableAnimation(true);
        setZoomFactor(newZoomFactor);

        requestAnimationFrame(() => {
            requestAnimationFrame(() => {
                setDisableAnimation(false);
            });
        });
    };

    const currentZoomIdx = zoomLevels.indexOf(fieldViewport?.zoomFactor);

    const zoomIn = () => {
        const newZoomLevelIdx = Math.min(zoomLevels.length - 1, currentZoomIdx + 1);
        updateZoomFactor(zoomLevels[newZoomLevelIdx]);
    };

    const zoomOut = () => {
        const newZoomLevelIdx = Math.max(0, currentZoomIdx - 1);
        updateZoomFactor(zoomLevels[newZoomLevelIdx]);
    };

    return (
        <FieldViewportContext.Provider
            value={{
                fieldViewport,
                zoomOffset,
                setZoomFactor: updateZoomFactor,
                setZoomOffset,
                zoomIn,
                zoomOut,
                enableZoomIn: currentZoomIdx < zoomLevels.length - 1,
                enableZoomOut: currentZoomIdx > 0,
            }}
        >
            {children}
        </FieldViewportContext.Provider>
    );
};
