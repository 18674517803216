import { useEffect, useState } from 'react';
import { Button, ClickAwayListener } from '@mui/material';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { useAssets } from '@labradorsports/assets';
import { SimpleModal, StyledSnackbar } from '@labradorsports/components';
import { useDispatcher } from '../../shared/hooks/index.js';
import { RootState, Selectors, teamsActions } from '../../store/index.js';
import { JoinTeam, JoinTeamSuccess } from '../index.js';

interface Props {
    onSelect?: (team: any) => any;
    onOpen?: () => any;
}

const TeamOption = (props: any) => {
    const { team, onClick } = props;

    return (
        <div
            onClick={onClick}
            className={`team-option ${team.paid ? '' : 'unpaid'} ${team.pending ? 'pending' : ''}`}
            data-cy="team-option"
        >
            <span className="team-name">{team.name ? team.name : <i>Unnamed Team </i>}</span>
        </div>
    );
};

const TeamsMenu: FC<Props> = ({ onOpen, onSelect }) => {
    const { AddIcon } = useAssets();
    const setActiveTeam = useDispatcher(teamsActions.SetActiveTeam);
    const activeTeam = useSelector(Selectors.activeTeam);
    const userTeams = useSelector((state: RootState) => state.teams.userTeams ?? []);
    const joinedTeams = useSelector((state: RootState) => state.teams.joinedTeams ?? []);
    const pendingJoinTeams = useSelector((state: RootState) => state.teams.pendingJoinTeams ?? []);
    const location = useLocation();
    const navigate = useNavigate();
    const [showDropdown, setShowDropdown] = useState(false);
    const [showJoin, setShowJoin] = useState(false);
    const [joinResult, setJoinResult] = useState(null);
    const [showUnpaidMessage, setShowUnpaidMessage] = useState(false);
    const [showUnpaidMore, setShowUnpaidMore] = useState(false);

    useEffect(() => {
        setShowDropdown(false);
    }, [location.pathname]);

    const hideMenu = () => setShowDropdown(false);

    const toggleMenu = () => {
        if (!showDropdown && onOpen) {
            onOpen();
        }

        setShowDropdown(!showDropdown);
        setShowJoin(false);
    };

    const manageTeams = () => {
        navigate('/profile/teamadmin');
    };

    const selectTeam = (team: any) => {
        setActiveTeam(team.id);

        setShowDropdown(false);
        setShowUnpaidMessage(!team.paid && !team.pending);

        if (onSelect) {
            onSelect(team);
        }
    };

    const teamJoined = (result: any) => {
        setShowDropdown(false);
        setShowJoin(false);
        setJoinResult(result);
    };

    const dismissTeamJoined = () => setJoinResult(null);

    const doShowJoin = () => setShowJoin(true);

    const handleClickOutside = () => {
        if (showDropdown) {
            hideMenu();
        }
    };

    const hideUnpaidMessage = () => setShowUnpaidMessage(false);

    const doShowUnpaidMore = () => {
        setShowUnpaidMessage(false);
        setShowUnpaidMore(true);
    };

    const hideUnpaidMore = () => setShowUnpaidMore(false);

    const teams = userTeams.concat(joinedTeams).concat(pendingJoinTeams);

    const curTeam = teams.find((tm: any) => tm.id === activeTeam);

    if (activeTeam && !curTeam) {
        return null;
    }

    let menuButton;
    if (!curTeam) {
        menuButton = (
            <Button className="menu-button" onClick={toggleMenu} fullWidth>
                <img src={AddIcon} alt="" />
                {APP ? 'Join' : 'Join/Create'} Team
            </Button>
        );
    } else {
        menuButton = (
            <div className="menu-button">
                <TeamOption team={curTeam} onClick={toggleMenu} />
            </div>
        );
    }

    const getTeam = (tm: any) => {
        return <TeamOption team={tm} key={tm.id} onClick={() => selectTeam(tm)} active={tm === curTeam} />;
    };

    const joinedTeamList = joinedTeams.map(getTeam);
    const userTeamsList = userTeams.map(getTeam);
    const pendingTeamsList = pendingJoinTeams.map(getTeam);

    const teamsLength = joinedTeamList.length + userTeamsList.length + pendingTeamsList.length;

    const teamsListDisplay = (
        <>
            {joinedTeamList.length > 0 ? (
                <>
                    {joinedTeamList}
                    <div className="menu-separator" />
                </>
            ) : undefined}
            {userTeamsList.length > 0 ? (
                <>
                    {userTeamsList}
                    <div className="menu-separator" />
                </>
            ) : undefined}
            {pendingTeamsList.length > 0 ? (
                <>
                    {pendingTeamsList}
                    <div className="menu-separator" />
                </>
            ) : undefined}
        </>
    );

    return (
        <ClickAwayListener onClickAway={handleClickOutside}>
            <div className="teams-menu" data-cy="teams-menu">
                {menuButton}
                <div className={`drop-down ${showDropdown ? '' : 'hidden'}`}>
                    {teamsLength > 0 ? teamsListDisplay : undefined}
                    {showJoin ? (
                        <JoinTeam className="p-2" onJoined={teamJoined} />
                    ) : (
                        <Button onClick={doShowJoin} className="header-menu-button">
                            Join Team
                        </Button>
                    )}
                    {!APP ? (
                        <Button onClick={manageTeams} className="header-menu-button">
                            Manage Subscriptions
                        </Button>
                    ) : null}
                </div>

                <JoinTeamSuccess onClose={dismissTeamJoined} joinResult={joinResult} />
                <StyledSnackbar
                    onClose={hideUnpaidMessage}
                    message="This team is currently inactive"
                    action={[
                        <Button key="0" color="secondary" onClick={doShowUnpaidMore}>
                            Learn More
                        </Button>,
                    ]}
                    open={showUnpaidMessage}
                />
                <SimpleModal
                    open={showUnpaidMore}
                    text="The subscription for this team is currently inactive. If you are the owner of this team, you can update your subscription on the MANAGE TEAMS screen. Otherwise, contact your team admin."
                    onClose={hideUnpaidMore}
                />
            </div>
        </ClickAwayListener>
    );
};

export default TeamsMenu;
