import { Button } from '@mui/material';

import { animatedModal } from '../index.js';
import { AnimatedModalProps } from '../animated-modal/animated-modal.js';

interface SimpleModalProps {
    children?: any;
    text?: string;
    className?: string;
    buttons?: {
        text: string;
        action?: AnyFunction;
        style?: any;
        color?: string;
        disabled?: boolean;
        variant?: string;
    }[];
}

function SimpleModal(props: SimpleModalProps & AnimatedModalProps) {
    let { buttons } = props;
    const { children, text, onClose, className = '' } = props;

    if (!buttons || buttons.length === 0) {
        buttons = [
            {
                text: 'OK',
                color: 'primary',
            },
        ];
    }

    const btns = buttons.map((btn, idx) => {
        const variant = btn.color ? 'contained' : btn.variant;
        return (
            <Button
                className="sm-btn"
                disabled={btn.disabled}
                color={(btn.color as any) ?? 'inherit'}
                key={idx}
                style={btn.style}
                variant={variant as any}
                onClick={btn.action ?? onClose}
            >
                {btn.text}
            </Button>
        );
    });

    return (
        <div className={className}>
            {text ? <p>{text}</p> : children}
            {btns}
        </div>
    );
}

export default animatedModal<SimpleModalProps>(SimpleModal, { className: 'simple-modal', narrow: true });
