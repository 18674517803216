import { ComponentType, lazy, LazyExoticComponent } from 'react';

import { UpdateModal } from '@labradorsports/components';
import { createException, GeneralErrors } from '@labradorsports/utils';

export default function lazyWrapper(load: () => Promise<any>): LazyExoticComponent<ComponentType<any>> {
    return lazy(() =>
        load().catch((error) => {
            if (error.message.includes('Loading chunk')) {
                return {
                    __esModule: true,
                    default: UpdateModal,
                };
            }

            throw createException(GeneralErrors.UNKNOWN_ERROR, { nestedError: error });
        })
    );
}
