import { ThunkAction, ThunkDispatch, Action } from '@reduxjs/toolkit';

import { AuthenticationErrors, Exception, getCommonError } from '@labradorsports/utils';
import Logger from '../shared/logger.js';
import { CFFetcher } from '../shared/cloud-functions.js';
import { mainActions } from './main/index.js';
import { authActions } from './auth/index.js';
import { RootState } from './state.js';

export type TA<RT> = ThunkAction<Promise<RT>, RootState, ThunkContext, Action>;
export type TD = ThunkDispatch<RootState, ThunkContext, Action>;

interface AsyncOptions {
    showLoading?: boolean;
    suppressError?: boolean;
    retryable?: boolean;
}

const RetryableErrors = [
    'An error occurred',
    'unavailable',
    'Failed to fetch',
    'Load failed',
    'Failed to get document because the client is offline.',
    'Internal Error',
];

export interface ThunkContext {
    cff: CFFetcher;
    logger: Logger;
    site: SiteSpec;
}

export const asyncWrapper = <T>(
    funcName: string,
    func: (...args: any[]) => T,
    { showLoading = true, suppressError = false, retryable = false }: AsyncOptions = {}
): TA<Awaited<T>> => {
    return async (dispatch: TD, getState: () => RootState, thunkContext: ThunkContext): Promise<Awaited<T>> => {
        const state = getState();

        if (showLoading) {
            dispatch(mainActions.Loading(true));
        }

        const tryExecute = async (retriesLeft) => {
            try {
                const result = await func(dispatch, state, thunkContext, getState);

                if (showLoading) {
                    dispatch(mainActions.Loading(false));
                }

                return result;
            } catch (err) {
                const checkErrors = [err.code, err.message, err.nestedError?.code, err.nestedError?.message].filter(
                    Boolean
                );

                if (retryable && retriesLeft > 0) {
                    thunkContext.logger.log('retryable checkErrors', { checkErrors });

                    if (checkErrors.length === 0 || Boolean(checkErrors.find((e) => RetryableErrors.includes(e)))) {
                        thunkContext.logger.log(`retrying ${funcName}`, {
                            retriesLeft,
                        });

                        return tryExecute(retriesLeft - 1);
                    }
                }

                if (showLoading) {
                    dispatch(mainActions.Loading(false));
                }

                if (!PROD && !(err instanceof Exception)) {
                    console.log(err);
                }

                thunkContext.logger.log('retries', { retryable, retriesLeft });

                const exc = getCommonError(err);

                thunkContext.logger.exception(exc);

                if (exc.is(AuthenticationErrors.REQUIRES_RECENT_LOGIN)) {
                    dispatch(authActions.Reauthenticating(true));

                    return {
                        data: undefined,
                    };
                }

                if (!suppressError) {
                    dispatch(mainActions.GenericError(exc));
                }

                throw exc;
            }
        };

        return tryExecute(2);
    };
};

export * from './play-editor/async-actions.js';
export * from './teams/async-actions.js';
export * from './main/async-actions.js';
export * from './community/async-actions.js';
