import { createContext, useContext, useRef, MutableRefObject } from 'react';

export interface FieldContainerContextValue {
    containerRef: MutableRefObject<HTMLDivElement>;
}

export const FieldContainerContext = createContext<FieldContainerContextValue>(null);

export const useFieldContainer = (): FieldContainerContextValue => {
    return useContext(FieldContainerContext);
};

export const FieldContainerProvider: FC = ({ children }) => {
    const containerRef = useRef();

    return (
        <FieldContainerContext.Provider
            value={{
                containerRef,
            }}
        >
            {children}
        </FieldContainerContext.Provider>
    );
};
