import { useState } from 'react';
import { Button } from '@mui/material';

import { collectFolders, getFolder } from '@labradorsports/utils';

interface FolderItemProps {
    allFolders: Folder[];
    folder: Folder;
    folderClick: (folderId: string) => any;
    selectedId: string;
    disabledFolderIds?: string[];
    depth: number;
}

const FolderItem: FC<FolderItemProps> = ({ allFolders, folder, folderClick, selectedId, disabledFolderIds, depth }) => {
    const [expanded, setExpanded] = useState(true);
    const hasChildFolders = folder.folders?.length > 0;

    const onFolderClick = () => {
        folderClick(folder.id);
    };

    const isSelected = Boolean(selectedId) && folder.id === selectedId;

    const disabledFolders = disabledFolderIds.map((folderId) => getFolder(allFolders, folderId));
    const disabledFoldersAndChildren = collectFolders(allFolders, disabledFolders);

    // Disable this folder if it is, or is nested under, the disabled folder
    // The root-level folder is mocked as a true folder, so it has a path of 0
    // Root level folder can never be disabled because it is always a valid target
    const disabled = disabledFolderIds?.includes(folder.id) || disabledFoldersAndChildren.includes(folder.id);

    const childFolders = folder.folders?.map((folderId) => getFolder(allFolders, folderId)).filter(Boolean);

    return (
        <div className={`folder-list-item ${disabled ? 'disabled' : ''}`}>
            <div
                className={`folder-name ${isSelected ? 'selected' : ''}`}
                style={{ paddingLeft: `${depth * 14}px` }}
                onClick={disabled ? null : onFolderClick}
            >
                <Button onClick={() => setExpanded(!expanded)} disabled={disabled}>
                    <div className="expand-icon">{hasChildFolders ? <span>{expanded ? '-' : '+'}</span> : null}</div>
                    {folder.name}
                </Button>
            </div>
            {hasChildFolders && expanded ? (
                // eslint-disable-next-line @typescript-eslint/no-use-before-define
                <FolderList
                    allFolders={allFolders}
                    folders={childFolders}
                    folderClick={folderClick}
                    selectedId={selectedId}
                    disabledFolderIds={disabledFolderIds}
                    depth={depth + 1}
                />
            ) : null}
        </div>
    );
};

interface Props {
    allFolders: Folder[];
    folders: Folder[];
    folderClick: (folderId: string) => any;
    selectedId: string;
    disabledFolderIds?: string[];
    depth?: number;
}

const FolderList: FC<Props> = ({ allFolders, folders, folderClick, selectedId, disabledFolderIds, depth = 0 }) => {
    return (
        <div className="folder-list">
            {folders.map((folder, idx) => (
                <FolderItem
                    allFolders={allFolders}
                    key={idx}
                    folder={folder}
                    folderClick={folderClick}
                    selectedId={selectedId}
                    disabledFolderIds={disabledFolderIds}
                    depth={depth}
                />
            ))}
        </div>
    );
};

export default FolderList;
