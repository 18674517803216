import { createAssetGetter } from '@labradorsports/assets';
import { hoopslab } from '@labradorsports/constants';

export const Logo = createAssetGetter('img/basketball/logo.svg');

export const SiteConfig = {
    ...hoopslab.SiteConfig,
    AppStoreUrl: 'https://apps.apple.com/il/app/hoops-lab/id1576985969',
    GooglePlayUrl: 'https://play.google.com/store/apps/details?id=com.labradorsports.hoopslab&hl=en_US&gl=US',
    Logo,
    PlaybookDescription:
        'Free play designer to create and share your basketball playbook. Create offense, defense and clearing plays. Add moves, passes, and comments to help guide your players.',
};

export const GoogleAnalyticsID = 'G-VWGLFQGBPD';

export const Sports = hoopslab.Sports;

export const SportsNames = {
    [Sports.HighSchoolBBall]: 'High-School Basketball',
    [Sports.MensCollegeBBall]: "Men's College Basketball",
    [Sports.WomensCollegeBBall]: "Women's College Basketball",
};

export const PlayerPositions = {
    POINT_GUARD: 'POINT_GUARD',
    SHOOTING_GUARD: 'SHOOTING_GUARD',
    SMALL_FORWARD: 'SMALL_FORWARD',
    POWER_FORWARD: 'POWER_FORWARD',
    CENTER: 'CENTER',
};

export const PlayerPositionNames = {
    [PlayerPositions.POINT_GUARD]: 'Point Guard',
    [PlayerPositions.SHOOTING_GUARD]: 'Shooting Guard',
    [PlayerPositions.SMALL_FORWARD]: 'Small Forward',
    [PlayerPositions.POWER_FORWARD]: 'Power Forward',
    [PlayerPositions.CENTER]: 'Center',
};

export const SportPositions = {
    [Sports.HighSchoolBBall]: [
        PlayerPositions.POINT_GUARD,
        PlayerPositions.SHOOTING_GUARD,
        PlayerPositions.SMALL_FORWARD,
        PlayerPositions.POWER_FORWARD,
        PlayerPositions.CENTER,
    ],
    [Sports.MensCollegeBBall]: [
        PlayerPositions.POINT_GUARD,
        PlayerPositions.SHOOTING_GUARD,
        PlayerPositions.SMALL_FORWARD,
        PlayerPositions.POWER_FORWARD,
        PlayerPositions.CENTER,
    ],
    [Sports.WomensCollegeBBall]: [
        PlayerPositions.POINT_GUARD,
        PlayerPositions.SHOOTING_GUARD,
        PlayerPositions.SMALL_FORWARD,
        PlayerPositions.POWER_FORWARD,
        PlayerPositions.CENTER,
    ],
};

export const PlayerRatingCategories = {
    SPEED: 'SPEED',
    JUMP: 'JUMP',
    AGILITY: 'AGILITY',
    STRENGTH: 'STRENGTH',
    DRIBBLE: 'DRIBBLE',
    INSIDE_SCORING: 'INSIDE_SCORING',
    MIDRANGE_SCORING: 'MIDRANGE_SCORING',
    THREE_PT_SCORING: 'THREE_PT_SCORING',
    OFFENSIVE_REBOUND: 'OFFENSIVE_REBOUND',
    DEFENSIVE_REBOUND: 'DEFENSIVE_REBOUND',
    CATCH: 'CATCH',
    STEAL: 'STEAL',
    ONBALL_DEFENSE: 'ONBALL_DEFENSE',
    OFFBALL_DEFENSE: 'OFFBALL_DEFENSE',
    BLOCK: 'BLOCK',
    COURT_VISION: 'COURT_VISION',
    OFFENSE_IQ: 'OFFENSE_IQ',
    DEFENSE_IQ: 'DEFENSE_IQ',
    CONDITIONING: 'CONDITIONING',
    GRIT: 'GRIT',
};

export const PointGuardWeights = {
    [PlayerRatingCategories.SPEED]: 10,
    [PlayerRatingCategories.JUMP]: 6,
    [PlayerRatingCategories.AGILITY]: 10,
    [PlayerRatingCategories.STRENGTH]: 4,
    [PlayerRatingCategories.DRIBBLE]: 10,
    [PlayerRatingCategories.INSIDE_SCORING]: 3,
    [PlayerRatingCategories.MIDRANGE_SCORING]: 7,
    [PlayerRatingCategories.THREE_PT_SCORING]: 7,
    [PlayerRatingCategories.OFFENSIVE_REBOUND]: 4,
    [PlayerRatingCategories.DEFENSIVE_REBOUND]: 4,
    [PlayerRatingCategories.CATCH]: 7,
    [PlayerRatingCategories.STEAL]: 7,
    [PlayerRatingCategories.ONBALL_DEFENSE]: 10,
    [PlayerRatingCategories.OFFBALL_DEFENSE]: 10,
    [PlayerRatingCategories.BLOCK]: 3,
    [PlayerRatingCategories.COURT_VISION]: 10,
    [PlayerRatingCategories.OFFENSE_IQ]: 10,
    [PlayerRatingCategories.DEFENSE_IQ]: 8,
    [PlayerRatingCategories.CONDITIONING]: 10,
    [PlayerRatingCategories.GRIT]: 10,
};

export const ShootingGuardWeights = {
    [PlayerRatingCategories.SPEED]: 8,
    [PlayerRatingCategories.JUMP]: 8,
    [PlayerRatingCategories.AGILITY]: 9,
    [PlayerRatingCategories.STRENGTH]: 4,
    [PlayerRatingCategories.DRIBBLE]: 9,
    [PlayerRatingCategories.INSIDE_SCORING]: 5,
    [PlayerRatingCategories.MIDRANGE_SCORING]: 7,
    [PlayerRatingCategories.THREE_PT_SCORING]: 9,
    [PlayerRatingCategories.OFFENSIVE_REBOUND]: 6,
    [PlayerRatingCategories.DEFENSIVE_REBOUND]: 6,
    [PlayerRatingCategories.CATCH]: 7,
    [PlayerRatingCategories.STEAL]: 7,
    [PlayerRatingCategories.ONBALL_DEFENSE]: 10,
    [PlayerRatingCategories.OFFBALL_DEFENSE]: 10,
    [PlayerRatingCategories.BLOCK]: 8,
    [PlayerRatingCategories.COURT_VISION]: 8,
    [PlayerRatingCategories.OFFENSE_IQ]: 8,
    [PlayerRatingCategories.DEFENSE_IQ]: 8,
    [PlayerRatingCategories.CONDITIONING]: 8,
    [PlayerRatingCategories.GRIT]: 10,
};

export const SmallForwardWeights = {
    [PlayerRatingCategories.SPEED]: 6,
    [PlayerRatingCategories.JUMP]: 8,
    [PlayerRatingCategories.AGILITY]: 8,
    [PlayerRatingCategories.STRENGTH]: 6,
    [PlayerRatingCategories.DRIBBLE]: 8,
    [PlayerRatingCategories.INSIDE_SCORING]: 6,
    [PlayerRatingCategories.MIDRANGE_SCORING]: 7,
    [PlayerRatingCategories.THREE_PT_SCORING]: 7,
    [PlayerRatingCategories.OFFENSIVE_REBOUND]: 7,
    [PlayerRatingCategories.DEFENSIVE_REBOUND]: 7,
    [PlayerRatingCategories.CATCH]: 7,
    [PlayerRatingCategories.STEAL]: 7,
    [PlayerRatingCategories.ONBALL_DEFENSE]: 10,
    [PlayerRatingCategories.OFFBALL_DEFENSE]: 10,
    [PlayerRatingCategories.BLOCK]: 5,
    [PlayerRatingCategories.COURT_VISION]: 6,
    [PlayerRatingCategories.OFFENSE_IQ]: 8,
    [PlayerRatingCategories.DEFENSE_IQ]: 8,
    [PlayerRatingCategories.CONDITIONING]: 8,
    [PlayerRatingCategories.GRIT]: 10,
};

export const PowerForwardWeights = {
    [PlayerRatingCategories.SPEED]: 4,
    [PlayerRatingCategories.JUMP]: 5,
    [PlayerRatingCategories.AGILITY]: 6,
    [PlayerRatingCategories.STRENGTH]: 8,
    [PlayerRatingCategories.DRIBBLE]: 5,
    [PlayerRatingCategories.INSIDE_SCORING]: 10,
    [PlayerRatingCategories.MIDRANGE_SCORING]: 5,
    [PlayerRatingCategories.THREE_PT_SCORING]: 4,
    [PlayerRatingCategories.OFFENSIVE_REBOUND]: 10,
    [PlayerRatingCategories.DEFENSIVE_REBOUND]: 10,
    [PlayerRatingCategories.CATCH]: 7,
    [PlayerRatingCategories.STEAL]: 3,
    [PlayerRatingCategories.ONBALL_DEFENSE]: 10,
    [PlayerRatingCategories.OFFBALL_DEFENSE]: 10,
    [PlayerRatingCategories.BLOCK]: 8,
    [PlayerRatingCategories.COURT_VISION]: 4,
    [PlayerRatingCategories.OFFENSE_IQ]: 8,
    [PlayerRatingCategories.DEFENSE_IQ]: 8,
    [PlayerRatingCategories.CONDITIONING]: 6,
    [PlayerRatingCategories.GRIT]: 10,
};

export const CenterWeights = {
    [PlayerRatingCategories.SPEED]: 4,
    [PlayerRatingCategories.JUMP]: 5,
    [PlayerRatingCategories.AGILITY]: 6,
    [PlayerRatingCategories.STRENGTH]: 8,
    [PlayerRatingCategories.DRIBBLE]: 5,
    [PlayerRatingCategories.INSIDE_SCORING]: 10,
    [PlayerRatingCategories.MIDRANGE_SCORING]: 5,
    [PlayerRatingCategories.THREE_PT_SCORING]: 2,
    [PlayerRatingCategories.OFFENSIVE_REBOUND]: 10,
    [PlayerRatingCategories.DEFENSIVE_REBOUND]: 10,
    [PlayerRatingCategories.CATCH]: 7,
    [PlayerRatingCategories.STEAL]: 3,
    [PlayerRatingCategories.ONBALL_DEFENSE]: 10,
    [PlayerRatingCategories.OFFBALL_DEFENSE]: 10,
    [PlayerRatingCategories.BLOCK]: 8,
    [PlayerRatingCategories.COURT_VISION]: 4,
    [PlayerRatingCategories.OFFENSE_IQ]: 8,
    [PlayerRatingCategories.DEFENSE_IQ]: 8,
    [PlayerRatingCategories.CONDITIONING]: 6,
    [PlayerRatingCategories.GRIT]: 10,
};

export const PlayerRatingWeights = {
    [PlayerPositions.POINT_GUARD]: PointGuardWeights,
    [PlayerPositions.SHOOTING_GUARD]: ShootingGuardWeights,
    [PlayerPositions.SMALL_FORWARD]: SmallForwardWeights,
    [PlayerPositions.POWER_FORWARD]: PowerForwardWeights,
    [PlayerPositions.CENTER]: CenterWeights,
};

export const PlayerRatingSections = [
    {
        title: 'Athleticism',
        ratings: [
            {
                key: PlayerRatingCategories.SPEED,
                title: 'Speed',
            },
            {
                key: PlayerRatingCategories.JUMP,
                title: 'Jump',
            },
            {
                key: PlayerRatingCategories.AGILITY,
                title: 'Agility',
            },
            {
                key: PlayerRatingCategories.STRENGTH,
                title: 'Strength',
            },
        ],
    },
    {
        title: 'Skills',
        ratings: [
            {
                key: PlayerRatingCategories.DRIBBLE,
                title: 'Dribble',
            },
            {
                key: PlayerRatingCategories.INSIDE_SCORING,
                title: 'Inside Scoring',
            },
            {
                key: PlayerRatingCategories.MIDRANGE_SCORING,
                title: 'Mid-Range Scoring',
            },
            {
                key: PlayerRatingCategories.THREE_PT_SCORING,
                title: '3-PT Scoring',
            },
            {
                key: PlayerRatingCategories.OFFENSIVE_REBOUND,
                title: 'Offensive Rebound',
            },
            {
                key: PlayerRatingCategories.DEFENSIVE_REBOUND,
                title: 'Defensive Rebound',
            },
            {
                key: PlayerRatingCategories.CATCH,
                title: 'Catch',
            },
            {
                key: PlayerRatingCategories.STEAL,
                title: 'Steal',
            },
            {
                key: PlayerRatingCategories.ONBALL_DEFENSE,
                title: 'On-Ball Defense',
            },
            {
                key: PlayerRatingCategories.OFFBALL_DEFENSE,
                title: 'Off-Ball Defense',
            },
            {
                key: PlayerRatingCategories.BLOCK,
                title: 'Block',
            },
        ],
    },
    {
        title: 'Awareness',
        ratings: [
            {
                key: PlayerRatingCategories.COURT_VISION,
                title: 'Court Vision',
            },
            {
                key: PlayerRatingCategories.OFFENSE_IQ,
                title: 'Offense IQ',
            },
            {
                key: PlayerRatingCategories.DEFENSE_IQ,
                title: 'Defense IQ',
            },
        ],
    },
    {
        title: 'Intangibles',
        ratings: [
            {
                key: PlayerRatingCategories.CONDITIONING,
                title: 'Conditioning',
            },
            {
                key: PlayerRatingCategories.GRIT,
                title: 'Grit',
            },
        ],
    },
];

export const PlayTags = ['Offense', 'Defense', 'Press', 'Press Break', 'Out-of-Bounds'];

export const DrillSkillTags = ['Ball Handling', 'Read-and-React', 'Transition'];

export const DepthSections = {
    starters: 'starters',
};

export const DepthConfig: any = {
    [Sports.HighSchoolBBall]: {
        [DepthSections.starters]: {
            name: 'Starters',
            starters: 5,
        },
    },
    [Sports.MensCollegeBBall]: {
        [DepthSections.starters]: {
            name: 'Starters',
            starters: 5,
        },
    },
    [Sports.WomensCollegeBBall]: {
        [DepthSections.starters]: {
            name: 'Starters',
            starters: 5,
        },
    },
};

export const DepthChartLayout = {
    [Sports.HighSchoolBBall]: [[DepthSections.starters]],
    [Sports.MensCollegeBBall]: [[DepthSections.starters]],
    [Sports.WomensCollegeBBall]: [[DepthSections.starters]],
};

// No variation from roster display names
export const PlayerRoleNames = PlayerPositionNames;

export const TeamSizes = hoopslab.TeamSizes;

export const AddonConfig = hoopslab.AddonConfig;

export const ScoutingReportStats = {
    GAMES: 'GAMES',
    TOTAL_POINTS: 'TOTAL_POINTS',
    POINTS_PER_GAME: 'POINTS_PER_GAME',
    SCORING_MARGIN: 'SCORING_MARGIN',
    FG_MADE_ATTEMPTED: 'FG_MADE_ATTEMPTED',
    FG_PCT: 'FG_PCT',
    FG_PER_GAME: 'FG_PER_GAME',
    _3PT_MADE_ATTEMPTED: '_3PT_MADE_ATTEMPTED',
    _3PT_PCT: '_3PT_PCT',
    _3PT_PER_GAME: '_3PT_PER_GAME',
    FT_MADE_ATTEMPTED: 'FT_MADE_ATTEMPTED',
    FT_PCT: 'FT_PCT',
    FT_PER_GAME: 'FT_PER_GAME',
    REBOUND_TOTAL: 'REBOUND_TOTAL',
    REBOUND_PER_GAME: 'REBOUND_PER_GAME',
    REBOUND_MARGIN: 'REBOUND_MARGIN',
    ASSISTS_TOTAL: 'ASSISTS_TOTAL',
    ASSISTS_PER_GAME: 'ASSISTS_PER_GAME',
    TURNOVERS_TOTAL: 'TURNOVERS_TOTAL',
    TURNOVERS_PER_GAME: 'TURNOVERS_PER_GAME',
    TURNOVERS_MARGIN: 'TURNOVERS_MARGIN',
    ASSIST_TURNOVER_RATIO: 'ASSIST_TURNOVER_RATIO',
    POINTS_OFF_TURNOVERS: 'POINTS_OFF_TURNOVERS',
};

export const ScoutingReportStatDetails = {
    [ScoutingReportStats.GAMES]: {
        key: ScoutingReportStats.GAMES,
        name: 'Games',
        shortKey: 'GP',
    },
    [ScoutingReportStats.TOTAL_POINTS]: {
        key: ScoutingReportStats.TOTAL_POINTS,
        shortKey: 'PTS',
        name: 'Total Points',
    },
    [ScoutingReportStats.POINTS_PER_GAME]: {
        key: ScoutingReportStats.POINTS_PER_GAME,
        name: 'Points Per Game',
        formula: [ScoutingReportStats.TOTAL_POINTS, '/', ScoutingReportStats.GAMES],
    },
    [ScoutingReportStats.SCORING_MARGIN]: {
        key: ScoutingReportStats.SCORING_MARGIN,
        name: 'Scoring Margin',
    },
    [ScoutingReportStats.FG_MADE_ATTEMPTED]: {
        key: ScoutingReportStats.FG_MADE_ATTEMPTED,
        name: 'FG: Made-Attempted',
        format: '-',
    },
    [ScoutingReportStats.FG_PCT]: {
        key: ScoutingReportStats.FG_PCT,
        name: 'FG: Percentage',
        formula: ['a-b', ScoutingReportStats.FG_MADE_ATTEMPTED, 'a', '/', 'b'],
    },
    [ScoutingReportStats.FG_PER_GAME]: {
        key: ScoutingReportStats.FG_PER_GAME,
        name: 'FG: Per Game',
        formula: ['a-b', ScoutingReportStats.FG_MADE_ATTEMPTED, 'a', '/', ScoutingReportStats.GAMES],
    },
    [ScoutingReportStats._3PT_MADE_ATTEMPTED]: {
        key: ScoutingReportStats._3PT_MADE_ATTEMPTED,
        name: '3PT: Made-Attempted',
        format: '-',
    },
    [ScoutingReportStats._3PT_PCT]: {
        key: ScoutingReportStats._3PT_PCT,
        name: '3PT: Percentage',
        formula: ['a-b', ScoutingReportStats._3PT_MADE_ATTEMPTED, 'a', '/', 'b'],
    },
    [ScoutingReportStats._3PT_PER_GAME]: {
        key: ScoutingReportStats._3PT_PER_GAME,
        name: '3PT: Per Game',
        formula: ['a-b', ScoutingReportStats._3PT_MADE_ATTEMPTED, 'a', '/', ScoutingReportStats.GAMES],
    },
    [ScoutingReportStats.FT_MADE_ATTEMPTED]: {
        key: ScoutingReportStats.FT_MADE_ATTEMPTED,
        name: 'FT: Made-Attempted',
        format: '-',
    },
    [ScoutingReportStats.FT_PCT]: {
        key: ScoutingReportStats.FT_PCT,
        name: 'FT: Percentage',
        formula: ['a-b', ScoutingReportStats.FT_MADE_ATTEMPTED, 'a', '/', 'b'],
    },
    [ScoutingReportStats.FT_PER_GAME]: {
        key: ScoutingReportStats.FT_PER_GAME,
        name: 'FT: Per Game',
        formula: ['a-b', ScoutingReportStats.FT_MADE_ATTEMPTED, 'a', '/', ScoutingReportStats.GAMES],
    },
    [ScoutingReportStats.REBOUND_TOTAL]: {
        key: ScoutingReportStats.REBOUND_TOTAL,
        shortKey: 'RB',
        name: 'Total Rebounds',
    },
    [ScoutingReportStats.REBOUND_PER_GAME]: {
        key: ScoutingReportStats.REBOUND_PER_GAME,
        name: 'Rebounds Per Game',
        formula: [ScoutingReportStats.REBOUND_TOTAL, '/', ScoutingReportStats.GAMES],
    },
    [ScoutingReportStats.REBOUND_MARGIN]: {
        key: ScoutingReportStats.REBOUND_MARGIN,
        name: 'Margin',
    },
    [ScoutingReportStats.ASSISTS_TOTAL]: {
        key: ScoutingReportStats.ASSISTS_TOTAL,
        shortKey: 'AST',
        name: 'Total Assists',
    },
    [ScoutingReportStats.ASSISTS_PER_GAME]: {
        key: ScoutingReportStats.ASSISTS_PER_GAME,
        name: 'Assists Per Game',
        formula: [ScoutingReportStats.ASSISTS_TOTAL, '/', ScoutingReportStats.GAMES],
    },
    [ScoutingReportStats.TURNOVERS_TOTAL]: {
        key: ScoutingReportStats.TURNOVERS_TOTAL,
        shortKey: 'TO',
        name: 'Total Turnovers',
        lowerIsBetter: true,
    },
    [ScoutingReportStats.TURNOVERS_PER_GAME]: {
        key: ScoutingReportStats.TURNOVERS_PER_GAME,
        name: 'Turnovers Per Game',
        formula: [ScoutingReportStats.TURNOVERS_TOTAL, '/', ScoutingReportStats.GAMES],
        lowerIsBetter: true,
    },
    [ScoutingReportStats.TURNOVERS_MARGIN]: {
        key: ScoutingReportStats.TURNOVERS_MARGIN,
        name: 'Margin',
    },
    [ScoutingReportStats.ASSIST_TURNOVER_RATIO]: {
        key: ScoutingReportStats.ASSIST_TURNOVER_RATIO,
        name: 'Assist/Turnover Ratio',
        formula: [ScoutingReportStats.ASSISTS_TOTAL, '/', ScoutingReportStats.TURNOVERS_TOTAL],
    },
    [ScoutingReportStats.POINTS_OFF_TURNOVERS]: {
        key: ScoutingReportStats.POINTS_OFF_TURNOVERS,
        name: 'Points Off Turnovers',
    },
};

const bballScoutingReportStats = [
    {
        categoryName: 'PREVIEW',
        stats: [ScoutingReportStats.GAMES, ScoutingReportStats.TOTAL_POINTS, ScoutingReportStats.POINTS_PER_GAME],
    },
    {
        categoryName: 'Scoring',
        stats: [
            ScoutingReportStats.GAMES,
            ScoutingReportStats.TOTAL_POINTS,
            ScoutingReportStats.POINTS_PER_GAME,
            ScoutingReportStats.SCORING_MARGIN,
        ],
    },
    {
        categoryName: 'Shooting',
        stats: [
            ScoutingReportStats.FG_MADE_ATTEMPTED,
            ScoutingReportStats.FG_PCT,
            ScoutingReportStats.FG_PER_GAME,
            ScoutingReportStats._3PT_MADE_ATTEMPTED,
            ScoutingReportStats._3PT_PCT,
            ScoutingReportStats._3PT_PER_GAME,
            ScoutingReportStats.FT_MADE_ATTEMPTED,
            ScoutingReportStats.FT_PCT,
            ScoutingReportStats.FT_PER_GAME,
        ],
    },
    {
        categoryName: 'Rebounding',
        stats: [
            ScoutingReportStats.REBOUND_TOTAL,
            ScoutingReportStats.REBOUND_PER_GAME,
            ScoutingReportStats.REBOUND_MARGIN,
        ],
    },
    {
        categoryName: 'Assists',
        stats: [ScoutingReportStats.ASSISTS_TOTAL, ScoutingReportStats.ASSISTS_PER_GAME],
    },
    {
        categoryName: 'Turnovers',
        stats: [
            ScoutingReportStats.TURNOVERS_TOTAL,
            ScoutingReportStats.TURNOVERS_PER_GAME,
            ScoutingReportStats.TURNOVERS_MARGIN,
            ScoutingReportStats.ASSIST_TURNOVER_RATIO,
            ScoutingReportStats.POINTS_OFF_TURNOVERS,
        ],
    },
];

export const ScoutingReportStatDisplay = {
    [Sports.HighSchoolBBall]: bballScoutingReportStats,
    [Sports.MensCollegeBBall]: bballScoutingReportStats,
    [Sports.WomensCollegeBBall]: bballScoutingReportStats,
};

export const PersonnelQuickStats = [
    ScoutingReportStats.GAMES,
    ScoutingReportStats.TOTAL_POINTS,
    ScoutingReportStats.ASSISTS_TOTAL,
    ScoutingReportStats.REBOUND_TOTAL,
    ScoutingReportStats.TURNOVERS_TOTAL,
];

export const Features = hoopslab.Features;

const Config: ConfigSpec = {
    SiteConfig,
    GoogleAnalyticsID,
    Sports,
    PlayTags,
    DrillSkillTags,
    SportsNames,
    PlayerPositions,
    PlayerRatingWeights,
    DepthSections,
    DepthConfig,
    DepthChartLayout,
    PlayerPositionNames,
    PlayerRatingCategories,
    PlayerRatingSections,
    SportPositions,
    PlayerRoleNames,
    TeamSizes,
    AddonConfig,
    ScoutingReportStats,
    ScoutingReportStatDetails,
    ScoutingReportStatDisplay,
    PersonnelQuickStats,
    Features,
};

export default Config;
