import { Component, ComponentType, forwardRef, ReactElement, ReactNode, Ref } from 'react';
import { Dialog, dialogClasses } from '@mui/material';
import { CSSTransition } from 'react-transition-group';

interface TransitionProps {
    in: boolean;
    children: ReactNode;
}

const SlideIn = forwardRef(function SlideIn(
    {
        in: inProp,
        children,
    }: TransitionProps & {
        children: ReactElement<any, any>;
    },
    ref: Ref<HTMLDivElement>
) {
    return (
        <CSSTransition in={inProp} classNames="animated-modal" timeout={200} appear mountOnEnter unmountOnExit>
            <span ref={ref} className="animated-modal-content" tabIndex={-1}>
                {children}
            </span>
        </CSSTransition>
    );
});

export interface AnimatedModalProps {
    open: boolean;
    onClose: () => any;
    narrow?: boolean;
}

export const AnimationDuration = 200;

export default function animatedModal<P>(
    WrappedComponent: ComponentType<P>,
    { className = '', narrow = false } = {}
): ComponentType<P & AnimatedModalProps> {
    return class WrappedModal extends Component<P & AnimatedModalProps, { props: P; open: boolean }> {
        update: any;

        constructor(props: P & AnimatedModalProps) {
            super(props);

            this.state = { props, open: props.open };
        }

        static getDerivedStateFromProps(props: P & AnimatedModalProps, prevState: any) {
            // If the modal is closing, state update will be handled by componentDidUpdate on a delay
            if (prevState.open && !props.open) {
                return { open: props.open };
            }

            return {
                open: props.open,
                props,
            };
        }

        override componentDidUpdate(prevProps: P & AnimatedModalProps) {
            const { open } = this.props;
            if (prevProps.open && !open) {
                if (this.update) {
                    clearTimeout(this.update);
                }

                this.update = setTimeout(() => {
                    this.setState({ props: this.props });
                }, AnimationDuration);
            }
        }

        override componentWillUnmount() {
            clearTimeout(this.update);
        }

        override render() {
            const { open = false, onClose, narrow: narrowProp } = this.props;
            const { props } = this.state;

            return (
                <Dialog
                    open={open}
                    maxWidth={narrow || narrowProp ? 'xs' : 'md'}
                    onClose={onClose}
                    scroll="body"
                    TransitionComponent={SlideIn}
                    style={{
                        zIndex: 900,
                        marginTop: APP ? '70px' : '',
                        marginBottom: APP ? '70px' : '',
                    }}
                    PaperProps={{
                        sx: (theme) => ({
                            [`&.${dialogClasses.paperScrollBody}`]: {
                                [theme.breakpoints.down('sm')]: {
                                    maxWidth: '100%',
                                    margin: '32px 0',
                                },
                                width: '100%',
                            },
                        }),
                    }}
                    className={`animated-modal ${APP ? 'app-container' : ''} ${className}`}
                    closeAfterTransition
                >
                    <WrappedComponent {...props} />
                </Dialog>
            );
        }
    };
}
