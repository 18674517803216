import { createAssetGetter } from '@labradorsports/assets';
import { getAssetUrl } from './shared/utils.js';

const fontLight = createAssetGetter('fonts/Roboto-Light.woff');
const fontRegular = createAssetGetter('fonts/Roboto-Regular.woff');
const fontMedium = createAssetGetter('fonts/Roboto-Medium.woff');
const fontBlack = createAssetGetter('fonts/Roboto-Black.woff');

export default `
/* latin */
@font-face {
    font-family: 'Roboto';
    font-display: swap;
    font-style: normal;
    font-weight: 300;
    src: local('Roboto Light'), local('Roboto-Light'), url(${getAssetUrl(fontLight)}) format('woff');
}

/* latin */
@font-face {
    font-family: 'Roboto';
    font-display: swap;
    font-style: normal;
    font-weight: 400;
    src: local('Roboto'), local('Roboto-Regular'), url(${getAssetUrl(fontRegular)}) format('woff');
}

/* latin */
@font-face {
    font-family: 'Roboto';
    font-display: swap;
    font-style: normal;
    font-weight: 500;
    src: local('Roboto Medium'), local('Roboto-Medium'), url(${getAssetUrl(fontMedium)}) format('woff');
}

/* latin */
@font-face {
    font-family: 'Roboto';
    font-display: swap;
    font-style: normal;
    font-weight: 900;
    src: local('Roboto Black'), local('Roboto-Black'), url(${getAssetUrl(fontBlack)}) format('woff');
}
`;
