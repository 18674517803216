import { createAssetGetter } from '@labradorsports/assets';

export const LogoFullImg = createAssetGetter('img/lacrosse/LL_Long.png');
export const LogoImg = createAssetGetter('img/lacrosse/logo.png');
export const Watermark = createAssetGetter('img/lacrosse/LL_Watermark.svg');
export const FullFieldIcon = createAssetGetter('img/lacrosse/FullField.svg');
export const HalfFieldIcon = createAssetGetter('img/lacrosse/HalfField.svg');
export const DepthChartImg = createAssetGetter('img/lacrosse/DepthChart.svg');
export const _231Icon = createAssetGetter('img/lacrosse/231.svg');
export const _33Icon = createAssetGetter('img/lacrosse/33.svg');
export const _222Icon = createAssetGetter('img/lacrosse/222.svg');
export const _141Icon = createAssetGetter('img/lacrosse/141.svg');
export const CircleIcon = createAssetGetter('img/lacrosse/Circle.svg');
export const WomensCircleIcon = createAssetGetter('img/lacrosse/WomensCircle.svg');
export const _232Icon = createAssetGetter('img/lacrosse/232.svg');
export const _3out2inIcon = createAssetGetter('img/basketball/3out2in.svg');
export const _4out1inIcon = createAssetGetter('img/basketball/4out1in.svg');
export const _5outIcon = createAssetGetter('img/basketball/5out.svg');
