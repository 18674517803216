import { useEffect, useState } from 'react';
import { useSelector, useStore } from 'react-redux';
import { useLocation, useNavigationType, useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import ReactGA from 'react-ga4';
import { LicenseInfo } from '@mui/x-license';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns/index.js';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider/index.js';
import { fetchAndActivate, getValue } from 'firebase/remote-config';
import { getRemoteConfig } from 'firebase/remote-config';
import { getAuth } from 'firebase/auth';

import { JoinTypes, ProfileFlags, Sites } from '@labradorsports/constants';
import { StyledTextField, LoadingSpinner, SimpleModal, StyledSnackbar } from '@labradorsports/components';
import { useSite } from '@labradorsports/utils';
import {
    RootState,
    mainActions,
    authActions,
    scheduleActions,
    playbookActions,
    scoutingReportActions,
    communityActions,
    Selectors,
    useUpdateRSVPMutation,
    authApi,
    useUpdateProfileFlagMutation,
} from './store/index.js';
import { loadPendingRoster, loadRoster } from './store/async-actions.js';
import ContentEditorProvider from './shared/form/content-editor/content-editor-context.js';
import { useStateLoader, useDispatcher, useDispatch } from './shared/hooks/index.js';
import { useLogger, ThemeProvider } from './shared/providers/index.js';
import { RouterStateSync } from './shared/structural/index.js';
import { ReauthenticateModal, ErrorBoundary, AboutModal } from './main/index.js';
import Logger from './shared/logger.js';

const logPage = (logger: Logger, location: any) => {
    logger.log('pageNavigation', { pathname: location.pathname });

    if (PROD && DEPLOY) {
        const fullUrl = `${location.pathname}${location.search}`;
        ReactGA.send({ hitType: 'pageview', page: fullUrl });

        window._hsq.push(['setPath', fullUrl]);
        window._hsq.push(['trackPageView']);
    }
};

LicenseInfo.setLicenseKey(
    'fede7fa35a6b8e74b800a549269b7444Tz05MTcxNyxFPTE3NDg4MTA0MjgwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='
);

const AppWrapper: FC = ({ children }) => {
    const dispatch = useDispatch();
    const loginChange = useDispatcher(authActions.LoginChange);
    const setGenericError = useDispatcher(mainActions.GenericError);
    const setGenericAlert = useDispatcher(mainActions.GenericAlert);
    const setReauthenticating = useDispatcher(authActions.Reauthenticating);
    const setPromptRSVPReason = useDispatcher(scheduleActions.SetPromptRSVPReason);
    const [updateRSVP] = useUpdateRSVPMutation();
    const updateFeatureFlags = useDispatcher(mainActions.UpdateFeatureFlags);
    const setNCAAYear = useDispatcher(scoutingReportActions.SetNCAAYear);
    const doShowSignupPrompt = useDispatcher(communityActions.ShowSignupPrompt);
    const [setProfileFlag] = useUpdateProfileFlagMutation();

    const store = useStore();
    const loading = useSelector((state: RootState) => state.main.loadingCount > 0);
    const genericError = useSelector((state: RootState) => state.main.genericError);
    const genericAlert = useSelector((state: RootState) => state.main.genericAlert);
    const reauthenticating = useSelector((state: RootState) => state.auth.reauthenticating);
    const promptRSVPAddReason = useSelector((state: RootState) => state.schedule.promptRSVPAddReason);
    const showSignupPrompt = useSelector((state: RootState) => state.community.showSignupPrompt);
    const user = useSelector(Selectors.user);
    const currentUserTeamRole = useSelector(Selectors.currentUserTeamRole);
    const roster = useSelector(Selectors.roster);
    const { Config, env } = useSite();
    const logger = useLogger();

    useStateLoader([
        {
            pathPattern: '(.*)',
            active: async (loadProp, state, store) => {
                if (Selectors.profileLoaded(state)) {
                    await loadProp('teams.activeTeam', loadRoster, { dedupeKey: 'roster' });
                    await loadProp('teams.activeTeam', loadPendingRoster, { dedupeKey: 'pendingRoster' });
                }

                store.dispatch(playbookActions.OverrideMenuWithBack());
            },
        },
    ]);

    const location = useLocation();
    const navigationType = useNavigationType();
    const navigate = useNavigate();
    const [settingRSVPReason, setSettingRSVPReason] = useState(null);
    const [rsvpReason, setRSVPReason] = useState('');

    useEffect(() => {
        if (PROD && DEPLOY) {
            window._hsq = window._hsq ?? [];
            ReactGA.initialize(Config.GoogleAnalyticsID);
        }

        window.StonlyWidget('sendData', {
            guideData: {
                production: PROD,
                site: Config.SiteConfig.Site,
            },
        });

        getAuth().onAuthStateChanged((user) => {
            const state = store.getState();

            loginChange(user);

            if (user) {
                const loggedIn = state.auth.user;
                if (user.uid === loggedIn?.uid) {
                    return;
                }

                logger.log('logged in', { uid: user.uid });

                if (PROD && DEPLOY) {
                    ReactGA.set({ userId: user.uid });

                    window._hsq.push([
                        'identify',
                        {
                            email: user.email,
                        },
                    ]);
                }

                dispatch(authApi.endpoints.loadProfile.initiate());
            }
        });

        const remoteConfig = getRemoteConfig();

        // 12 hours
        remoteConfig.settings.minimumFetchIntervalMillis = 1000 * 60 * 60 * 12;
        remoteConfig.defaultConfig = {
            featureFlags: JSON.stringify({
                christmasEasterEggEnabled: false,
            }),
            ncaaConfig: JSON.stringify({
                [Sites.lacrosselab]: '2021',
                [Sites.hoopslab]: '2022',
            }),
        };

        fetchAndActivate(remoteConfig).then(() => {
            const featureFlags = getValue(remoteConfig, 'featureFlags').asString();
            updateFeatureFlags(JSON.parse(featureFlags));

            const ncaaConfig = JSON.parse(getValue(remoteConfig, 'ncaaConfig').asString());
            setNCAAYear(ncaaConfig[Config.SiteConfig.Site]);
        });

        const flushLogger = () => {
            logger.log('pageUnload');
            logger.flush();
        };

        window.addEventListener('pagehide', flushLogger);

        return () => window.removeEventListener('pagehide', flushLogger);
    }, []);

    useEffect(() => {
        if (roster.length > 0 && currentUserTeamRole !== JoinTypes.PLAYER) {
            setProfileFlag({ flag: ProfileFlags.INVITED_TEAM, value: true });
        }
    }, [roster]);

    useEffect(() => {
        if (navigationType === 'PUSH') {
            logPage(logger, location);
        }
    }, [location]);

    useEffect(() => {
        if (genericAlert) {
            // Automatically dismiss alerts after 5 seconds
            setTimeout(() => {
                setGenericAlert();
            }, 5000);
        }
    }, [genericAlert]);

    const dismissError = () => {
        setGenericError();
        setGenericAlert();
    };

    const cancelReauth = () => {
        setReauthenticating(false);
    };

    const dismissSetRSVPReason = () => setPromptRSVPReason(null);

    const showSetRSVPReason = () => {
        setPromptRSVPReason(null);

        setSettingRSVPReason(promptRSVPAddReason);
    };

    const cancelSetRSVPReason = () => {
        setSettingRSVPReason(null);
        setRSVPReason('');
    };

    const rsvpReasonChange = (evt: any) => {
        setRSVPReason(evt.target.value);
    };

    const saveRSVPReason = async () => {
        await updateRSVP({ eventIds: settingRSVPReason, reason: rsvpReason });

        setSettingRSVPReason(null);
    };

    const declineSignup = () => {
        doShowSignupPrompt(false);
    };

    const goToSignup = () => {
        doShowSignupPrompt(false);

        if (user) {
            navigate('/community/profile');
        } else {
            navigate('/signup');
        }
    };

    const rsvpReasonButtons = [
        {
            text: 'Cancel',
            action: cancelSetRSVPReason,
        },
        {
            text: 'Save',
            action: saveRSVPReason,
            color: 'primary',
        },
    ];

    const signupPromptButtons = [
        {
            text: 'Cancel',
            action: declineSignup,
        },
        {
            text: user ? 'Join Community' : 'Sign Up',
            action: goToSignup,
            color: 'primary',
        },
    ];

    return (
        <ThemeProvider>
            <ContentEditorProvider>
                <ErrorBoundary logger={logger} env={env}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <AboutModal />
                        <RouterStateSync />
                        <SimpleModal onClose={declineSignup} open={showSignupPrompt} buttons={signupPromptButtons}>
                            {user ? (
                                <>
                                    <p>You need to setup your profile to contribute.</p>
                                    <p>Click below to begin!</p>
                                </>
                            ) : (
                                <>
                                    <p>You need to sign up to contribute.</p>
                                    <p>Click below to sign up!</p>
                                </>
                            )}
                        </SimpleModal>
                        {loading ? <LoadingSpinner /> : undefined}
                        <SimpleModal onClose={dismissError} open={genericError !== undefined}>
                            <p>
                                Something went wrong. If this keeps happening, please contact us at{' '}
                                <a href="mailto:support@labradorsports.com">support@labradorsports.com</a>
                            </p>
                            {genericError?.code ? (
                                <p className="small text-muted">Error Code: {genericError.code}</p>
                            ) : undefined}
                        </SimpleModal>
                        <StyledSnackbar
                            onClose={dismissError}
                            open={genericAlert !== undefined}
                            message={genericAlert}
                        />
                        <StyledSnackbar
                            onClose={dismissSetRSVPReason}
                            open={Boolean(promptRSVPAddReason)}
                            message="Do you want to add a reason for your response?"
                            action={[
                                <Button key="0" color="secondary" onClick={showSetRSVPReason}>
                                    Add Reason
                                </Button>,
                            ]}
                        />
                        <SimpleModal
                            onClose={cancelSetRSVPReason}
                            open={Boolean(settingRSVPReason)}
                            buttons={rsvpReasonButtons}
                        >
                            <StyledTextField
                                label="RSVP Reason"
                                value={rsvpReason}
                                onChange={rsvpReasonChange}
                                fullWidth
                            />
                        </SimpleModal>
                        <ReauthenticateModal open={reauthenticating} onClose={cancelReauth} />
                        {children}
                    </LocalizationProvider>
                </ErrorBoundary>
            </ContentEditorProvider>
        </ThemeProvider>
    );
};

export default AppWrapper;
