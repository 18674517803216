export function getSVGPoints(pts: any[]): string {
    return pts
        .map((pt) => {
            return `${pt.x},${pt.y}`;
        })
        .join(' ');
}

export function movePath(x: number, y: number): string {
    return `M${x} ${y} `;
}

export function arcPath(r: number, down: boolean, x: number, y: number, large = false): string {
    return `A ${r} ${r} 0 ${large ? '1' : '0'} ${down ? '0' : '1'} ${x} ${y} `;
}

export function halfCirclePath(r: number, x: number, y: number, down: boolean): string {
    return movePath(x, y) + arcPath(r, down, x + r * 2, y);
}

export function svgTransform(coords: Point): string {
    return `translate(${coords.x}px, ${coords.y}px)`;
}

export function bezierCurvePath(start: Point, control: Point, end: Point): string {
    return `M ${start.x} ${start.y} Q ${control.x} ${control.y}, ${end.x} ${end.y}`;
}

export function linePath(start: Point, end: Point): string {
    return `M ${start.x} ${start.y} L ${end.x} ${end.y}`;
}
