import { getCDNUrl } from '@labradorsports/utils';

export function initEnv(Config: ConfigSpec, testUrl: string) {
    if (!PROD) {
        const baseUrl = DEPLOY ? testUrl : `http://${APP ? 'localhost:5050' : window.location.host}`;

        // Will be removed in production build
        return {
            baseUrl,
            apiUrl: `${baseUrl}/api`,
        };
    }

    const baseUrl = Config.SiteConfig.Url;

    return {
        baseUrl,
        apiUrl: `${baseUrl}/api`,
    };
}

export function getSettings() {
    const version = `${APP ? 'app' : 'web'}-${VERSION}`;
    const cdnUrl = getCDNUrl(APP, PROD);

    if (!PROD) {
        // Will be removed in production build
        return {
            version,
            cdnUrl,
            stripeKey: 'pk_test_aglBfu0c6w4W1JwTrugyFZEH',
            firebaseConfig: {
                apiKey: 'AIzaSyA26s3p0_SDErBOhHPiwHPA70tnq6YDw2I',
                authDomain: 'lacrosse-lab-test.firebaseapp.com',
                projectId: 'lacrosse-lab-test',
                storageBucket: 'lacrosse-lab-test.appspot.com',
                messagingSenderId: '518137969550',
                appId: '1:518137969550:web:069140ca3ed5ae6a5e82df',
            },
        };
    }

    return {
        version,
        cdnUrl,
        stripeKey: 'pk_live_3lzMkZKOxFuKl4UgmwJxePT9',
        firebaseConfig: {
            apiKey: 'AIzaSyAl0aiFXynFT8E_r43xWqAoSyH5xJQQ8h4',
            authDomain: 'lacrosse-lab.firebaseapp.com',
            projectId: 'lacrosse-lab',
            storageBucket: 'lacrosse-lab.appspot.com',
            messagingSenderId: '232689056332',
            appId: '1:232689056332:web:5c433ab5042ba77d6412e9',
        },
    };
}
