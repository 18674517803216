import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { SimpleModal } from '@labradorsports/components';
import { PlaybookTabs } from '@labradorsports/constants';
import { useLogger } from '../../shared/providers/index.js';
import { RootState, Selectors, playbookActions, teamsActions } from '../../store/index.js';
import { joinTeamLink as doJoinTeamLink } from '../../store/async-actions.js';
import { JoinTeamSuccess } from '../index.js';
import { useDispatcher } from '../../shared/hooks/index.js';
import { GeneralErrors, TeamErrors } from '@labradorsports/utils';

const JoinTeamPage: FC = () => {
    const joinTeamLink = useDispatcher(doJoinTeamLink);
    const setActiveTeam = useDispatcher(teamsActions.SetActiveTeam);
    const setShownPlaybook = useDispatcher(playbookActions.SetShownPlaybook);

    const joinTeamCode = useSelector((state: RootState) => state.main.joinTeamCode);
    const joinTeamId = useSelector((state: RootState) => state.main.joinTeamId);
    const teams = useSelector(Selectors.allTeams);
    const logger = useLogger();
    const navigate = useNavigate();
    const [error, setError] = useState('');
    const [joinResult, setJoinResult] = useState(null);

    useEffect(() => {
        // If the user is already joined to the team, just direct them to the team playbook
        if (joinTeamId && teams.find((tm) => tm.id === joinTeamId)) {
            logger.log('joinTeamLink user already member', { joinTeamId });
            setActiveTeam(joinTeamId);
            setShownPlaybook(PlaybookTabs.TEAM);

            // Profile load will cause state-loader to trigger, so we want to wait until that finishes before navigating to playbook
            // to prevent double loading of playbook data. This is ugly, need to find a cleaner way to handle this
            setTimeout(() => {
                navigate('/feed');
            }, 0);
        } else {
            joinTeamLink(joinTeamId, joinTeamCode)
                .then(setJoinResult)
                .catch((err: any) => {
                    const newError = (() => {
                        if (err.code === TeamErrors.TEAM_FULL.code) {
                            return 'This team is already full';
                        }

                        if (err.code === TeamErrors.ALREADY_JOINED.code) {
                            return 'You are already joined to this team';
                        }

                        if (err.code === GeneralErrors.NOT_FOUND.code || err.code === TeamErrors.INVALID_LINK.code) {
                            return 'This link is invalid or expired.';
                        }

                        return 'An error occurred';
                    })();

                    setError(newError);
                });
        }
    }, []);

    const dismissMessage = () => {
        navigate('/feed');
    };

    if (error) {
        return (
            <SimpleModal onClose={dismissMessage} open>
                <p>{error}</p>
            </SimpleModal>
        );
    }

    if (joinResult) {
        if (joinResult.pending) {
            return (
                <SimpleModal onClose={dismissMessage} open>
                    <p>Your coach needs to review your membership. Check back later.</p>
                </SimpleModal>
            );
        }

        return <JoinTeamSuccess onClose={dismissMessage} joinResult={joinResult} />;
    }

    return null;
};

export default JoinTeamPage;
