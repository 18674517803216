import { MouseEvent, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { PanelHeader } from '@labradorsports/components';
import { loadEntityLogo as doLoadEntityLogo } from '../../../store/async-actions.js';
import { useDispatcher } from '../../hooks/index.js';
import { RootState, Selectors } from '../../../store/index.js';

interface Props {
    team?: any;
    subtitle?: string;
    title?: string;
    actionMenu?: any;
    onBack?: (evt: MouseEvent<any, any>) => any;
}

const TeamHeader: FC<Props> = ({ team, subtitle, title, onBack, actionMenu }) => {
    const loadEntityLogo = useDispatcher(doLoadEntityLogo);
    const entityLogoUrls = useSelector((state: RootState) => state.teams.entityLogoUrls);
    const currentTeam = useSelector(Selectors.currentTeam);

    const showTeam = team ?? currentTeam;

    useEffect(() => {
        if (showTeam && !entityLogoUrls[showTeam.id]) {
            loadEntityLogo(showTeam.programId);
        }
    }, [showTeam]);

    const logoUrl = entityLogoUrls[showTeam?.programId];

    const finalTitle = showTeam && !subtitle ? showTeam?.name : title;
    const finalSubheader = showTeam && !subtitle ? title : subtitle;

    return (
        <PanelHeader
            title={finalTitle}
            subtitle={finalSubheader}
            logoUrl={logoUrl}
            onBack={onBack}
            actionMenu={actionMenu}
        />
    );
};

export default TeamHeader;
