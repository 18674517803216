import { ConfirmDeleteModal } from '@labradorsports/components';

interface Props {
    open: boolean;
    selectedItems: any[];
    confirm: () => any;
    cancel: () => any;
}

const ConfirmUnshareItemsModal: FC<Props> = ({ selectedItems, open, confirm, cancel }) => {
    let text;

    if (selectedItems.length === 1) {
        if (selectedItems[0].sport) {
            text = 'This will unshare the play from the Team Playbook. Are you sure you want to continue?';
        } else {
            text =
                'This will delete the folder and unshare all of its contents from the Team Playbook. Are you sure that you want to continue?';
        }
    } else {
        text = 'This will delete folders and unshare plays from the Team Playbook. Are you sure you want to continue?';
    }

    return <ConfirmDeleteModal text={text} open={open} confirm={confirm} cancel={cancel} />;
};

export default ConfirmUnshareItemsModal;
