import { useSelector } from 'react-redux';
import { createApi, EndpointDefinition, skipToken } from '@reduxjs/toolkit/query/react';
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions.js';

import {
    AuthenticationErrors,
    Exception,
    GeneralErrors,
    createException,
    getCommonError,
    maskObjectPaths,
} from '@labradorsports/utils';
import { DataTags } from '../shared/constants.js';
import { mainActions } from './main/index.js';
import { authActions } from './auth/index.js';
import { Selectors } from './state.js';
import {
    BaseQuery,
    BaseQueryArgs,
    CustomEndpointDefinition,
    CustomEndpointDefinitionWithQueryProps,
    EndpointDefinitionsIn,
    EndpointDefinitionsOut,
    QueryApi,
    QueryOptions,
    QueryReturnType,
    ReducerPath,
    RetryArgs,
    TagTypes,
} from './types.js';

const RetryableErrors = [
    'An error occurred',
    'unavailable',
    'Failed to fetch',
    'Load failed',
    'Failed to get document because the client is offline.',
];

function retryCondition(err: Error & Exception, arg: any, extraArgs: RetryArgs<any>) {
    const { attempt, baseQueryApi, extraOptions = {} } = extraArgs;
    const { dontRetry } = extraOptions;
    const { extra, endpoint } = baseQueryApi;
    const { logger } = extra;

    if (!PROD && !(err instanceof Exception)) {
        console.log(err);
    }

    // Only log and check errors if the request is retryable
    // queries are retryable by default
    if (!dontRetry) {
        const retriesLeft = 3 - attempt;

        if (retriesLeft > 0) {
            const checkErrors = [
                err.code,
                err.message,
                (err.nestedError as Exception)?.code,
                err.nestedError?.message,
            ].filter(Boolean);

            logger.log('retryable checkErrors', { checkErrors });

            if (checkErrors.length === 0 || Boolean(checkErrors.find((e) => RetryableErrors.includes(e)))) {
                logger.log(`retrying ${endpoint}`, {
                    retriesLeft,
                });

                return true;
            }
        }

        logger.log('retries', { dontRetry, retriesLeft });
    }

    return false;
}

function noopTransform(response: any, meta: any, arg: any): any {
    return response;
}

const baseQueryWrapper =
    <QueryArg, ResultType>(
        getArgs: CustomEndpointDefinitionWithQueryProps<QueryArg, ResultType>['query'],
        transformResponse = noopTransform
    ) =>
    async (arg, api: QueryApi, extraOptions: QueryOptions<QueryArg>) => {
        const { path, query, body, isJSON = true, meta } = getArgs(arg, api, extraOptions);
        const { extra } = api;
        const { cff } = extra;

        const data = await cff.fetch(path, query, body, isJSON);

        return {
            data: transformResponse(data, meta, arg),
        };
    };

export function customQuery<QueryArg extends Record<string, any>, ResultType>(
    def: CustomEndpointDefinition<QueryArg, ResultType>,
    build: EndpointBuilder<BaseQuery, TagTypes, ReducerPath>
): EndpointDefinition<QueryArg, BaseQuery, TagTypes, ResultType, ReducerPath> {
    const {
        type,
        queryFn,
        query,
        transformResponse,
        transformErrorResponse,
        onQueryStarted,
        onQueryEnded,
        onQueryError,
        isValid,
        invalidatesTags,
        providesTags,
        ...rest
    } = def;

    const queryDef = {
        queryFn: async (
            arg,
            api: QueryApi,
            extraOptions: QueryOptions<QueryArg> = {}
        ): Promise<QueryReturnType<ResultType>> => {
            const { extra, endpoint, dispatch } = api;
            const { logger } = extra;
            const { suppressError, suppressLoading, unloggableArg } = extraOptions;

            if (isValid && !isValid(arg)) {
                // TODO: remove when queries are properly skipped for common cases
                logger.log(endpoint, typeof arg !== 'undefined' ? maskObjectPaths(arg, unloggableArg) : undefined);

                logger.exception(
                    createException(GeneralErrors.INVALID_REQUEST, { details: `${endpoint} query isValid: false` })
                );

                return {
                    data: undefined,
                };
            }

            logger.log(endpoint, typeof arg !== 'undefined' ? maskObjectPaths(arg, unloggableArg) : undefined);
            const showLoading = !suppressLoading && !(arg as any)?.autosave;

            if (showLoading) {
                dispatch(mainActions.Loading(true));
            }

            const tryExecute = async (attempt = 0) => {
                try {
                    const queryFunction = queryFn ?? baseQueryWrapper(query, transformResponse);
                    const result = await queryFunction(arg, api, extraOptions);

                    if (!result) {
                        return {
                            data: undefined,
                        };
                    }

                    return result;
                } catch (error) {
                    const shouldRetry =
                        type === 'query' && retryCondition(error, arg, { attempt, baseQueryApi: api, extraOptions });

                    const exc = getCommonError(error);

                    if (!suppressError || !onQueryError) {
                        logger.exception(exc);
                    }

                    if (shouldRetry) {
                        return tryExecute(attempt + 1);
                    }

                    if (exc.is(AuthenticationErrors.REQUIRES_RECENT_LOGIN)) {
                        dispatch(authActions.Reauthenticating(true));

                        return {
                            data: undefined,
                        };
                    }

                    if (!suppressError) {
                        dispatch(mainActions.GenericError(exc));
                    }

                    return {
                        error: {
                            name: error.name,
                            message: error.message,
                            stack: error.stack,
                            code: error.code,
                        },
                    };
                }
            };

            const response = await tryExecute();

            if (showLoading) {
                dispatch(mainActions.Loading(false));
            }

            return response;
        },
        onQueryStarted: async (arg, api) => {
            if (onQueryStarted) {
                onQueryStarted(arg, api);
            }

            const { queryFulfilled, dispatch, extra } = api;
            const { logger } = extra;

            try {
                const response = await queryFulfilled;

                if (onQueryEnded) {
                    await onQueryEnded(response, api, arg);
                }
            } catch (result) {
                if (onQueryError) {
                    try {
                        await onQueryError(result, api, arg);
                    } catch (error) {
                        const exc = getCommonError(error);

                        logger.exception(exc);

                        dispatch(mainActions.GenericError(exc));
                    }
                }
            }
        },
        ...rest,
    };

    return type === 'query'
        ? build.query({
              ...queryDef,
              providesTags,
          })
        : build.mutation({
              ...queryDef,
              invalidatesTags,
          });
}

function createAuthenticatedQueryHook<HookType extends (arg: any, options?: any) => any>(
    originalHook: HookType
): HookType {
    return function useQueryHook(arg, options = {}) {
        const user = useSelector(Selectors.user);
        const profileLoaded = useSelector(Selectors.profileLoaded);
        const profileNotReady = user === null || (user !== null && !profileLoaded);
        const result = originalHook(profileNotReady ? skipToken : arg, options);

        return result;
    } as HookType;
}

export function createApiSlice<Defs extends EndpointDefinitionsIn>(apiDefinitions: Defs) {
    const apiSlice = emptySplitApi.injectEndpoints({
        endpoints: (build) => {
            const endpoints = Object.fromEntries(
                Object.entries(apiDefinitions).map(([api, def]) => {
                    return [api, customQuery(def, build)];
                })
            );

            return endpoints as EndpointDefinitionsOut<Defs>;
        },
    });

    // NOTE: this ignores the "lazy" hook variants
    const wrappedHooks = Object.fromEntries(
        Object.entries(apiDefinitions)
            .filter(([, endpointDef]) => endpointDef.type === 'query')
            .map(([k, endpointDef]) => {
                const hookName = `use${k[0].toUpperCase()}${k.slice(1)}Query`;
                const hook = apiSlice[hookName];

                const wrappedHook = endpointDef.extraOptions?.anonymousHooks
                    ? hook
                    : createAuthenticatedQueryHook(hook);

                Object.defineProperty(wrappedHook, 'name', {
                    value: hookName,
                    configurable: true,
                });

                return [hookName, wrappedHook];
            })
    );

    return {
        ...apiSlice,
        ...wrappedHooks,
    };
}

export const emptySplitApi = createApi({
    baseQuery: (arg: BaseQueryArgs, api: QueryApi, extraOptions: QueryOptions<any>) => ({ data: undefined }),
    tagTypes: Object.values(DataTags),
    endpoints: () => ({}),
});
