import { PlayPermissions, ShareLevels } from '@labradorsports/constants';
import { countDisplay } from '@labradorsports/utils';
import { Button, Switch } from '@mui/material';

interface Props {
    items: any[];
    shareAudience: string;
    options: {
        text: string;
        onClick?: () => any;
        href?: string;
    }[];
    optionClick: (option: any) => () => void;
    setShareAudience?: (audience: string) => void;
    edit?: () => void;
    deleteClick?: () => void;
    unshareClick?: () => void;
    allowedTo: PlayPermissions;
}

const TeamItemsDropdown: FC<Props> = ({
    items,
    shareAudience,
    setShareAudience,
    edit,
    deleteClick,
    unshareClick,
    options,
    optionClick,
    allowedTo,
}) => {
    const toggleShare = () => {
        setShareAudience(shareAudience === ShareLevels.COACHES ? ShareLevels.TEAM : ShareLevels.COACHES);
    };

    const shareCoaches = () => {
        if (shareAudience !== ShareLevels.COACHES) {
            setShareAudience(ShareLevels.COACHES);
        }
    };

    const shareTeam = () => {
        if (shareAudience !== ShareLevels.TEAM) {
            setShareAudience(ShareLevels.TEAM);
        }
    };

    const title = items.length === 1 ? items[0].name : `${countDisplay('item', items.length)} selected`;
    const containsPlays = items.some((item) => Boolean(item.sport));

    return (
        <div className="team-items-dropdown">
            <div className="d-flex align-items-center">
                <em className="dropdown-play-name">{title}</em>
                {allowedTo.RENAME ? <Button onClick={edit}>Edit</Button> : null}
            </div>
            <hr />
            {allowedTo.SHARE ? (
                <>
                    <div className="small text-muted">Audience:</div>
                    <div className="d-flex share-audience">
                        <button onClick={shareTeam}>Whole Team</button>
                        {shareAudience ? (
                            <Switch
                                checked={shareAudience === ShareLevels.COACHES}
                                onChange={toggleShare}
                                color="secondary"
                            />
                        ) : (
                            <span className="dummy-switch" />
                        )}
                        <button onClick={shareCoaches}>Coaches Only</button>
                    </div>
                    {shareAudience ? null : (
                        <div className="small text-muted">
                            <i>Mixed Audiences</i>
                        </div>
                    )}
                    <hr />
                </>
            ) : null}
            {options.map((opt, idx) => (
                <a key={idx} className="dropdown-menu-item" href={opt.href} onClick={optionClick(opt)}>
                    {opt.text}
                </a>
            ))}
            {allowedTo.DELETE ? (
                <>
                    <hr />
                    {containsPlays ? (
                        <Button color="error" onClick={unshareClick} fullWidth>
                            Unshare
                        </Button>
                    ) : (
                        <Button color="error" onClick={deleteClick} fullWidth>
                            Delete
                        </Button>
                    )}
                </>
            ) : null}
        </div>
    );
};

export default TeamItemsDropdown;
