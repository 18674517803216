import { createPortal } from 'react-dom';
import { Snackbar, SnackbarProps } from '@mui/material';

const StyledSnackbar: FC<SnackbarProps> = ({ ...props }) => {
    const bodyEl = SSR ? null : document.getElementsByClassName('body')[0];

    if (!bodyEl) {
        return null;
    }

    return createPortal(<Snackbar anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }} {...props} />, bodyEl);
};

export default StyledSnackbar;
