/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Exception } from '@labradorsports/utils';

export interface MainState {
    suppressPopup: boolean;
    loadingCount: number;
    genericError: Exception;
    genericAlert: string;
    deeplink: string;
    joinTeamCode: string;
    joinTeamId: string;
    actionLog: string[];
    featureFlags: {
        [k: string]: boolean;
    };
}

const mainDefaultState: MainState = {
    suppressPopup: false,
    loadingCount: 0,
    genericError: undefined,
    genericAlert: undefined,
    deeplink: undefined,
    joinTeamCode: undefined,
    joinTeamId: undefined,
    actionLog: [],
    featureFlags: {},
};

const actionBlacklist = ['main/Loading'];

const { reducer: mainReducer, actions: mainActions } = createSlice({
    name: 'main',
    initialState: mainDefaultState,
    reducers: {
        SuppressPopup: (state, action) => {
            state.suppressPopup = action.payload;
        },

        Loading: (state, action) => {
            state.loadingCount = Math.max(state.loadingCount + (action.payload ? 1 : -1), 0);
        },

        GenericError: (state, action: PayloadAction<Exception | null>) => {
            state.genericError = action.payload;
        },

        GenericAlert: (state, action: PayloadAction<string | null>) => {
            state.genericAlert = action.payload;
        },

        SetDeeplink: (state, action: PayloadAction<string | null>) => {
            state.deeplink = action.payload;
        },

        UpdateFeatureFlags: (state, action) => {
            if (action.payload) {
                Object.assign(state.featureFlags, action.payload);
            }
        },
    },

    extraReducers: (builder) =>
        builder
            .addMatcher(
                (action): action is any => !actionBlacklist.includes(action.type) && !action.type.startsWith('api/'),
                (state, action) => {
                    state.actionLog = state.actionLog.concat([action.type]).slice(-100);
                }
            )
            .addDefaultCase(() => {
                // Do nothing
            }),
});

export { mainReducer, mainActions, mainDefaultState };
