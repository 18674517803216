import { Helmet } from 'react-helmet-async';

import { useSite } from '@labradorsports/utils';

interface Props {
    title?: string;
    description?: string;
    image?: string;
}

const HelmetWrapper: FC<Props> = ({ description, children, title, image }) => {
    const { Config } = useSite();

    const fullTitle = `${title} | ${Config.SiteConfig.SiteName}`;

    return (
        <Helmet>
            <title>{fullTitle}</title>
            {description ? <meta name="description" content={description} /> : undefined}

            <meta property="og:title" content={fullTitle} />
            {description ? <meta property="og:description" content={description} /> : undefined}

            <meta name="twitter:title" content={fullTitle} />
            {description ? <meta name="twitter:description" content={description} /> : undefined}

            {image ? <meta property="og:image" content={image} /> : null}
            {image ? <meta property="twitter:image" content={image} /> : null}

            {children}
        </Helmet>
    );
};

export default HelmetWrapper;
