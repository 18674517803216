import { SpecialAnchorTypes, ToolBaseTypes, ToolTypes } from '@labradorsports/constants';
import { arraySlidingWindow } from '@labradorsports/utils';
import { Move } from '../models/index.js';
import { createLines, snapMoveToPlayerLine, defaultMove } from '../utils/index.js';
import { PluginContext } from './api.js';

export default {
    baseType: ToolBaseTypes.MOVE,
    types: [ToolTypes.DMOVE, ToolTypes.OMOVE, ToolTypes.MOVE],
    specialAnchorTypes: [SpecialAnchorTypes.MOVE_ELBOW],
    generateAnchors: ({ play, frame }) => {
        const anchors = [];

        if (!play.fieldSetup) {
            anchors.unshift(
                ...frame.visibleMoves(play.viewOptions).flatMap((mv) => {
                    return mv.anchors.map((anc: any, idx: number) => {
                        return {
                            type: mv.type,
                            id: idx,
                            target: mv.target,
                            ...anc,
                        };
                    });
                })
            );
        }

        return anchors;
    },

    generateSelectedAnchors: ({ play }, selected) => {
        if (selected instanceof Move && play.config.MoveConfig[selected.type].baseType === ToolBaseTypes.MOVE) {
            return arraySlidingWindow([selected.origin, ...selected.anchors], (anchor, nextAnchor, idx) => {
                return {
                    target: selected.target,
                    type: SpecialAnchorTypes.MOVE_ELBOW,
                    id: idx,
                    x: (anchor.x + nextAnchor.x) / 2,
                    y: (anchor.y + nextAnchor.y) / 2,
                    props: {
                        moveType: selected.type,
                        anchorCount: selected.anchors.length,
                    },
                };
            });
        }

        return [];
    },

    generateLines: ({ play, frame }) => {
        if (play.fieldSetup) return [];

        return frame.visibleMoves(play.viewOptions).flatMap(createLines);
    },

    handleDragEnd: ({ api, frame, fieldViewport, play }, anchor, offset) => {
        if (anchor.type === SpecialAnchorTypes.MOVE_ELBOW) {
            const move = frame.getMove(anchor.target);

            api.updateMove(move);
        } else if (typeof anchor.id === 'number') {
            const move = frame.getMove(anchor.target);
            const newMove = move.copy();

            if (anchor.type === ToolTypes.OMOVE || anchor.type === ToolTypes.DMOVE) {
                newMove.type = ToolTypes.MOVE;
            }

            // For moves, attempt to snap to a line
            if (anchor.type === ToolTypes.MOVE) {
                const snapLine = snapMoveToPlayerLine(frame, fieldViewport, offset, anchor.target);

                // Snap to the line, and mark the move to remove the player
                if (snapLine) {
                    newMove.props.targetLine = snapLine.id;
                    newMove.updateAnchor(anchor.id, snapLine.origin, play.field);
                } else {
                    newMove.props.targetLine = null;
                    newMove.updateAnchor(anchor.id, offset, play.field);
                }
            } else {
                newMove.updateAnchor(anchor.id, offset, play.field);
            }

            api.updateMove(newMove);
        } else {
            const closest = frame.findClosestPlayer(
                offset,
                frame.hiddenGoalies(),
                frame.getTargetForToolType(anchor.type)
            );

            if (closest) {
                const newMove = defaultMove(frame.field, closest.origin, anchor.type, closest.id);

                if (anchor.type === ToolTypes.OMOVE || anchor.type === ToolTypes.DMOVE) {
                    newMove.type = ToolTypes.MOVE;
                }

                api.updateMove(newMove);
            }
        }
    },

    handleDrag({ play, frame, fieldViewport }, anchor, offset, clamped) {
        if (anchor.type === SpecialAnchorTypes.MOVE_ELBOW) {
            // Modify the frame from the play so dragend can pick up the changes
            const thisFrame = play.frameSnapshots[frame.idx];
            const move = thisFrame.getMove(anchor.target);

            if (move.anchors.length === anchor.props.anchorCount) {
                thisFrame.insertAnchor(anchor.target, anchor.id, clamped);
            } else {
                thisFrame.updateAnchor(anchor.target, anchor.id, clamped);
            }
        } else if (anchor.type === ToolTypes.MOVE) {
            const snapLine = snapMoveToPlayerLine(frame, fieldViewport, offset, anchor.target);

            const target = snapLine ? snapLine.origin : clamped;

            frame.updateAnchor(anchor.target, anchor.id, target);
        } else {
            // All other move types
            frame.updateAnchor(anchor.target, anchor.id, clamped);
        }
    },

    getDefaultMove({ frame }, type, offset) {
        const closest = frame.findClosestPlayer(offset, frame.hiddenGoalies(), frame.getTargetForToolType(type));

        if (!closest) return null;

        return defaultMove(frame.field, closest, type);
    },
} as PlayPlugin<PluginContext>;
