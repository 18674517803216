import { createPortal } from 'react-dom';
import { CSSTransition } from 'react-transition-group';

interface Props {
    in: boolean;
    position?: 'left' | 'right' | 'bottom';
}

const Flyover: FC<Props> = ({ in: animationIn, position = 'left', children }) => {
    return SSR
        ? null
        : createPortal(
              <CSSTransition classNames="flyover" in={animationIn} timeout={300} mountOnEnter unmountOnExit>
                  <div className={`flyover ${position}`}>{children}</div>
              </CSSTransition>,
              document.getElementById('react')
          );
};

export default Flyover;
