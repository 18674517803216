const TeamUnpaid: FC = () => (
    <div className="p-3">
        <div className="alert alert-danger">
            The subscription for this team is inactive. Please enable billing for this team or contact your team
            administrator.
        </div>
    </div>
);

export default TeamUnpaid;
