/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ScheduleTabs } from '@labradorsports/constants';
import { authActions } from '../auth/index.js';

export interface ScheduleState {
    favoriteLocations: any[];
    viewEventId: string;
    rsvpUpdateStatus: string;
    viewPracticeTemplateId: string;
    shownTab: string;
    promptRSVPAddReason?: string;
}

const scheduleDefaultState: ScheduleState = {
    viewEventId: undefined,
    favoriteLocations: undefined,
    rsvpUpdateStatus: undefined,
    viewPracticeTemplateId: undefined,
    shownTab: ScheduleTabs.FEED,
};

const { reducer: scheduleReducer, actions: scheduleActions } = createSlice({
    name: 'schedule',
    initialState: scheduleDefaultState,
    reducers: {
        FavoriteLocationsLoaded: (state, action) => {
            state.favoriteLocations = action.payload;
        },

        SetViewEvent: (state, action: PayloadAction<string | null>) => {
            state.viewEventId = action.payload;
        },

        SetViewPracticeTemplate: (state, action: PayloadAction<string | null>) => {
            state.viewPracticeTemplateId = action.payload;
        },

        SetShownTab: (state, action) => {
            state.shownTab = action.payload;
        },

        SetPromptRSVPReason: (state, action) => {
            state.promptRSVPAddReason = action.payload;
        },
    },
    extraReducers: {
        [authActions.PurgeProfile.type]: () => scheduleDefaultState,
    },
});

export { scheduleReducer, scheduleActions, scheduleDefaultState };
