import { FieldViewport } from '../models/index.js';

export interface Props {
    fieldViewport: FieldViewport;
}

const Hoop: FC<Props> = ({ fieldViewport }) => (
    <>
        <circle cx={0} cy={0} r={fieldViewport.getPixelLength(0.75)} fill="transparent" />
        <line
            x1={fieldViewport.getPixelLength(-3)}
            y1={fieldViewport.getPixelLength(0.75)}
            x2={fieldViewport.getPixelLength(3)}
            y2={fieldViewport.getPixelLength(0.75)}
        />
    </>
);

export default Hoop;
