import { useState } from 'react';
import { Button } from '@mui/material';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { Checkbox, StyledTextField } from '@labradorsports/components';
import { isIOS, useSite } from '@labradorsports/utils';
import { HelmetWrapper } from '../../shared/structural/index.js';
import { useDispatcher } from '../../shared/hooks/index.js';
import {
    RootState,
    authActions,
    Selectors,
    useCaptchaCreateAccountMutation,
    LoginPopoverForms,
} from '../../store/index.js';

interface Props {
    changeForm?: (form?: string) => any;
}

const SignupForm: FC<Props> = ({ changeForm }) => {
    const { Config } = useSite();
    const [createAccount] = useCaptchaCreateAccountMutation();
    const setLoginEmail = useDispatcher(authActions.SetLoginEmail);
    const accountFormError = useSelector(Selectors.accountFormError);
    const email = useSelector((state: RootState) => state.auth.loginEmail);
    const location = useLocation();
    const navigate = useNavigate();
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [tosAccepted, setTosAccepted] = useState(false);
    const [error, setError] = useState<any>({});

    const validate = () => {
        const newError: any = {};

        if (firstName === '') {
            newError.firstName = true;
        }

        if (lastName === '') {
            newError.lastName = true;
        }

        if (password !== confirmPassword) {
            newError.password = true;
        }

        if (!tosAccepted) {
            newError.tos = true;
        }

        const valid = !Object.keys(newError)
            .map((k) => newError[k])
            .includes(true);

        setError(newError);

        return valid;
    };

    const signup = async () => {
        if (validate()) {
            const { data: success } = await createAccount({ email, password, firstName, lastName });

            if (success && location.pathname !== '/playbook') {
                navigate('/feed');
            }
        }
    };

    const emailChange = (event: any) => {
        setLoginEmail(event.target.value);
    };

    const passwordChange = (event: any) => {
        setPassword(event.target.value);
    };

    const confirmPasswordChange = (event: any) => {
        setConfirmPassword(event.target.value);
    };

    const firstNameChange = (event: any) => {
        setFirstName(event.target.value);
    };

    const lastNameChange = (event: any) => {
        setLastName(event.target.value);
    };

    const keyPress = (event: any) => {
        if (event.key === 'Enter') {
            signup();
        }
    };

    const toggleTOS = (event: any, checked: boolean) => {
        setTosAccepted(checked);
    };

    const showLogin = () => {
        changeForm(LoginPopoverForms.LOGIN);
    };

    const canonical = `${Config.SiteConfig.Url}/signup`;

    return (
        <div className="signup-form">
            <HelmetWrapper title="Sign Up">
                <link rel="canonical" href={canonical} />
                <meta property="og:url" content={canonical} />
            </HelmetWrapper>
            <h2>Sign Up Free</h2>
            <p>Create a free account to {isIOS() ? '' : 'save your plays or '}join your team&apos;s roster.</p>
            <div>
                <StyledTextField
                    value={firstName}
                    onChange={firstNameChange}
                    label="First Name"
                    className="first-name-field"
                    onKeyPress={keyPress}
                    helperText={error.firstName && 'First name is required'}
                    error={error.firstName}
                />
                <StyledTextField
                    value={lastName}
                    onChange={lastNameChange}
                    label="Last Name"
                    className="last-name-field"
                    onKeyPress={keyPress}
                    helperText={error.lastName && 'Last name is required'}
                    error={error.lastName}
                />
            </div>
            <StyledTextField value={email} onChange={emailChange} label="Email" onKeyPress={keyPress} fullWidth />
            <StyledTextField
                type="password"
                value={password}
                onChange={passwordChange}
                label="Password"
                onKeyPress={keyPress}
                fullWidth
            />
            <StyledTextField
                type="password"
                value={confirmPassword}
                onChange={confirmPasswordChange}
                label="Confirm Password"
                onKeyPress={keyPress}
                helperText={error.password && 'Passwords do not match'}
                error={error.password}
                fullWidth
            />
            <div className="d-table mb-2">
                <Checkbox
                    checked={tosAccepted}
                    onChange={toggleTOS}
                    data-cy="tos-checkbox"
                    labelComponent={
                        <span className="small d-table-cell align-middle">
                            I have read and accept the{' '}
                            <a
                                href="https://app.termly.io/document/terms-of-use-for-saas/3e7f14b7-2cec-45f4-a500-d8c920b9036f"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Terms of Use
                            </a>
                        </span>
                    }
                />
            </div>
            {error.tos ? <span className="error-text">You must accept the Terms of Service</span> : undefined}
            {accountFormError ? <span className="error-text">{accountFormError.message}</span> : undefined}
            <div className="row">
                <div className="col text-start">
                    <div className="small">Already have an account?</div>
                    <Button tabIndex={1} onClick={showLogin}>
                        Log In
                    </Button>
                </div>
                <div className="col-auto text-end">
                    <Button variant="contained" onClick={signup} color="secondary" data-cy="signup-submit-btn">
                        Sign Up Free
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default SignupForm;
