import { Sites, Addons, Plans, GlobalPublicSite, PlanTeamSizes } from './common.js';

export const Sports = {
    MensLax: 'mens_lax',
    WomensLax: 'womens_lax',
    BoxLax: 'box_lax',
};

export const SiteConfig = {
    Site: Sites.lacrosselab,
    SiteName: 'Lacrosse Lab',
    Sport: 'lacrosse',
    FriendlyUrl: 'LacrosseLab.com',
    Url: 'https://lacrosse.labradorsports.com',
    PublicUrl: `${GlobalPublicSite}/lacrosse`,
    InstagramUrl: 'https://www.instagram.com/lacrosselab_sports/',
    FacebookUrl: 'https://www.facebook.com/LacrosseLab/',
    TwitterUrl: 'https://twitter.com/lacrosse_lab',
    YouTubeUrl: 'https://www.youtube.com/channel/UCnDsmzWjusDL_Rsth8Hs5Qw',
    DefaultDescription:
        'The most comprehensive software available to lacrosse coaches. Create and share your lacrosse playbook with your players.',
    GooglePlayUrl: 'https://play.google.com/store/apps/details?id=com.labradorsports.lacrosselab&hl=en_US&gl=US',
    AppStoreUrl: 'https://apps.apple.com/ie/app/lacrosse-lab/id1545423864',
};

export const TestUrl = 'https://lacrosse-lab-test.firebaseapp.com';

export const AddonConfig = {
    [Addons.PLAYERS]: {
        Name: 'Roster Expansion',
        MonthlyPrice: 3,
        AnnualPrice: 30,
        ExtraSlots: 5,
    },
    [Addons.COACHES]: {
        Name: 'Additional Coach',
        MonthlyPrice: 3,
        AnnualPrice: 30,
        ExtraSlots: 1,
    },
    [Addons.ADMINS]: {
        Name: 'Additional Admin',
        MonthlyPrice: 4,
        AnnualPrice: 30,
        ExtraSlots: 1,
    },
};

// null limit means unlimited
export const TeamSizes: {
    [plan: string]: PlanTeamSizes;
} = {
    [Plans.BASIC]: {
        Admin: 1,
        Coach: 3,
        Player: 25,
    },
    [Plans.COMPLETE]: {
        Admin: 1,
        Coach: 4,
        Player: 40,
    },
    [Plans.COLLEGIATE]: {
        Admin: 1,
        Coach: 8,
        Player: 80,
    },
    [Plans.CLUB_COMPLETE]: {
        Admin: 3,
        Coach: 3,
        Player: 40,
    },
    [Plans.PERSONAL]: {
        Admin: 1,
        Coach: 6,
        Player: null,
    },
};

export const Features = {
    Roster: true,
    DepthChart: true,
    Evaluation: true,
    Schedule: true,
    ScoutingReport: true,
    Community: true,
    PlayDesigner: true,
    TeamPlaybook: true,
    TeamAdmin: true,
    TeamDocuments: true,
};
