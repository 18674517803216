import { CSSProperties, useState } from 'react';
import { differenceInCalendarDays, addDays, addMinutes } from 'date-fns';

import {
    Addons,
    JoinTypes,
    Plans,
    PlayOperations,
    PlayPermissions,
    PlaybookTabs,
    SiteConstants,
    TeamFlags,
} from '@labradorsports/constants';

export function setCookie(name: string, value: boolean | string = true, expiration?: Date): void {
    if (typeof document !== 'undefined') {
        document.cookie = `${name}=${value}; expires=${(expiration || new Date(9999, 11, 31)).toUTCString()}`;
    }
}

export function getCookie(name: string): string {
    if (typeof document !== 'undefined') {
        const cookies = document.cookie.split(';').map((ck) => ck.trim());
        const found = cookies.find((ck) => ck.indexOf(`${name}=`) === 0);

        if (found) {
            return found.replace(`${name}=`, '');
        }
    }

    return null;
}

export function isMobileBrowser(): boolean {
    if (typeof document !== 'undefined') {
        const userAgent = navigator.userAgent ?? navigator.vendor ?? window.opera;

        if (
            /windows phone/i.test(userAgent) ||
            /android/i.test(userAgent) ||
            (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) ||
            // iPad shares the same user agent as Safari on Mac
            (/macintosh/i.test(userAgent) && navigator.maxTouchPoints > 0)
        ) {
            return true;
        }
    }

    return false;
}

export function isIOS(): boolean {
    if (typeof document !== 'undefined') {
        const userAgent = navigator.userAgent ?? navigator.vendor ?? window.opera;

        if (
            (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) ||
            // iPad shares the same user agent as Safari on Mac
            (/macintosh/i.test(userAgent) && navigator.maxTouchPoints > 0)
        ) {
            return true;
        }
    }

    return false;
}

export function isSafari(): boolean {
    if (typeof document !== 'undefined') {
        return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    }

    return false;
}

const localStorageTest = 'LAB_SPORTS_TEST_ITEM';
export function isLocalStorageEnabled(): boolean {
    try {
        localStorage.setItem(localStorageTest, localStorageTest);
        localStorage.removeItem(localStorageTest);
        return true;
    } catch (e) {
        return false;
    }
}

type GlobalCreation<T> = [() => T, (newGlobal: Partial<T>) => void];

export function createGlobal<T>(initialGlobal: T): GlobalCreation<T> {
    const ref: { value: T } = {
        value: initialGlobal,
    };

    const getGlobal = () => ref.value;
    const setGlobal = (newGlobal: T) => {
        ref.value = newGlobal;
    };

    return [getGlobal, setGlobal];
}

type StateCreation<T> = [() => T, (newState: Partial<T>) => void];

export function useMutableState<T>(initialState: T): StateCreation<T> {
    const [state, setState]: [T, (updates: T) => void] = useState(initialState);

    const getState = () => state;
    const _setState = (newState: Partial<T>) => {
        setState(Object.assign(state, newState));
    };

    return [getState, _setState];
}

export function arrayReplace<T>(array: T[], idx: number, newItem: T): T[] {
    if (idx === -1) {
        return array;
    }

    return [...array.slice(0, idx), newItem, ...array.slice(idx + 1)];
}

export function arrayModify<T>(array: T[], existingItem: T, changes: Partial<T>): T[] {
    const idx = array.indexOf(existingItem);
    return arrayReplace(array, idx, { ...existingItem, ...changes });
}

export function loadExternalScript(src: string): Promise<void> {
    return new Promise((resolve) => {
        if (typeof document !== 'undefined') {
            const script = document.createElement('script');
            script.onload = () => resolve();
            script.src = src;
            const firstScript = document.getElementsByTagName('script')[0];
            firstScript.parentElement.insertBefore(script, firstScript);
        } else {
            resolve();
        }
    });
}

export function inIframe(): boolean {
    try {
        return !SSR && window.self !== window.top;
    } catch (e) {
        return true;
    }
}

export function getCSSVariables(vars: any): CSSProperties {
    return Object.fromEntries(Object.entries(vars).filter(([, val]) => val !== null && val !== undefined));
}

export function getFilteredFolderPlayCount(
    Config: ConfigSpec,
    allFolders: Folder[] = [],
    folderId: string,
    playlist: any[] = [],
    activeTags: string[] = []
): number {
    const filteredPlaylist = playlist.filter((play) => getSiteForSport(play.sport) === Config.SiteConfig.Site);
    return getFolderPlayCount(allFolders, folderId, filteredPlaylist, activeTags);
}

export function getSelectedCount(
    Config: ConfigSpec,
    selected: string[],
    allFolders: Folder[] = [],
    playlist: any[] = []
) {
    return selected.reduce((accumulator, selectedId) => {
        if (playlist.some((play) => play.id === selectedId)) return accumulator + 1;

        return (
            accumulator +
            getFilteredFolderPlayCount(
                Config,
                allFolders,
                selectedId,
                playlist.filter((play) => !selected.includes(play.id))
            )
        );
    }, 0);
}

export function throttle<T extends AnyFunction>(func: T, wait: number): T {
    let allow = true;
    let throttleTimeout: number;

    const setThrottleTimeout = () => {
        if (throttleTimeout) {
            clearTimeout(throttleTimeout);
        }

        throttleTimeout = window.setTimeout(() => {
            allow = true;
            throttleTimeout = null;
        }, wait);
    };

    return ((...args) => {
        if (allow) {
            func(...args);
        }

        allow = false;
        setThrottleTimeout();
    }) as T;
}

export function readFile(file: File): Promise<string> {
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
        reader.addEventListener('load', () => {
            resolve(reader.result.toString());
        });

        reader.addEventListener('error', reject);
        reader.readAsText(file);
    });
}

export function applyFieldCorrections(
    records: any[],
    expectedFields: string[],
    expectedFieldNames: string[],
    correction: any
): any[] {
    return records.map((record) =>
        Object.fromEntries(
            expectedFieldNames.map((expected, idx) => {
                return [expectedFields[idx], record[correction[expected]]];
            })
        )
    );
}

export function getMailToLink(playerEmail: string, contacts: any[] = []) {
    const contactEmails = contacts.map((contact) => contact.email).filter(Boolean);
    const link = (to: string, cc: string[]) => `mailto:${to}${cc.length > 0 ? `?cc=${cc.join(',')}` : ''}`;

    if (playerEmail) {
        return link(playerEmail, contactEmails);
    }

    if (contactEmails.length > 0) {
        const firstContact = contactEmails.shift();
        return link(firstContact, contactEmails);
    }

    return '';
}

export function processTeam(team: any): Team {
    return {
        ...team,
        members: Object.fromEntries(
            Object.entries(team.members ?? {}).map(([k, v]: [string, any]) => [
                k,
                v !== undefined && typeof v !== 'boolean' ? new Date(v) : v,
            ])
        ),
        players: Object.fromEntries(
            Object.entries(team.players ?? {}).map(([k, v]: [string, any]) => [
                k,
                v !== undefined && typeof v !== 'boolean' ? new Date(v) : v,
            ])
        ),
    };
}

export function processProgram(team: any): Program {
    return {
        ...team,
        admins: Object.fromEntries(
            Object.entries(team.admins ?? {}).map(([k, v]: [string, any]) => [
                k,
                v !== undefined && typeof v !== 'boolean' ? new Date(v) : v,
            ])
        ),
    };
}

export function objectGet(obj: any, propPath: string): any {
    const pathParts = propPath.split('.');

    return pathParts.reduce((accumulator, key) => accumulator && accumulator[key], obj);
}

export function objectSet(obj: any, propPath: string, value: any): any {
    const pathParts = propPath.split('.');
    const propName = pathParts.pop();

    const targetObj = pathParts.reduce((accumulator, key) => {
        if (!accumulator?.[key]) {
            accumulator[key] = {};
        }

        return accumulator[key];
    }, obj);

    targetObj[propName] = value;
}

export function objectClone(obj: any): any {
    if (obj === undefined || obj === null) return obj;

    if (Array.isArray(obj)) return obj.map(objectClone);

    if (typeof obj === 'object') {
        return Object.fromEntries(
            Object.keys(obj).map((key: string) => {
                const val = obj[key];

                if (Array.isArray(val)) {
                    return [key, val.map(objectClone)];
                }

                if (val instanceof Date) {
                    return [key, new Date(val.getTime())];
                }

                if (typeof val === 'object') {
                    return [key, objectClone(val)];
                }

                return [key, val];
            })
        );
    }

    return obj;
}

export function objectMerge(targetObj: any = {}, sourceObj: any = {}): any {
    return Object.keys(targetObj).reduce((accumulator: any, key: string) => {
        const targetVal = targetObj?.[key];
        const sourceVal = sourceObj?.[key];

        if (sourceVal === undefined) {
            return {
                ...accumulator,
                [key]: targetVal,
            };
        }

        if (Array.isArray(sourceVal)) {
            return {
                ...accumulator,
                [key]: [...sourceVal],
            };
        }

        if (sourceVal !== null && typeof sourceVal === 'object') {
            return {
                ...accumulator,
                [key]: objectMerge(targetVal, sourceVal),
            };
        }

        return {
            ...accumulator,
            [key]: sourceVal,
        };
    }, sourceObj);
}

export function objectDiff(a: any, b: any): any {
    return Object.entries(a).reduce((accumulator, [k, v]) => {
        if (v === b[k]) return accumulator;

        return {
            ...accumulator,
            [k]: true,
        };
    }, {});
}

export function createNestedUpdates(updates: any, key?: string): any {
    const nested: any = {};
    const keys = Object.keys(updates);

    if (keys.length === 0) {
        nested[key] = updates;
    } else {
        Object.keys(updates).forEach((k) => {
            if (updates[k] !== undefined) {
                const nestKey = key ? `${key}.${k}` : k;

                if (updates[k] !== null && typeof updates[k] === 'object') {
                    Object.assign(nested, createNestedUpdates(updates[k], nestKey));
                } else {
                    nested[nestKey] = updates[k];
                }
            }
        });
    }

    return nested;
}

export const getSiteForSport = (sport: string): string => {
    const found = Object.entries(SiteConstants).find(([, constants]) =>
        Object.values(constants.Sports).includes(sport)
    );
    return found?.[0];
};

export const getYouTubeVideoID = (url: string): string => {
    const YouTubeIDRegex = /(?:\/|%3D|v=|vi=)([0-9A-z-_]{11})(?:[%#?&]|$)/;
    const match = url?.match(YouTubeIDRegex);

    return match?.[1];
};

export const getVimeoVideoID = (url: string): string => {
    const VimeoIDRegex =
        /https?:\/\/(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^/]*)\/videos\/|album\/(?:\d+)\/video\/|video\/|)(\d+)(?:$|\/|\?)/;
    const match = url?.match(VimeoIDRegex);

    return match?.[1];
};

export const isHudlUrl = (url: string): boolean => {
    const HudlIDRegex =
        /https?:\/\/(?:www\.)?hudl.com\/(?:v|watch\/video|video)\/([0-9A-Za-z=]+)(?:\/explore)?(?:$|\/|\?)/;
    const match = url?.match(HudlIDRegex);

    return Boolean(match);
};

export const rootFolders = (folders: Folder[]): Folder[] => {
    const subFolders = folders.reduce((acc, folder) => {
        if (folder.folders) {
            return [...acc, ...folder.folders];
        }

        return acc;
    }, []);

    return folders.filter((folder) => !subFolders.includes(folder.id));
};

export const collectFolders = (allFolders: Folder[], folders: Folder[]): string[] => {
    return (
        folders?.filter(Boolean).flatMap((folder) => {
            if (folder.folders) {
                return [
                    ...folder.folders,
                    ...collectFolders(
                        allFolders,
                        folder.folders.map((f) => getFolder(allFolders, f))
                    ),
                ];
            }

            return folder.folders ?? [];
        }) ?? []
    );
};

export const collectPlays = (allFolders: Folder[], folders: Folder[]): string[] => {
    return (
        folders?.filter(Boolean).flatMap((folder) => {
            if (folder.folders) {
                return [
                    ...folder.plays,
                    ...collectPlays(
                        allFolders,
                        folder.folders.map((f) => getFolder(allFolders, f))
                    ),
                ];
            }

            return folder.plays;
        }) ?? []
    );
};

export const rootFolderPlayIds = (allPlays: any[], folders: Folder[]): string[] => {
    const folderPlays = folders.reduce((acc, folder) => {
        if (folder.plays) {
            return [...acc, ...folder.plays];
        }

        return acc;
    }, []);

    return allPlays.filter((pl: any) => !folderPlays.includes(pl.id)).map((pl: any) => pl.id);
};

export const getFolder = (folders: Folder[], folderId: string): Folder => folders?.find((f) => f.id === folderId);

export const getFolderParent = (folders: Folder[], folderId: string): Folder =>
    folders?.find((f) => f.folders?.includes(folderId));

export const getFolderPlayIds = (folders: Folder[], folderId: string): string[] =>
    getFolder(folders, folderId)?.plays ?? [];

export const getFolderPlays = (folderId: string, playbook: any): any[] => {
    const plays = playbook.shared ?? playbook.plays;

    const playIds = getFolderPlayIds(playbook.folders, folderId);

    return plays.filter(({ id }: any) => playIds.includes(id));
};

export const getCurrentFolderItems = (allPlays: any[] = [], allFolders: Folder[] = [], folderId?: string): any[] => {
    const currentFolder = getFolder(allFolders, folderId);

    // Use root plays if we are not in a folder
    const playIds = currentFolder ? currentFolder.plays : rootFolderPlayIds(allPlays, allFolders);

    // Handle either shared (team playbook) or plays (personal playbook)
    const plays = allPlays.filter((pl: any) => playIds.includes(pl.id));

    // Use root folders if we are not in a folder
    const folders = currentFolder
        ? currentFolder.folders?.map((f) => getFolder(allFolders, f))
        : rootFolders(allFolders);

    // Combine folders and plays to a single list
    return plays
        .concat(folders ?? [])
        .sort((a: any, b: any) => {
            // Sort by manual position
            const aPosition = a.position ?? Infinity;
            const bPosition = b.position ?? Infinity;

            if (aPosition === bPosition) {
                if (typeof a.position !== 'undefined') return 0;

                // Without user-defined position, put folders first
                if (b.sport && !a.sport) return -1;

                // Sort plays by sharedOn date, newest first
                const aDate = a.sharedOn ?? a.createdDate ?? new Date(0);
                const aTime = aDate._seconds ?? aDate.getTime?.() ?? 0;

                const bDate = b.sharedOn ?? b.createdDate ?? new Date(0);
                const bTime = bDate._seconds ?? bDate.getTime?.() ?? 0;
                return bTime - aTime;
            }

            return aPosition > bPosition ? 1 : -1;
        })
        .filter(Boolean);
};

export const findCurrentFolders = (folders: Folder[], playId: string): Folder[] => {
    return folders.filter((folder) => folder.plays.includes(playId));
};

export const removePlayFromFolders = (folders: Folder[], playId: string): Folder[] => {
    const containingFolders = findCurrentFolders(folders, playId);

    containingFolders.forEach((folder) => {
        // eslint-disable-next-line no-param-reassign
        folder.plays = folder.plays.filter((pl) => pl.id !== playId);
    });

    return containingFolders;
};

export const getPracticePlanDuration = (sections: any[]): number =>
    sections?.reduce((accumulator: number, section: any) => accumulator + Number(section.duration), 0) ?? 0;

export const getCustomEventType = (team: any, eventType: string): { name: string; color: string } => {
    return team?.customEventTypes?.find((type: any) => type.name === eventType) ?? null;
};

export function getFolderPlayCount(
    allFolders: Folder[],
    folderId: string,
    playlist: any[] = [],
    activeTags: string[] = []
): number {
    const folder = getFolder(allFolders, folderId);

    if (!folder) {
        return 0;
    }

    const tagFilter = (playId: string) => {
        const play = playlist.find((pl) => pl.id === playId);

        // Exclude plays that are from a different sport
        if (!play) return false;

        return activeTags.length === 0 || activeTags.some((tag) => play?.tags?.includes(tag));
    };

    const thisFolderCount = folder.plays?.filter(tagFilter).length ?? 0;

    return folder.folders
        ? folder.folders.reduce(
              (accumulator: number, subFolderId) =>
                  accumulator + getFolderPlayCount(allFolders, subFolderId, playlist, activeTags),
              thisFolderCount
          )
        : thisFolderCount;
}

export function getTeamRosterLimit(team: Team): number {
    if (!team || !team.site || !team.plan) {
        return 0;
    }

    if (typeof team.customContract?.PlayerLimit !== 'undefined') {
        return team.customContract?.PlayerLimit;
    }

    if (SiteConstants[team.site].TeamSizes[team.plan].Player) {
        const addonCapacity =
            (team.addons?.[Addons.PLAYERS] ?? 0) * SiteConstants[team.site].AddonConfig[Addons.PLAYERS].ExtraSlots;

        return SiteConstants[team.site].TeamSizes[team.plan].Player + addonCapacity;
    }

    return null;
}

export function getPlayerCountFromRoster(roster: any[]): number {
    return roster?.filter((person) => person.uid && !person.playerId).length ?? 0;
}

export function getTeamCoachLimit(team: Team): number {
    if (!team || !team.site || !team.plan) {
        return 0;
    }

    if (typeof team.customContract?.CoachLimit !== 'undefined') {
        return team.customContract?.CoachLimit;
    }

    if (SiteConstants[team.site].TeamSizes[team.plan].Coach) {
        const addonCapacity =
            (team.addons?.[Addons.COACHES] ?? 0) * SiteConstants[team.site].AddonConfig[Addons.COACHES].ExtraSlots;

        return SiteConstants[team.site].TeamSizes[team.plan].Coach + addonCapacity;
    }

    return null;
}

export function getProgramAdminLimit(program: Program): number {
    if (!program || !program.site || !program.plan) {
        return 0;
    }

    if (typeof program.customContract?.AdminLimit !== 'undefined') {
        return program.customContract?.AdminLimit;
    }

    const addonCapacity =
        (program.addons?.[Addons.ADMINS] ?? 0) * SiteConstants[program.site].AddonConfig[Addons.ADMINS].ExtraSlots;
    const freeAddonCapacity = program.plan === Plans.CLUB_COMPLETE || Object.keys(program.teams).length < 5 ? 0 : 3;

    return SiteConstants[program.site].TeamSizes[program.plan].Admin + addonCapacity + freeAddonCapacity;
}

export function getAddonCounts(
    teams: Team[],
    extraAddons: {
        [k: string]: number;
    } = {}
): {
    [k: string]: number;
} {
    const counts: any = Object.fromEntries(Object.keys(Addons).map((key) => [key, 0]));

    teams.forEach((team) => {
        Object.entries(team.addons ?? {}).forEach(([key, count]) => {
            counts[key] += count;
        });
    });

    if (extraAddons) {
        Object.keys(extraAddons).forEach((k) => {
            counts[k] += extraAddons[k];
        });
    }

    return counts;
}

function flipXValue(value: number, flipX: number): number {
    return typeof flipX === 'number' && typeof value === 'number'
        ? Math.max(0, flipX - (value - flipX))
        : value ?? null;
}

function flipRotationValue(value: number): number {
    return typeof value === 'number' ? -(value % 360) : value ?? null;
}

export function flipPlay(play: any, flipX: number): any {
    const newPlay = { ...play };

    if (newPlay.initial) {
        newPlay.initial = newPlay.initial.map((plr: any) => ({
            ...plr,
            rotation: flipRotationValue(plr.rotation),
            origin: {
                ...plr.origin,
                x: flipXValue(plr.origin.x, flipX),
            },
        }));
    }

    if (newPlay.frames) {
        newPlay.frames = newPlay.frames.map((frame: any) => {
            const newFrame = {
                ...frame,
            };

            if (newFrame.moves) {
                newFrame.moves = newFrame.moves.map((mv: any) => ({
                    ...mv,
                    anchors: mv.anchors.map((anc: any) => ({
                        ...anc,
                        x: flipXValue(anc.x, flipX),
                    })),
                }));
            }

            return newFrame;
        });
    }

    if (newPlay.fieldPieces) {
        newPlay.fieldPieces = newPlay.fieldPieces.map((piece: any) => ({
            ...piece,
            rotation: flipRotationValue(piece.rotation),
            origin: {
                ...piece.origin,
                x: flipXValue(piece.origin.x, flipX),
            },
        }));
    }

    return newPlay;
}

export function validateEmail(val: string) {
    return /^\S+@\S+\.\S+/.test(val);
}

export function isUserAdmin(program: Program, uid: string) {
    return program.ownerId === uid || program.admins?.[uid];
}

export function getTeamUserRole(team: Team, uid: string) {
    if (team.ownerId === uid) {
        return JoinTypes.ADMIN;
    }

    if (team.members?.[uid]) {
        return JoinTypes.COACH;
    }

    return JoinTypes.PLAYER;
}

export function noop() {
    // intentionally does nothing
}

export function passthrough(val) {
    return val;
}

export function range(num: number) {
    return Array(num)
        .fill(null)
        .map((_, i) => i);
}

export function getCDNUrl(app: boolean, prod: boolean) {
    if (app && typeof window !== 'undefined') {
        return `${window.location.origin}${window.location.pathname.replace('index.html', '')}assets`;
    }

    return prod ? 'https://labrador-sports-cdn.firebaseapp.com' : 'https://labrador-sports-cdn-test.firebaseapp.com';
}

export function getRSVPStatus(event: any, uid: string): string {
    if (!event || !event.rsvps) return null;

    return event.rsvps[uid];
}

// Manipulates the object argument directly!
export function serializeObjectPaths(
    object: any,
    serializers: {
        [k: string]: (value: any) => any;
    }
) {
    const serializeSimpleProp = (obj, path, serialize) => {
        const value = objectGet(obj, path);

        if (value) {
            objectSet(obj, path, serialize(value));
        }
    };

    return Object.entries(serializers).reduce((acc, [path, serialize]) => {
        if (path.includes('[*]')) {
            const [arrayPath, itemPath] = path.split('[*].');

            const array = objectGet(acc, arrayPath);

            if (array) {
                array.forEach((item) => {
                    serializeSimpleProp(item, itemPath, serialize);
                });
            }
        } else {
            serializeSimpleProp(acc, path, serialize);
        }

        return acc;
    }, object);
}

export function isAlphanumeric(str: string) {
    return /[a-zA-Z0-9]*/.test(str);
}

export function isValidScreenName(screenName: string) {
    return /[a-zA-Z0-9_. -]*/.test(screenName) && isAlphanumeric(screenName.slice(0, 1)) && screenName.length < 40;
}

export function getAddressString(addressComponents: any) {
    const { name, streetAddress, city, state, zipCode } = addressComponents;

    return `${name && name !== streetAddress ? `${name}, ` : ''}${streetAddress}, ${city ?? ''}, ${state ?? ''} ${
        zipCode ?? ''
    }`;
}

export function teamHasFlag(team: Team, flag: string): boolean {
    return Boolean(team?.flags?.[flag]);
}

export function zeroPad(value: number, len = 2): string {
    let str = `${value}`;
    while (str.length < len) {
        str = `0${str}`;
    }

    return str;
}

export function getEventDate(date: any, time: string) {
    const eventDate = new Date(date);
    const [hour, minute] = time.split(':');
    eventDate.setHours(Number(hour));
    eventDate.setMinutes(Number(minute));
    eventDate.setSeconds(0);

    return eventDate;
}

export function getUserRole(uid: string, programs: Program[], joinedTeams: Team[]) {
    if (programs.length === 0 && joinedTeams.length === 0) {
        return 'NONE';
    }

    const isAdmin = Boolean(programs.find((program) => isUserAdmin(program, uid)));

    if (isAdmin) {
        return JoinTypes.ADMIN;
    }

    const isCoach = Boolean(joinedTeams.find((team) => getTeamUserRole(team, uid) === JoinTypes.COACH));

    return isCoach ? JoinTypes.COACH : JoinTypes.PLAYER;
}

export function displayNumber(num: number): number {
    return Number.isNaN(num) ? 0 : num;
}

export function cleanStack(stack = '', baseUrl = '') {
    return stack
        ?.replace(/\n/g, '\\n')
        .replace(/file:\/\/.*?www\/js/g, `${baseUrl}/__app__/js`)
        .replace(/\/\/\/private\/.*?www\/js/g, `${baseUrl}/__app__/js`)
        .replace(/https:\/\/localhost\/js/g, `${baseUrl}/__app__/js`);
}

export function getDefaultEndTime(startTime: string) {
    const [hour, minute] = startTime.split(':');
    const endHour = Number(hour) + (2 % 24);

    return `${zeroPad(endHour, 2)}:${minute}`;
}

export function getReminderOptions(date: Date) {
    return ['Send Immediately', 'One Day Before', 'Two Days Before', 'Three Days Before'].filter(
        (opt, idx) => idx < Math.max(0, differenceInCalendarDays(date, Date.now()))
    );
}

export function maskObjectPaths(obj: any, paths: string[] = []) {
    const isMasked = (path: string, keyParts: string[]) => {
        const pathParts = path.split('.');

        return keyParts.length > 0 && pathParts.every((part, idx) => part === '*' || part === keyParts[idx]);
    };

    const maskObj = (objPart: any, keyParts: string[] = []): any => {
        if (typeof objPart === 'undefined') {
            return `[undefined]`;
        }

        if (objPart === null) {
            return null;
        }

        if (typeof objPart === 'undefined' && Number.isNaN(objPart)) {
            return '[NaN]';
        }

        if (paths.some((path) => isMasked(path, keyParts))) {
            return '***';
        }

        if (Array.isArray(objPart)) {
            return objPart.map((entry, idx) => maskObj(entry, [...keyParts, `${idx}`]));
        }

        if (typeof objPart === 'object') {
            return Object.fromEntries(
                Object.entries(objPart).map(([key, entry]) => [key, maskObj(entry, [...keyParts, key])])
            );
        }

        return objPart;
    };

    return maskObj(obj);
}

export function arraySlidingWindow<T, R>(array: T[], fn: (a: T, b: T, idx: number) => R): R[] {
    return array
        .map((el, idx) => {
            const nextEl = array[idx + 1];

            if (typeof nextEl === 'undefined') {
                return null;
            }

            return fn(el, nextEl, idx);
        })
        .slice(0, -1);
}

export function isValidUrl(url: string): boolean {
    try {
        return Boolean(new URL(url));
    } catch (e) {
        return false;
    }
}

export function getDefaultDateTime(): { date: Date; startTime: string; endTime: string } {
    const date = addDays(new Date(), new Date().getHours() >= 15 ? 1 : 0);

    return {
        date,
        startTime: '15:00',
        endTime: '17:00',
    };
}

export function noPropagation(handler: AnyFunction) {
    return (evt: any) => {
        if (typeof evt?.stopPropagation === 'function') {
            evt.stopPropagation();
        }

        return handler(evt);
    };
}

export function stripMarkup(str: string): string {
    return str?.replace(/<\/p>/g, '\n').replace(/<(.*?)>/g, '') ?? '';
}

export function getAllowedPlayOperations(
    team: Team,
    play: any,
    readOnly: boolean,
    playbookType: string,
    teamRole: string
): PlayPermissions {
    const coachCanCopy = teamHasFlag(team, TeamFlags.COACHES_CAN_COPY_TEAM_PLAYS);
    const playerCanShareToOtherTeams = teamHasFlag(team, TeamFlags.PLAYERS_CAN_REFERENCE_TEAM_PLAYS);
    const playerCanShareByLink = teamHasFlag(team, TeamFlags.PLAYERS_CAN_SHARE_PLAY_LINKS);
    const isTeamPlaybook = playbookType === PlaybookTabs.TEAM;
    const isProxy = play && Boolean(play.proxyTeamId);
    const isSaved = play && !play.id.includes('__');

    const canCopy =
        // Proxy shared plays cannot be copied
        !isProxy &&
        // Anyone can copy in personal or public playbook
        (!isTeamPlaybook ||
            // Admin can always copy
            teamRole === JoinTypes.ADMIN ||
            // Coach can copy with permission
            (teamRole === JoinTypes.COACH && coachCanCopy));

    const canShare =
        // Proxy shared plays cannot be shared again
        !isProxy &&
        // Anyone can share their own plays (without teams we will show an upsell)
        (!isTeamPlaybook ||
            // Coach and admin can always share team plays
            [JoinTypes.ADMIN, JoinTypes.COACH].includes(teamRole) ||
            // Players can share team plays with permission
            playerCanShareToOtherTeams ||
            // Players can share plays by public links/embed
            playerCanShareByLink);

    return {
        [PlayOperations.RENAME]: !readOnly && !isTeamPlaybook,
        [PlayOperations.MOVE]: !readOnly && isSaved,
        [PlayOperations.COPY]: canCopy && isSaved,
        [PlayOperations.SHARE]: isSaved && canShare,
        [PlayOperations.DELETE]: !readOnly,
    };
}

export function getAllowedFolderOperations(
    team: Team,
    readOnly: boolean,
    playbookType: string,
    teamRole: string
): PlayPermissions {
    const isTeamPlaybook = playbookType === PlaybookTabs.TEAM;
    const playerCanShareToOtherTeams = teamHasFlag(team, TeamFlags.PLAYERS_CAN_REFERENCE_TEAM_PLAYS);

    const canShare =
        // Anyone can share their own plays (without teams we will show an upsell)
        !isTeamPlaybook ||
        // Coach and admin can always share team plays
        [JoinTypes.ADMIN, JoinTypes.COACH].includes(teamRole) ||
        // Players can share team plays with permission
        playerCanShareToOtherTeams;

    return {
        [PlayOperations.RENAME]: !readOnly,
        [PlayOperations.MOVE]: !readOnly,
        [PlayOperations.COPY]: !readOnly,
        [PlayOperations.SHARE]: canShare,
        [PlayOperations.DELETE]: !readOnly,
    };
}

export function plural(str: string, count: number, suffix = 's'): string {
    return `${str}${count === 1 ? '' : suffix}`;
}

export function countDisplay(str: string, count: number, suffix = 's'): string {
    return `${count} ${plural(str, count, suffix)}`;
}

export function createBreadcrumbMetadata(items: { name: string; url: string }[]) {
    return {
        '@type': 'BreadcrumbList',
        '@id': 'https://labradorsports.com/#breadcrumb',
        itemListElement: [
            {
                '@type': 'ListItem',
                position: 1,
                name: 'Home',
                item: 'https://labradorsports.com/',
            },
            ...items.map(({ name, url }, idx) => {
                return {
                    '@type': 'ListItem',
                    position: idx + 1,
                    name,
                    item: url,
                };
            }),
        ],
    };
}

export function createGraphMetadata(breadcrumbs: { name: string; url: string }[]) {
    return JSON.stringify({
        '@context': 'https://schema.org',
        '@graph': [createBreadcrumbMetadata(breadcrumbs)],
    });
}

// Default of 240 is EST
export const applyTimezoneOffset = (date: Date, timezone = -240): Date => {
    return addMinutes(date, timezone);
};
