/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { processProgram, processTeam } from '@labradorsports/utils';
import { authActions } from '../auth/index.js';

export interface TeamsState {
    userTeams: Team[];
    programs: any[];
    joinedTeams: any[];
    activeTeam: string;
    allTeamsMembers: any;
    roster: any[];
    playerRoster: any[];
    contactMap: any;
    depthChart: any;
    tempTeams: any[];
    rosterPlayerId: string;
    tempRatings: any;
    ratingsList: any[];
    activeRatingId: string;
    entityLogoUrls: {
        [key: string]: string;
    };
    depthChartModified: boolean;
    pendingRoster: any[];
    pendingJoinTeams: any[];
}

const teamsDefaultState: TeamsState = {
    userTeams: undefined,
    programs: undefined,
    joinedTeams: undefined,
    activeTeam: undefined,
    allTeamsMembers: undefined,
    roster: [],
    playerRoster: [],
    contactMap: {},
    depthChart: undefined,
    tempTeams: undefined,
    rosterPlayerId: undefined,
    tempRatings: undefined,
    ratingsList: undefined,
    activeRatingId: undefined,
    entityLogoUrls: {},
    depthChartModified: false,
    pendingRoster: [],
    pendingJoinTeams: undefined,
};

const { reducer: teamsReducer, actions: teamsActions } = createSlice({
    name: 'teams',
    initialState: teamsDefaultState,
    reducers: {
        TeamsLoaded: {
            prepare: (programs: Team[], owned: Team[], joined?: Team[], pendingJoin?: any[]) => ({
                payload: {
                    programs,
                    owned,
                    joined,
                    pendingJoin,
                },
            }),
            reducer: (state, action: PayloadAction<any>) => {
                if (action.payload.owned) {
                    state.userTeams = action.payload.owned.map(processTeam);
                }

                if (action.payload.joined) {
                    state.joinedTeams = action.payload.joined.map(processTeam);
                }

                if (action.payload.programs) {
                    state.programs = action.payload.programs.map(processProgram).map((program: any) => ({
                        ...program,
                        teams: state.userTeams.filter((team: any) => team.programId === program.id),
                    }));
                }

                if (action.payload.pendingJoin) {
                    state.pendingJoinTeams = action.payload.pendingJoin.map((team) => ({
                        ...team,
                        pending: true,
                    }));
                }

                const teamsList = (state.userTeams ?? [])
                    .concat(state.joinedTeams ?? [])
                    .concat(state.pendingJoinTeams ?? []);

                if (teamsList.length > 0) {
                    if (!state.activeTeam || teamsList.findIndex((tm: any) => tm.id === state.activeTeam) === -1) {
                        state.activeTeam = teamsList[0].id;
                    }
                } else {
                    state.activeTeam = undefined;
                }
            },
        },

        TeamUpdated: {
            prepare: (entityId: string, entityUpdates: any) => ({
                payload: {
                    entityUpdates,
                    entityId,
                },
            }),
            reducer: (state, action: PayloadAction<any>) => {
                const { entityUpdates, entityId } = action.payload;

                const existingProgram = state.programs.find((program) => program.id === entityId);

                if (existingProgram) {
                    Object.assign(existingProgram, entityUpdates);
                }

                const existingTeam = state.userTeams.find((team) => team.id === entityId);

                if (existingTeam) {
                    Object.assign(existingTeam, entityUpdates);
                } else if (entityUpdates.id) {
                    state.userTeams = [...state.userTeams, entityUpdates];
                }
            },
        },

        SetActiveTeam: (state, action) => {
            if (action.payload !== state.activeTeam) {
                state.roster = [];
                state.depthChart = undefined;
                state.activeTeam = action.payload;
            }
        },

        MembersLoaded: (state, action) => {
            state.allTeamsMembers = action.payload;
        },

        MemberRemoved: {
            prepare: (entityId: string, memberIds: string[]) => ({
                payload: {
                    entityId,
                    memberIds,
                },
            }),
            reducer: (
                state,
                action: PayloadAction<{
                    entityId: string;
                    memberIds: string[];
                }>
            ) => {
                const teamsList = (state.userTeams ?? []).concat(state.joinedTeams ?? []);
                const { entityId, memberIds } = action.payload;
                const existingTeam = teamsList.find((tm: any) => tm.id === entityId);

                if (existingTeam) {
                    memberIds.forEach((memberId) => {
                        delete existingTeam.members[memberId];
                    });

                    if (existingTeam.programId) {
                        const existingProgram = state.programs.find((clb: any) => clb.id === existingTeam.programId);

                        if (existingProgram) {
                            const programTeam = existingProgram.teams.find((tm: any) => tm.id === entityId);

                            memberIds.forEach((memberId) => {
                                delete programTeam.members[memberId];
                            });
                        }
                    }
                } else {
                    const existingProgram = state.programs.find((clb: any) => clb.id === entityId);

                    memberIds.forEach((memberId) => {
                        delete existingProgram.admins[memberId];
                    });
                }
            },
        },

        EntityLogoUrlLoaded: {
            prepare: (teamId: string, url?: string) => ({
                payload: {
                    teamId,
                    url,
                },
            }),
            reducer: (state, action: PayloadAction<any>) => {
                state.entityLogoUrls[action.payload.teamId] = action.payload.url;
            },
        },

        RosterLoaded: (state, action) => {
            state.roster = action.payload;
            state.playerRoster = action.payload.filter((person: any) => !person.playerId);

            state.contactMap = {};
            state.playerRoster.forEach((player) => {
                state.contactMap[player.id] = state.roster.filter((person) => person.playerId === player.id);
            });
        },

        DepthChartLoaded: (state, action) => {
            state.depthChart = action.payload;
            state.depthChartModified = false;
        },

        SetRosterPlayer: (state, action) => {
            state.rosterPlayerId = action.payload;
        },

        SetTempRatings: {
            prepare: (payload?: any) => ({ payload }),
            reducer: (state, action: PayloadAction<any>) => {
                state.tempRatings = action.payload;
            },
        },

        SetRatingsList: (state, action) => {
            state.activeRatingId = action.payload?.[0] ? action.payload[0].id : undefined;
            state.ratingsList = action.payload;
        },

        SetActiveRatingId: (state, action) => {
            state.activeRatingId = action.payload;
        },

        DepthChartModified: (state, action) => {
            state.depthChartModified = action.payload;
        },

        TeamFlagsSet: (state, action) => {
            const currentTeam = state.userTeams.find((team) => team.id === state.activeTeam);
            currentTeam.flags = currentTeam.flags ?? {};
            Object.assign(currentTeam.flags, action.payload);
        },

        PendingRosterLoaded: (state, action) => {
            state.pendingRoster = action.payload;
        },
    },
    extraReducers: {
        [authActions.PurgeProfile.type]: () => teamsDefaultState,
    },
});

export { teamsReducer, teamsActions, teamsDefaultState };
