import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { addDays } from 'date-fns';
import { ThunkAction } from '@reduxjs/toolkit';

import { setCookie } from '@labradorsports/utils';
import { useDispatch } from '../../shared/hooks/index.js';
import { AffiliateCouponCookieName } from '../../shared/constants.js';
import { billingApi } from '../../store/index.js';

const AffiliateLinkPage: FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();

    useEffect(() => {
        (async () => {
            const { data: result, error } = await dispatch(
                billingApi.endpoints.validateDiscountCodes.initiate({
                    couponCode: params.code,
                })
            );

            if (error) {
                navigate('/feed');
            } else {
                setCookie(AffiliateCouponCookieName, result.coupon.code, addDays(new Date(), 30));
                navigate('/profile/teamadmin/create');
            }
        })();
    }, []);

    return null;
};

export default AffiliateLinkPage;
