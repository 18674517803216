import { useEffect, useRef, Suspense, useState } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import { LoadingSpinner } from '@labradorsports/components';
import { Plans } from '@labradorsports/constants';
import { inIframe, useSite } from '@labradorsports/utils';
import { PrivateRoute, lazyWrapper, SiteChrome } from './shared/structural/index.js';
import {
    JoinTeamPage,
    EmailActionPage,
    AffiliateLinkPage,
    AccountManagementForm,
    LoginForm,
    SignupForm,
    ForgotPasswordForm,
    BetaPendingPage,
} from './main/index.js';
import fonts from './fonts.js';

const PlaybookPage = lazyWrapper(
    () => import(/* webpackChunkName: "playbook" */ './playbook/playbook-page/playbook-page.js')
);
const MyTeam = lazyWrapper(() => import(/* webpackChunkName: "my-team" */ './team-management/my-team/my-team.js'));
const MyTeamUpsell = lazyWrapper(
    () => import(/* webpackChunkName: "my-team-upsell" */ './team-management/my-team-upsell/my-team-upsell.js')
);
const ProfilePage = lazyWrapper(
    () => import(/* webpackChunkName: "profile" */ './profile/profile-page/profile-page.js')
);
const SchedulePage = lazyWrapper(
    () => import(/* webpackChunkName: "schedule" */ './schedule/schedule-page/schedule-page.js')
);
const ScheduleUpsell = lazyWrapper(
    () => import(/* webpackChunkName: "schedule-upsell" */ './schedule/schedule-upsell/schedule-upsell.js')
);
const PublicSharedPlayPage = lazyWrapper(
    () =>
        import(
            /* webpackChunkName: "publicSharedPlay" */ './playbook/public-shared-play-page/public-shared-play-page.js'
        )
);
const DashboardPage = lazyWrapper(
    () => import(/* webpackChunkName: "dashboard" */ './dashboard/dashboard-page/dashboard-page.js')
);
const PaymentPage = lazyWrapper(
    () => import(/* webpackChunkName: "billing" */ './billing/payment-page/payment-page.js')
);
const CommunityPage = lazyWrapper(
    () => import(/* webpackChunkName: "community" */ './community/community-page/community-page.js')
);

const routesWithoutChrome = ['/play', '/playbook/team'];

const SiteContent: FC = () => {
    const location = useLocation();
    const bodyRef = useRef(null);
    const [noChrome, setNoChrome] = useState(false);

    useEffect(() => {
        setNoChrome(routesWithoutChrome.includes(location.pathname) && inIframe());
    }, [location.pathname]);

    const { Config } = useSite();

    useEffect(() => {
        if (bodyRef.current) {
            // cannot use Element.scrollTo() because it is not supported in Edge
            bodyRef.current.scrollTop = 0;
        }
    }, [location]);

    return (
        <SiteChrome hideChrome={noChrome}>
            <Helmet>
                <style>{fonts}</style>
            </Helmet>
            <div className={`body ${noChrome ? 'no-chrome' : ''}`} ref={bodyRef}>
                <Suspense fallback={<LoadingSpinner />}>
                    <Routes>
                        <Route path="/beta-pending" element={<BetaPendingPage />} />
                        <Route path="/payment/*" element={<PaymentPage />} />
                        <Route path="/login" element={<AccountManagementForm FormComponent={LoginForm} />} />
                        <Route path="/signup" element={<AccountManagementForm FormComponent={SignupForm} />} />
                        <Route
                            path="/forgot-password"
                            element={<AccountManagementForm FormComponent={ForgotPasswordForm} />}
                        />
                        <Route path="/email-action" element={<EmailActionPage />} />
                        <Route path="/play" element={<PublicSharedPlayPage />} />
                        <Route
                            path="/feed/*"
                            element={
                                <PrivateRoute>
                                    <DashboardPage />
                                </PrivateRoute>
                            }
                        />
                        {Config.Features.PlayDesigner ? (
                            <Route
                                path="/playbook/*"
                                element={
                                    <PrivateRoute>
                                        <PlaybookPage />
                                    </PrivateRoute>
                                }
                            />
                        ) : null}
                        {Config.Features.Schedule && Config.Features.TeamAdmin ? (
                            <Route
                                path="/schedule/*"
                                element={
                                    <PrivateRoute
                                        fallback={<ScheduleUpsell />}
                                        requireSubscription
                                        excludePlans={[Plans.BASIC]}
                                    >
                                        <SchedulePage />
                                    </PrivateRoute>
                                }
                            />
                        ) : null}
                        <Route
                            path="/profile/*"
                            element={
                                <PrivateRoute>
                                    <ProfilePage />
                                </PrivateRoute>
                            }
                        />
                        {Config.Features.Roster && Config.Features.TeamAdmin ? (
                            <Route
                                path="/team/*"
                                element={
                                    <PrivateRoute fallback={<MyTeamUpsell />} requireSubscription>
                                        <MyTeam />
                                    </PrivateRoute>
                                }
                            />
                        ) : null}
                        {Config.Features.Community ? <Route path="/community/*" element={<CommunityPage />} /> : null}
                        <Route
                            path="/join-team"
                            element={
                                <PrivateRoute>
                                    <JoinTeamPage />
                                </PrivateRoute>
                            }
                        />
                        <Route path="/:code" element={<AffiliateLinkPage />} />
                        <Route path="*" element={<Navigate to="/feed" />} />
                    </Routes>
                </Suspense>
            </div>
        </SiteChrome>
    );
};

export default SiteContent;
