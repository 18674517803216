import { UpdateHandler } from '../../interface/index.js';
import { Header } from '../../../main/index.js';

interface Props {
    hideChrome?: boolean;
    children: any;
}

const SiteChrome: FC<Props> = ({ children, hideChrome }) => {
    if (hideChrome) return children;

    return (
        <>
            <UpdateHandler />
            <Header />
            {children}
        </>
    );
};

export default SiteChrome;
