import { EventTypes } from '@labradorsports/constants';

export const FeedbackURL = 'https://www.surveymonkey.com/r/5B729KN';

export const States = [
    'AK',
    'AL',
    'AR',
    'AZ',
    'CA',
    'CO',
    'CT',
    'DE',
    'FL',
    'GA',
    'HI',
    'IA',
    'ID',
    'IL',
    'IN',
    'KS',
    'KY',
    'LA',
    'MA',
    'MD',
    'ME',
    'MI',
    'MN',
    'MO',
    'MS',
    'MT',
    'NC',
    'ND',
    'NE',
    'NH',
    'NJ',
    'NM',
    'NV',
    'NY',
    'OH',
    'OK',
    'OR',
    'PA',
    'RI',
    'SC',
    'SD',
    'TN',
    'TX',
    'UT',
    'VA',
    'VT',
    'WA',
    'WI',
    'WV',
    'WY',
];

export const Provinces = ['AB', 'BC', 'MB', 'NB', 'NL', 'NT', 'NS', 'NU', 'ON', 'PE', 'QC', 'SK', 'YT'];

export const Countries = ['US', 'CA'];

export const CouponLimit = 1;

export const UnsavedPlayMessage =
    'You have unsaved changes. If you navigate away, these changes will be lost. Are you sure you want to continue?';

export const PlayerType = 'ROSTER_PLAYER';

export const AffiliateCouponCookieName = 'affiliateCode';

export const EventColorClasses = {
    [EventTypes.GAME]: 'event-type-game',
    [EventTypes.PRACTICE]: 'event-type-practice',
    [EventTypes.SCRIMMAGE]: 'event-type-scrimmage',
    [EventTypes.CUSTOM]: 'event-type-custom',
};

export const VideoDomains = ['youtube.com', 'youtu.be', 'vimeo.com', 'hudl.com'];

export const DataTags = {
    POST: 'Post',
    EVENT: 'Event',
    PLAY: 'Play',
    PLAYLIST_ITEM: 'PlaylistItem',
    FOLDER: 'Folder',
    FORMATION: 'Formation',
    NCAA_TEAM: 'NCAATeam',
    SCOUTING_REPORT: 'ScoutingReport',
    BILLING_ENTITY: 'BillingEntity',
    INVOICE: 'Invoice',
    PAYMENT_METHOD: 'PaymentMethod',
    BILLING_DETAILS: 'BillingDetails',
    PROFILE: 'Profile',
    PROFILE_IMAGE: 'ProfileImage',
    TEAM_DOCUMENT: 'TeamDocument',
    PRACTICE_TEMPLATE: 'PracticeTemplate',
} as const;

export const PlayType = 'PLAY';
