import { arrayReplace } from '@labradorsports/utils';
import Move from '../models/move.js';
import FieldViewport from '../models/field-viewport.js';
import Play from '../models/play.js';
import FieldPiece from '../models/field-piece.js';
import FrameSnapshot from '../models/frame-snapshot.js';
import { angleBetweenPoints } from '../utils/index.js';

export interface PlayDebug {
    log: (msg: string, data: any) => void;
    points: (pt: Point[]) => void;
    lines: (lines: Line[]) => void;
}

export interface PluginApi {
    updateFieldPiece: (piece: any) => void;
    updatePlayer: (playerId: number, updates: any) => void;
    updatePlayerRotation: (update: any) => void;
    updateMove: (move: Move, frameIdx?: number) => void;
    updateOption: (option: any) => void;
    updateAssignment: (id: number, defId: number, atkId?: number) => void;
    updatePass: (pass: any, frameIdx?: number) => void;
    updateShot: (shot: any) => void;
    removeMove: (move: Move | FieldPiece) => void;
    removeBallMovement: (ballId: number) => void;
    moveInitialBall: (carrier: number) => void;
    updateVideoLink: (url: string) => Promise<any>;
    updateFieldComment: (fieldComment: any) => void;
    removeFieldPiece: (pieceId: number) => void;
    deletePlayer: (playerId: string) => void;
    showOverlayItems: (items: any[]) => void;
    updateFrameTheme: (theme: any) => void;
    saveComment: (comment: { id: number; text: string }) => void;
    debug: PlayDebug;
}

export interface PluginContext {
    play: Play;
    frame: FrameSnapshot;
    fieldViewport: FieldViewport;
    api?: PluginApi;
    anchors?: Anchor[];
    lines?: AnchorLine[];
}

function selectPlugins(
    plugins: PlayPlugin<PluginContext>[],
    context: PluginContext,
    type: string
): PlayPlugin<PluginContext>[] {
    const { play } = context;
    const activePlugins = plugins.filter(
        (plugin) => plugin.types.includes(type) || plugin.specialAnchorTypes?.includes(type)
    );

    // Only match plugins based on baseType if there are none targeted directly at the type
    // this allows a fallback plugin for default behavior of the baseType
    // but with more specific behavior in a targeted plugin
    if (activePlugins.length === 0) {
        activePlugins.unshift(
            ...plugins.filter((plugin) => {
                return (
                    plugin.baseType === play.config.MoveConfig[type].baseType && !plugin.excludedTypes?.includes(type)
                );
            })
        );
    }

    return activePlugins;
}

export function generateAnchors(plugins: PlayPlugin<PluginContext>[], context: PluginContext) {
    const { fieldViewport } = context;

    return plugins
        .filter((plugin) => typeof plugin.generateAnchors === 'function')
        .flatMap((plugin) => {
            return plugin.generateAnchors(context) ?? [];
        })
        .map((anchor) => ({
            ...anchor,
            ...fieldViewport.getPixelCoords(anchor),
        }));
}

export function generateSelectedAnchors(
    plugins: PlayPlugin<PluginContext>[],
    context: PluginContext,
    selected: FieldPiece
) {
    const { fieldViewport, play } = context;

    if (!play) {
        return [];
    }

    return plugins
        .filter(
            (plugin) =>
                (plugin.types.includes(selected.type) ||
                    plugin.baseType === play.config.MoveConfig[selected.type]?.baseType) &&
                typeof plugin.generateSelectedAnchors === 'function'
        )
        .flatMap((plugin) => {
            return plugin.generateSelectedAnchors(context, selected) ?? [];
        })
        .map((anchor) => ({
            ...anchor,
            ...fieldViewport.getPixelCoords(anchor),
            distanceBias: 0.5,
        }));
}

export function generateLines(plugins: PlayPlugin<PluginContext>[], context: PluginContext) {
    const { fieldViewport } = context;
    return plugins
        .filter((plugin) => typeof plugin.generateLines === 'function')
        .flatMap((plugin) => {
            return plugin.generateLines(context) ?? [];
        })
        .map((line) => {
            return {
                ...fieldViewport.getPixelLine(line),
                angle: angleBetweenPoints(line.a, line.b),
            };
        });
}

export function handleDragEnd(
    plugins: PlayPlugin<PluginContext>[],
    context: PluginContext,
    anchor: Anchor,
    offset: Point
) {
    const { play, api } = context;
    const clamped = play.field.clampCoords(offset);

    const relevantPlugins = plugins.filter((plugin) => typeof plugin.handleDragEnd === 'function');
    const activePlugins = selectPlugins(relevantPlugins, context, anchor.type);

    activePlugins.forEach((plugin) => {
        plugin.handleDragEnd(context, anchor, offset, clamped);
    });

    api.showOverlayItems([]);
}

export function handleDrag(
    plugins: PlayPlugin<PluginContext>[],
    context: PluginContext,
    anchor: Anchor,
    offset: FieldPoint
) {
    const { play, frame } = context;
    const clamped = play.field.clampCoords(offset);

    const relevantPlugins = plugins.filter((plugin) => typeof plugin.handleDrag === 'function');
    const activePlugins = selectPlugins(relevantPlugins, context, anchor.type);

    const newFrame = frame.copy();
    // newFrame will be modified by plugins
    activePlugins.forEach((plugin) => {
        plugin.handleDrag({ ...context, frame: newFrame }, anchor, offset, clamped);
    });

    const idx = play.frameSnapshots.findIndex((fr) => fr.idx === frame.idx);

    return arrayReplace(play.frameSnapshots, idx, newFrame);
}

export function getDefaultMove(
    plugins: PlayPlugin<PluginContext>[],
    context: PluginContext,
    type: string,
    offset: Point
): Move {
    const relevantPlugins = plugins.filter((plugin) => typeof plugin.getDefaultMove === 'function');
    const [activePlugin] = selectPlugins(relevantPlugins, context, type);

    if (!activePlugin) {
        return null;
    }

    return activePlugin.getDefaultMove(context, type, offset);
}
