import {
    FieldPiecesPlugin,
    BallsPlugin,
    PlayersPlugin,
    ShotsPlugin,
    OptionsPlugin,
    AssignmentsPlugin,
    ShadingPlugin,
    FieldCommentsPlugin,
    SnapLinesPlugin,
} from '../plugins/index.js';
import FormationPlugin from './plugins/formation.js';
import MovementsPlugin from './plugins/movements.js';
import PassesPlugin from './plugins/passes.js';

export default [
    FieldPiecesPlugin,
    BallsPlugin,
    PlayersPlugin,
    MovementsPlugin,
    PassesPlugin,
    ShotsPlugin,
    OptionsPlugin,
    AssignmentsPlugin,
    ShadingPlugin,
    FieldCommentsPlugin,
    SnapLinesPlugin,
    FormationPlugin,
];
