import { DataTags } from '../../shared/constants.js';
import { RootState, Selectors } from '../state.js';
import { asyncWrapper, TD } from '../async-actions.js';
import { getVideoDetails } from '../main/async-actions.js';
import { playbookApi } from '../index.js';
import { playEditorActions } from '../index.js';

let newPlayId = 0;
// eslint-disable-next-line import/prefer-default-export
export const createPlay = (type: string, sport: string, name?: string, description = '', tags: string[] = []) => {
    return async (dispatch: TD, getState: () => RootState) => {
        const playbookId = Selectors.playbookId(getState());

        newPlayId += 1;
        const id = `__${newPlayId}`;
        dispatch(playEditorActions.CreatePlay({ id, type, sport, name, description, tags }));

        playbookApi.util.invalidateTags([{ type: DataTags.PLAYLIST_ITEM, id: `${playbookId}_LIST` }]);
    };
};

export const setVideoLink = (videoLink: string) => {
    return asyncWrapper(
        'setVideoLink',
        async (dispatch: TD) => {
            dispatch(
                playEditorActions.UpdatePlay({
                    videoLink,
                })
            );

            if (videoLink !== '') {
                const { thumbnailUrl } = await dispatch(getVideoDetails(videoLink));

                dispatch(
                    playEditorActions.UpdatePlay({
                        thumbnailUrl,
                    })
                );
            } else {
                dispatch(
                    playEditorActions.UpdatePlay({
                        thumbnailUrl: null,
                    })
                );
            }
        },
        { showLoading: false }
    );
};
