import { halfCirclePath } from '@labradorsports/utils';

const CollegeFullFieldBackdrop: FC<any> = ({ fieldViewport, backgroundImage }) => {
    const fieldWidth = 50;
    const fieldHeight = 94;

    return (
        <g className="field-backdrop">
            <image
                className="team-logo"
                xlinkHref={backgroundImage}
                width={fieldViewport.getPixelLength(fieldWidth / 2)}
                height={fieldViewport.getPixelLength(fieldWidth / 2)}
                x={
                    fieldViewport.flipped
                        ? fieldViewport.getPixelX(fieldWidth * 0.75)
                        : fieldViewport.getPixelX(fieldWidth * 0.25)
                }
                y={fieldViewport.flipped ? fieldViewport.getPixelY(fieldHeight / 2 - 4) : fieldViewport.getPixelY(4)}
                transform={`rotate(${fieldViewport.flipped ? 0 : 180}, ${fieldViewport.getPixelX(
                    fieldWidth / 2
                )} ${fieldViewport.getPixelY(fieldHeight * 0.25)})`}
            />
            <image
                className="team-logo"
                xlinkHref={backgroundImage}
                width={fieldViewport.getPixelLength(fieldWidth / 2)}
                height={fieldViewport.getPixelLength(fieldWidth / 2)}
                x={
                    fieldViewport.flipped
                        ? fieldViewport.getPixelX(fieldWidth * 0.75)
                        : fieldViewport.getPixelX(fieldWidth * 0.25)
                }
                y={
                    fieldViewport.flipped
                        ? fieldViewport.getPixelY(fieldHeight - 4)
                        : fieldViewport.getPixelY(fieldHeight - 43)
                }
                transform={`rotate(${fieldViewport.flipped ? 180 : 0}, ${fieldViewport.getPixelX(
                    fieldWidth / 2
                )} ${fieldViewport.getPixelY(fieldHeight * 0.75)})`}
            />

            {/* Field Outline */}
            <rect
                x={fieldViewport.flipped ? fieldViewport.getPixelX(fieldWidth) : fieldViewport.getPixelX(0)}
                y={fieldViewport.flipped ? fieldViewport.getPixelY(fieldHeight) : fieldViewport.getPixelY(0)}
                width={fieldViewport.getPixelLength(fieldWidth)}
                height={fieldViewport.getPixelLength(fieldHeight)}
                fill="none"
            />

            {/* Bottom free throw lines */}
            <line
                x1={fieldViewport.getPixelX(19)}
                y1={fieldViewport.getPixelY(fieldHeight - 19)}
                x2={fieldViewport.getPixelX(19)}
                y2={fieldViewport.getPixelY(fieldHeight)}
            />
            <line
                x1={fieldViewport.getPixelX(19)}
                y1={fieldViewport.getPixelY(fieldHeight - 19)}
                x2={fieldViewport.getPixelX(fieldWidth - 19)}
                y2={fieldViewport.getPixelY(fieldHeight - 19)}
            />
            <line
                x1={fieldViewport.getPixelX(fieldWidth - 19)}
                y1={fieldViewport.getPixelY(fieldHeight - 19)}
                x2={fieldViewport.getPixelX(fieldWidth - 19)}
                y2={fieldViewport.getPixelY(fieldHeight)}
            />
            <path
                fill="none"
                d={halfCirclePath(
                    fieldViewport.getPixelLength(6),
                    fieldViewport.flipped ? fieldViewport.getPixelX(fieldWidth - 19) : fieldViewport.getPixelX(19),
                    fieldViewport.getPixelY(fieldHeight - 19),
                    fieldViewport.flipped
                )}
            />

            {/* Top free throw lines */}
            <line
                x1={fieldViewport.getPixelX(19)}
                y1={fieldViewport.getPixelY(19)}
                x2={fieldViewport.getPixelX(19)}
                y2={fieldViewport.getPixelY(0)}
            />
            <line
                x1={fieldViewport.getPixelX(19)}
                y1={fieldViewport.getPixelY(19)}
                x2={fieldViewport.getPixelX(fieldWidth - 19)}
                y2={fieldViewport.getPixelY(19)}
            />
            <line
                x1={fieldViewport.getPixelX(fieldWidth - 19)}
                y1={fieldViewport.getPixelY(19)}
                x2={fieldViewport.getPixelX(fieldWidth - 19)}
                y2={fieldViewport.getPixelY(0)}
            />
            <path
                fill="none"
                d={halfCirclePath(
                    fieldViewport.getPixelLength(6),
                    fieldViewport.flipped ? fieldViewport.getPixelX(fieldWidth - 19) : fieldViewport.getPixelX(19),
                    fieldViewport.getPixelY(19),
                    !fieldViewport.flipped
                )}
            />

            {/* Bottom 3 point line */}
            <path
                fill="none"
                d={halfCirclePath(
                    fieldViewport.getPixelLength(fieldWidth / 2 - 4.25),
                    fieldViewport.flipped ? fieldViewport.getPixelX(fieldWidth - 4.25) : fieldViewport.getPixelX(4.25),
                    fieldViewport.getPixelY(fieldHeight - 4.2),
                    fieldViewport.flipped
                )}
            />
            <line
                x1={fieldViewport.getPixelX(4.25)}
                y1={fieldViewport.getPixelY(fieldHeight - 4.2)}
                x2={fieldViewport.getPixelX(4.25)}
                y2={fieldViewport.getPixelY(fieldHeight)}
            />
            <line
                x1={fieldViewport.getPixelX(45.75)}
                y1={fieldViewport.getPixelY(fieldHeight - 4.2)}
                x2={fieldViewport.getPixelX(45.75)}
                y2={fieldViewport.getPixelY(fieldHeight)}
            />

            {/* Top 3 point line */}
            <path
                fill="none"
                d={halfCirclePath(
                    fieldViewport.getPixelLength(fieldWidth / 2 - 4.25),
                    fieldViewport.flipped ? fieldViewport.getPixelX(fieldWidth - 4.25) : fieldViewport.getPixelX(4.25),
                    fieldViewport.getPixelY(4.2),
                    !fieldViewport.flipped
                )}
            />
            <line
                x1={fieldViewport.getPixelX(4.25)}
                y1={fieldViewport.getPixelY(4.2)}
                x2={fieldViewport.getPixelX(4.25)}
                y2={fieldViewport.getPixelY(0)}
            />
            <line
                x1={fieldViewport.getPixelX(fieldWidth - 4.25)}
                y1={fieldViewport.getPixelY(4.2)}
                x2={fieldViewport.getPixelX(fieldWidth - 4.25)}
                y2={fieldViewport.getPixelY(0)}
            />

            {/* Center lines */}
            <circle
                cx={fieldViewport.getPixelX(fieldWidth / 2)}
                cy={fieldViewport.getPixelY(fieldHeight / 2)}
                r={fieldViewport.getPixelLength(6)}
                fill="none"
            />
            <line
                x1={fieldViewport.getPixelX(0)}
                y1={fieldViewport.getPixelY(fieldHeight / 2)}
                x2={fieldViewport.getPixelX(fieldWidth)}
                y2={fieldViewport.getPixelY(fieldHeight / 2)}
            />

            {/* Bottom substitution area */}
            <line
                x1={fieldViewport.getPixelX(fieldWidth - 3)}
                y1={fieldViewport.getPixelY(fieldHeight / 2 + 19)}
                x2={fieldViewport.getPixelX(fieldWidth + 3)}
                y2={fieldViewport.getPixelY(fieldHeight / 2 + 19)}
            />
            <line
                x1={fieldViewport.getPixelX(fieldWidth)}
                y1={fieldViewport.getPixelY(fieldHeight / 2 + 4)}
                x2={fieldViewport.getPixelX(fieldWidth + 3)}
                y2={fieldViewport.getPixelY(fieldHeight / 2 + 4)}
            />

            {/* Top substitution area */}
            <line
                x1={fieldViewport.getPixelX(fieldWidth - 3)}
                y1={fieldViewport.getPixelY(fieldHeight / 2 - 19)}
                x2={fieldViewport.getPixelX(fieldWidth + 3)}
                y2={fieldViewport.getPixelY(fieldHeight / 2 - 19)}
            />
            <line
                x1={fieldViewport.getPixelX(fieldWidth)}
                y1={fieldViewport.getPixelY(fieldHeight / 2 - 4)}
                x2={fieldViewport.getPixelX(fieldWidth + 3)}
                y2={fieldViewport.getPixelY(fieldHeight / 2 - 4)}
            />

            <text
                dx={
                    fieldViewport.flipped
                        ? fieldViewport.getPixelX(4.25) - 5
                        : fieldViewport.getPixelX(fieldWidth - 4.25) - 5
                }
                dy={fieldViewport.flipped ? fieldViewport.getPixelY(0) - 5 : fieldViewport.getPixelY(fieldHeight) - 5}
                textAnchor="end"
                strokeWidth="0"
                fill="#aaa"
                fontSize=".8em"
            >
                Hoops-Lab.com
            </text>
        </g>
    );
};
export default CollegeFullFieldBackdrop;
