interface Props {
    progress: number;
}

const ProgressBar: FC<Props> = ({ progress }) => (
    <div className="progress-bar">
        <span className="progress" style={{ width: `${Math.max(0, Math.min(progress, 100))}%` }} />
    </div>
);

export default ProgressBar;
