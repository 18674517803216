import { createAssetGetter } from '@labradorsports/assets';

export const LogoFullImg = createAssetGetter('img/basketball/HL_Long.png');
export const LogoImg = createAssetGetter('img/basketball/logo.png');
export const Watermark = createAssetGetter('img/basketball/Watermark.svg');
export const FullFieldIcon = createAssetGetter('img/basketball/FullField.svg');
export const HalfFieldIcon = createAssetGetter('img/basketball/HalfField.svg');
export const DepthChartImg = createAssetGetter('img/basketball/DepthChart.svg');
export const _3out2inIcon = createAssetGetter('img/basketball/3out2in.svg');
export const _4out1inIcon = createAssetGetter('img/basketball/4out1in.svg');
export const _5outIcon = createAssetGetter('img/basketball/5out.svg');
