import { useMemo } from 'react';

import { useSite } from '@labradorsports/utils';
import * as Assets from './images.js';

export default function useAssets() {
    const {
        env: { cdnUrl },
        Images,
    } = useSite();

    return useMemo(() => {
        return Object.fromEntries(
            Object.entries({
                ...Images,
                ...Assets,
            }).map(([name, getter]) => {
                return [name, getter(cdnUrl)];
            })
        );
    }, []);
}
