import { useNavigate } from 'react-router-dom';

import { useDispatcher } from '../../shared/hooks/index.js';
import { playbookActions, useMyPlaybookQuery, ShareConfig, useTeamPlaybookQuery } from '../../store/index.js';

export default function useStartSharing() {
    const navigate = useNavigate();
    const setSharing = useDispatcher(playbookActions.SetSharing);
    const {
        data: { plays, folders },
    } = useMyPlaybookQuery();
    const {
        data: { shared, folders: teamFolders },
    } = useTeamPlaybookQuery();

    return (shareConfig?: ShareConfig) => {
        setSharing(shareConfig);

        if (!shareConfig) {
            navigate('/playbook');
        } else if (shareConfig.myPlaybook) {
            navigate('/playbook/share/my/playbook');
        } else if (shareConfig.itemIds) {
            const [myPlayIds, teamPlayIds, myFolderIds, teamFolderIds] = shareConfig.itemIds.reduce(
                (acc, itemId) => {
                    if (plays.some((play) => play.id === itemId)) {
                        return [acc[0].concat([itemId]), acc[1], acc[2], acc[3]];
                    }

                    if (shared.some((play) => play.id === itemId)) {
                        return [acc[0], acc[1].concat([itemId]), acc[2], acc[3]];
                    }

                    if (folders.some((folder) => folder.id === itemId)) {
                        return [acc[0], acc[1], acc[2].concat([itemId]), acc[3]];
                    }

                    if (teamFolders.some((folder) => folder.id === itemId)) {
                        return [acc[0], acc[1], acc[2], acc[3].concat([itemId])];
                    }

                    return acc;
                },
                [[], [], [], []]
            );

            const isPlay = myPlayIds.length > 0 || teamPlayIds.length > 0;

            if (shareConfig.itemIds.length === 1 && isPlay) {
                if (teamPlayIds.length > 0) {
                    navigate('/playbook/share/team/play');
                } else {
                    navigate('/playbook/share/my/play');
                }
            } else {
                if (teamPlayIds.length > 0 || teamFolderIds.length > 0) {
                    navigate('/playbook/share/team/items/team');
                } else {
                    navigate('/playbook/share/my/items/team');
                }
            }
        }
    };
}
