import { createAssetGetter } from '@labradorsports/assets';

export const LogoFullImg = createAssetGetter('img/football/FL_Long.png');
export const LogoImg = createAssetGetter('img/football/logo.png');
export const Watermark = createAssetGetter('img/football/LL_Watermark.svg');
export const FullFieldIcon = createAssetGetter('img/football/FullField.svg');
export const HalfFieldIcon = createAssetGetter('img/football/HalfField.svg');
export const DepthChartImg = createAssetGetter('img/football/DepthChart.svg');
export const _231Icon = createAssetGetter('img/football/231.svg');
export const _33Icon = createAssetGetter('img/football/33.svg');
export const _222Icon = createAssetGetter('img/football/222.svg');
export const _141Icon = createAssetGetter('img/football/141.svg');
export const CircleIcon = createAssetGetter('img/football/Circle.svg');
export const WomensCircleIcon = createAssetGetter('img/football/WomensCircle.svg');
export const _232Icon = createAssetGetter('img/football/232.svg');
export const _3out2inIcon = createAssetGetter('img/basketball/3out2in.svg');
export const _4out1inIcon = createAssetGetter('img/basketball/4out1in.svg');
export const _5outIcon = createAssetGetter('img/basketball/5out.svg');
