/* eslint-disable no-param-reassign */
import { User } from 'firebase/auth';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Exception } from '@labradorsports/utils';

export interface AuthState {
    signup: any;
    user: User;
    accountFormError: Exception;
    deeplink: string;
    loginEmail: string;
    emailAction: {
        mode: string;
        oobCode: string;
        apiKey: string;
        continueUrl: string;
    };
    reauthenticating: boolean;
    authReady: boolean;
    profileLoaded: boolean;
}

const authDefaultState: AuthState = {
    signup: undefined,
    user: undefined,
    accountFormError: undefined,
    deeplink: undefined,
    loginEmail: '',
    emailAction: {
        mode: undefined,
        oobCode: undefined,
        apiKey: undefined,
        continueUrl: undefined,
    },
    reauthenticating: false,
    authReady: false,
    profileLoaded: false,
};

const { reducer: authReducer, actions: authActions } = createSlice({
    name: 'auth',
    initialState: authDefaultState,
    reducers: {
        LoginChange: (state, action) => {
            state.user = action.payload;
            state.authReady = true;
        },

        AccountFormError: (state, action: PayloadAction<Exception | null>) => {
            state.accountFormError = action.payload;
        },

        StartSignup: {
            prepare: (firstName: string, lastName: string) => ({ payload: { firstName, lastName } }),
            reducer: (state, action: PayloadAction<{ firstName: string; lastName: string }, string>) => {
                state.signup = action.payload;
            },
        },

        PurgeProfile: ({ emailAction }) => ({
            ...authDefaultState,
            emailAction,
            user: null,
        }),

        SetLoginEmail: (state, action) => {
            state.loginEmail = action.payload;
        },

        Reauthenticating: (state, action: PayloadAction<boolean | null>) => {
            state.reauthenticating = action.payload;
        },

        ProfileLoaded: (state, action) => {
            state.profileLoaded = action.payload;
        },
    },
});

export { authReducer, authActions, authDefaultState };
