/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ToolTypes, ToolBaseTypes, Sites } from '@labradorsports/constants';
import {
    conformPlayData,
    convertToUnifiedField,
    createDefaultGoals,
    Field,
    getEmptyFrame,
    getFormationAnchor,
    getLinePlayerPosition,
    getNewPlay,
    PlayData,
    removeBallMovement,
    removePlayerFromFrames,
    updateExistingOrCreateNew,
    applyOffset,
} from '@labradorsports/play-rendering';
import SportConfigs from '@labradorsports/sports';
import { getSiteForSport } from '@labradorsports/utils';
import { linePlayerId } from '../../shared/utils.js';
import { authActions } from '../auth/index.js';

export interface PlayEditorState {
    PlayConfig: PlayConfigSpec;
    playModified: boolean;
    currentFrame: number;
    savingPlay: boolean;

    play: PlayData;
    debugOptions: any;
    debugMarkers: any[];

    editHistory: any[];
}

const playEditorDefaultState: PlayEditorState = {
    PlayConfig: undefined,
    play: undefined,
    currentFrame: 0,
    playModified: false,
    savingPlay: false,
    debugOptions: {},
    debugMarkers: [],
    editHistory: [],
};

const playModifierReducers = {
    AddFrame: (state, action: PayloadAction<number | null>) => {
        if (action.payload !== undefined) {
            state.play.frames = [
                ...state.play.frames.slice(0, action.payload + 1),
                getEmptyFrame(),
                ...state.play.frames.slice(action.payload + 1),
            ];
            state.currentFrame = action.payload + 1;
        } else {
            state.play.frames.push(getEmptyFrame());
            state.currentFrame = state.play.frames.length - 1;
        }
    },

    UpdateMove: (state, action: PayloadAction<any>) => {
        const { frameIdx = Math.floor(state.currentFrame), ...move } = action.payload;
        const { moves } = state.play.frames[frameIdx];

        const existingMove = moves.find((mv: any) => {
            return mv.target === action.payload.target;
        });

        if (existingMove) {
            Object.assign(existingMove, move);
        } else {
            moves.push(move);
        }
    },

    UpdatePass: (state, action: PayloadAction<any>) => {
        const { PlayTypes } = state.PlayConfig;
        const { frameIdx = Math.floor(state.currentFrame), ...pass } = action.payload;
        const currentFrame = state.play.frames[frameIdx];

        if (state.play.type === PlayTypes.DRILL) {
            currentFrame.passes = currentFrame.passes ?? [];

            updateExistingOrCreateNew(currentFrame.passes, {
                ...pass,
                // Pass ID should match ball ID to keep a single pass per ball per frame
                id: pass.id ?? pass.ball,
            });
        } else {
            currentFrame.passes = [
                {
                    // Copy existing pass props
                    ...(currentFrame.passes?.[0] ?? {}),
                    ...pass,
                    id: 0,
                },
            ];
        }
    },

    UpdateOption: (state, action: PayloadAction<any>) => {
        const currentFrame = state.play.frames[state.currentFrame];

        if (!currentFrame.options) currentFrame.options = [];

        updateExistingOrCreateNew(currentFrame.options, {
            ...action.payload,
            type: ToolTypes.OPTION,
        });
    },

    UpdatePlayer: (state, action: PayloadAction<any>) => {
        updateExistingOrCreateNew(state.play.initial, action.payload);
    },

    DeletePlayer: (state, action: PayloadAction<any>) => {
        if (typeof action.payload === 'undefined') {
            return;
        }

        const players = state.play.initial;
        const [removed] = players.splice(
            players.findIndex((plr) => plr.id === action.payload),
            1
        );

        const ballIdx = state.play.balls.findIndex((ball) => ball.carrier === action.payload);

        if (ballIdx !== -1) {
            state.play.balls.splice(ballIdx, 1);
        }

        removePlayerFromFrames(state.play.frames, removed);
    },

    UpdateFrameRole: {
        prepare: (playerId: number, role?: string): any => ({
            payload: {
                id: playerId,
                role,
            },
        }),
        reducer: (state, action: PayloadAction<any>): void => {
            // Frame roles are stored in frame.slides
            const currentFrame = state.play.frames[state.currentFrame];

            // Initialize slides if it doesn't exist yet
            currentFrame.slides = currentFrame.slides ?? [];

            const { slides } = currentFrame;

            const existingIdx = slides.findIndex((sl: any) => sl.target === action.payload.id);

            if (action.payload.role) {
                const slide = {
                    target: action.payload.id,
                    role: action.payload.role,
                };

                if (existingIdx !== -1) {
                    slides.splice(existingIdx, 1, slide);
                } else {
                    slides.push(slide);
                }
            } else if (existingIdx !== -1) {
                slides.splice(existingIdx, 1);
            }
        },
    },

    UpdateAssignment: {
        prepare: (id: number, defId: number, atkId?: number): any => ({
            payload: {
                id,
                defId,
                atkId,
            },
        }),
        reducer: (state, action: PayloadAction<any>): void => {
            const currentFrame = state.play.frames[state.currentFrame];

            currentFrame.assignments = currentFrame.assignments ?? [];

            const { assignments } = currentFrame;

            const existingIdx = assignments.findIndex((asn: any) => asn.id === action.payload.id);
            if (existingIdx !== -1) {
                // Is there another assignment with the same two targets?
                const sameIdx = assignments.findIndex(
                    (asn: any) =>
                        asn.defId === action.payload.defId &&
                        asn.atkId === action.payload.atkId &&
                        asn.id !== action.payload.id
                );

                if (sameIdx === -1) {
                    assignments.splice(existingIdx, 1, action.payload);
                } else {
                    // If there is, remove this one
                    assignments.splice(existingIdx, 1);
                }
            } else {
                let newId = 0;
                const findExisting = (asn: any) => asn.id === newId;
                while (assignments.find(findExisting)) {
                    newId += 1;
                }

                const newAsn = {
                    ...action.payload,
                    id: newId,
                };

                if (newAsn.atkId === undefined) {
                    delete newAsn.atkId;
                }

                assignments.push(newAsn);
            }
        },
    },

    RemoveMove: (state, action: PayloadAction<any>) => {
        const { MoveConfig } = state.PlayConfig;
        const trgPlayer = state.play.initial.find((plr) => plr.id === action.payload.target);
        const trgPlayerPiece = trgPlayer?.pieceId && state.play.fieldPieces.find((pc) => pc.id === trgPlayer.pieceId);

        const frame = state.play.frames[Math.floor(state.currentFrame)];

        if (
            frame.shots?.length > 0 &&
            (MoveConfig[action.payload.type]?.baseType === ToolTypes.SHOOT || trgPlayerPiece?.type === ToolTypes.GOAL)
        ) {
            frame.shots = frame.shots.filter((shot: any) => shot.id !== action.payload.id);
        } else if (MoveConfig[action.payload.type]?.baseType === ToolBaseTypes.PASS) {
            frame.passes = frame.passes.filter((pass: any) => pass.id !== action.payload.id);
        } else if (action.payload.type === ToolTypes.ASSIGN) {
            frame.assignments = frame.assignments.filter((asn: any) => asn.id !== action.payload.id);
        } else if (action.payload.type === ToolTypes.OPTION) {
            frame.options = frame.options.filter((opt: any) => opt.id !== action.payload.id);
        } else if (action.payload.type === ToolTypes.COMMENT) {
            frame.fieldComments = frame.fieldComments.filter((comment: any) => comment.id !== action.payload.id);
        } else {
            frame.moves = frame.moves.filter((mv: any) => mv.target !== action.payload.target);
        }
    },

    UpdateShot: (state, action: PayloadAction<any>) => {
        const { PlayTypes } = state.PlayConfig;
        const currentFrame = state.play.frames[state.currentFrame];
        if (state.play.type === PlayTypes.DRILL) {
            currentFrame.shots = currentFrame.shots ?? [];
            updateExistingOrCreateNew(currentFrame.shots, {
                // Shot ID should match ball ID to keep a single pass per ball per frame
                ...action.payload,
                type: ToolTypes.SHOOT,
                id: action.payload.id ?? action.payload.ball,
            });
        } else {
            currentFrame.shots = [
                {
                    // Copy existing shot props
                    type: ToolTypes.SHOOT,
                    ...(currentFrame.shots?.[0] ?? {}),
                    ...action.payload,
                    id: 0,
                },
            ];
        }
    },

    UpdateFieldComment: (state, action: PayloadAction<any>) => {
        const currentFrame = state.play.frames[state.currentFrame];

        // Initialize fieldComments if it doesn't exist yet
        currentFrame.fieldComments = currentFrame.fieldComments ?? [];

        updateExistingOrCreateNew(currentFrame.fieldComments, action.payload);
    },

    UpdateFieldPiece: (state, action: PayloadAction<any>) => {
        const { FieldTypes, FieldSettings } = state.PlayConfig;
        // Initialize fieldPieces if it doesn't exist yet
        state.play.fieldPieces = state.play.fieldPieces ?? [];

        const { fieldPieces } = state.play;
        const piece = action.payload;

        updateExistingOrCreateNew(fieldPieces, piece);

        const newPiece =
            typeof piece.id !== 'undefined'
                ? fieldPieces.find((pc) => pc.id === piece.id)
                : fieldPieces[fieldPieces.length - 1];

        if ([ToolTypes.OLINE, ToolTypes.DLINE].includes(newPiece.type)) {
            const minPlayers = 2;
            const maxPlayers = 8;

            newPiece.playerCount = Math.min(
                maxPlayers,
                Math.max(minPlayers, newPiece.playerCount === undefined ? 3 : newPiece.playerCount)
            );

            const { width, height, sideline } =
                state.play.fieldType === FieldTypes.CUSTOM
                    ? state.play.fieldConfig
                    : FieldSettings[state.play.sport][state.play.fieldType];

            const linePlayers = new Array(newPiece.playerCount).fill(null).map((_, i) => {
                const position = getLinePlayerPosition(
                    newPiece.origin,
                    newPiece.playerCount,
                    i,
                    new Field(width, height, sideline),
                    newPiece.rotation
                );

                const existing = state.play.initial.find(
                    (plr) => plr.pieceId === newPiece.id && plr.linePosition === i
                );

                if (existing) {
                    return {
                        ...existing,
                        origin: position,
                    };
                }

                return {
                    id: linePlayerId(newPiece, i),
                    pieceId: newPiece.id,
                    type: newPiece.type === ToolTypes.OLINE ? ToolTypes.BLUEPLAYER : ToolTypes.ORANGEPLAYER,
                    origin: position,
                    linePosition: i,
                };
            });

            state.play.initial = state.play.initial.filter((plr) => plr.pieceId !== newPiece.id).concat(linePlayers);
        } else if (newPiece.type === ToolTypes.GOAL) {
            newPiece.goalieType = newPiece.goalieType ?? ToolTypes.ORANGEPLAYER;

            state.play.initial = state.play.initial.filter((plr) => plr.pieceId !== newPiece.id);

            updateExistingOrCreateNew(state.play.initial, {
                type: newPiece.goalieType,
                pieceId: newPiece.id,
                origin: newPiece.origin,
            });
        }
    },

    RemoveFieldPiece: (state, action: PayloadAction<any>) => {
        const { fieldPieces } = state.play;
        const [removed] = fieldPieces.splice(
            fieldPieces.findIndex((pc) => pc.id === action.payload),
            1
        );

        if (removed.type === ToolTypes.OLINE || removed.type === ToolTypes.DLINE || removed.type === ToolTypes.GOAL) {
            const newPlayers = state.play.initial.filter((plr) => plr.pieceId !== removed.id);
            const removedPlayers = state.play.initial.filter((plr) => plr.pieceId === removed.id);

            state.play.initial = newPlayers;

            const affectedBalls = state.play.balls.filter((ball) =>
                removedPlayers.some((plr) => plr.id === ball.carrier)
            );

            affectedBalls.forEach((ball) => removeBallMovement(state.play, ball.id));
            removedPlayers.forEach((plr) => removePlayerFromFrames(state.play.frames, plr));
        }

        if (removed.type === ToolTypes.GOAL) {
            state.play.frames.forEach((frame) => {
                if (frame.shoot === removed.id) {
                    delete frame.shoot;
                }
            });
        }
    },

    UpdatePlayerRotation: (state, action: PayloadAction<any>) => {
        const currentFrame = state.play.frames[state.currentFrame];

        // Initialize playerRotations if it doesn't exist yet
        currentFrame.playerRotations = currentFrame.playerRotations ?? [];

        const existingIdx = currentFrame.playerRotations.findIndex(
            (rotation: any) => rotation.target === action.payload.id
        );

        if (action.payload.rotation !== undefined) {
            if (existingIdx === -1) {
                currentFrame.playerRotations.push({
                    target: action.payload.id,
                    rotation: action.payload.rotation,
                });
            } else {
                currentFrame.playerRotations[existingIdx].rotation = action.payload.rotation;
            }
        } else if (existingIdx !== -1) {
            currentFrame.playerRotations.splice(existingIdx, 1);
        }
    },

    RemoveBallMovement: (state, action: PayloadAction<number>) => {
        removeBallMovement(state.play, action.payload);
    },

    MoveInitialBall: (state, action: PayloadAction<any>) => {
        const { PlayTypes } = state.PlayConfig;

        if (state.play.type === PlayTypes.DRILL) {
            updateExistingOrCreateNew(state.play.balls, {
                carrier: action.payload,
            });
        } else {
            state.play.balls = [
                {
                    id: 0,
                    carrier: action.payload,
                },
            ];
        }
    },

    ApplyFormation: (state, action: PayloadAction<any>) => {
        const { initial, balls, fieldPieces, id, anchorType } = action.payload;

        const { pieces } = createDefaultGoals(state.PlayConfig, state.play);
        const anchor = getFormationAnchor(state.PlayConfig, state.play, anchorType, pieces);

        state.play.initial = initial.map((plr) => ({
            ...plr,
            origin: applyOffset(anchor, plr.origin),
        }));

        state.play.fieldPieces = fieldPieces.map((piece) => ({
            ...piece,
            origin: applyOffset(anchor, piece.origin),
        }));

        state.play.balls = balls;
        state.play.formationId = id ?? null;

        state.play.frames = state.play.frames.map(() => getEmptyFrame());
    },

    ClearField: (state, action: PayloadAction<any>) => {
        const { PlayTypes } = state.PlayConfig;

        state.play.initial = [];
        state.play.fieldPieces = [];
        state.play.frames = state.play.frames.map(() => getEmptyFrame());
        state.play.balls = [];

        if (state.play.type !== PlayTypes.DRILL) {
            const { pieces, goalies } = createDefaultGoals(state.PlayConfig, state.play);
            state.play.fieldPieces = pieces;
            state.play.initial = goalies;
        }
    },

    ConvertToUnifiedField: (state) => {
        state.play = convertToUnifiedField(state.PlayConfig, state.play);
    },

    UpdateFrameTheme: (state, action: PayloadAction<any>) => {
        const currentFrame = state.play.frames[state.currentFrame];

        currentFrame.theme = currentFrame.theme ?? [];

        const { theme } = currentFrame;

        const existingIdx = theme.findIndex((sl: any) => sl.target === action.payload.target);

        if (action.payload.color || action.payload.shape) {
            if (existingIdx !== -1) {
                theme.splice(existingIdx, 1, {
                    ...theme[existingIdx],
                    ...action.payload,
                });
            } else {
                theme.push(action.payload);
            }
        } else if (existingIdx !== -1) {
            theme.splice(existingIdx, 1);
        }
    },
};

const { reducer: playEditorReducer, actions: playEditorActions } = createSlice({
    name: 'playEditor',
    initialState: playEditorDefaultState,
    reducers: {
        CreatePlay: (state, action) => {
            const play = getNewPlay(state.PlayConfig, action.payload);

            // TODO: figure out a good way to make this part of NewPlaySettings
            if (getSiteForSport(action.payload.sport) === Sites.footballlab) {
                const { PlayerPositions } = SportConfigs[Sites.footballlab];
                play.frames[1].passes = [
                    {
                        type: state.PlayConfig.ToolTypes.SNAP,
                        target: play.initial.find((p) => p.role === PlayerPositions.QUARTERBACK).id,
                        id: 0,
                        ball: 0,
                    },
                ];
            }

            // Returning new state to ensure that no properties of a previous play carry over to the new play
            return {
                ...state,
                currentFrame: 0,
                editHistory: [play],
                play,
            };
        },

        SetupField: (state, action) => {
            state.currentFrame = 0;
            state.play.fieldSetup = action.payload;
        },

        SelectFrame: (state, action) => {
            // Use frames.length to allow football scrubbing to the end of last frame
            const frameCount = state.play?.frames ? state.play.frames.length - 0.000001 : 0;
            state.currentFrame = Math.min(action.payload, frameCount);
        },

        SaveComment: (state, action) => {
            const { id, text } = action.payload;
            const currentFrame = state.play.frames[state.currentFrame];
            if (id === -1) {
                currentFrame.comment = text;
            } else {
                const fieldComment = currentFrame.fieldComments?.find((comm: any) => comm.id === id);

                if (fieldComment) {
                    fieldComment.text = text;
                } else {
                    throw new Error(
                        `Invalid save comment frameIdx: ${state.currentFrame} commentId: ${id} frameComments: ${currentFrame.fieldComments?.length}`
                    );
                }
            }

            state.playModified = true;
        },

        PlayLoaded: (state, action: PayloadAction<PlayData | null>) => {
            state.currentFrame = 0;
            state.play = undefined;
            state.playModified = false;

            if (action.payload) {
                const conformed = conformPlayData(state.PlayConfig, action.payload);
                state.play = conformed;
                state.playModified = JSON.stringify(conformed) !== JSON.stringify(action.payload);
                state.editHistory = [state.play];
            } else {
                state.editHistory = [];
            }
        },

        PlayModified: (state, action) => {
            state.playModified = action.payload;
        },

        RemoveFrame: (state) => {
            const lastFrameIdx = state.play.frames.length - 1;
            state.play.frames.pop();

            if (state.currentFrame >= lastFrameIdx) {
                state.currentFrame = lastFrameIdx - 1;
            }

            state.playModified = true;
        },

        UpdateFieldType: {
            prepare: (type: string, width?: number, height?: number) => ({
                payload: { type, width, height },
            }),
            reducer: (state, action: PayloadAction<any>) => {
                const { type, width, height } = action.payload;
                const { FieldTypes } = state.PlayConfig;

                state.play.fieldType = type;
                state.play.fieldConfig =
                    type === FieldTypes.CUSTOM
                        ? {
                              width,
                              height,
                          }
                        : null;

                if (type !== FieldTypes.CUSTOM) {
                    const { pieces, goalies } = createDefaultGoals(state.PlayConfig, state.play);
                    state.play.fieldPieces = pieces;
                    state.play.initial = goalies;
                }

                state.playModified = true;
            },
        },

        Saving: (state, action) => {
            state.savingPlay = action.payload;
        },

        NewPlaySaved: (state, action) => {
            if (state.play) {
                state.play.id = action.payload;
            }
        },

        Undo: (state) => {
            if (state.editHistory.length > 1) {
                state.editHistory.shift();
                [state.play] = state.editHistory;
                state.playModified = true;
                state.currentFrame = Math.min(state.play.frames.length - 1, state.currentFrame);
            }
        },

        UpdatePlay: (state, action) => {
            Object.assign(state.play, action.payload);
            state.playModified = true;
        },

        UpdateMarkers: (state, action: PayloadAction<Point[]>) => {
            if (action.payload) {
                state.debugMarkers = [...(state.debugMarkers ?? []), ...action.payload];
            } else {
                state.debugMarkers = [];
            }
        },

        UpdateDebugOptions: (state, action: PayloadAction<object | null>) => {
            if (action.payload) {
                state.debugOptions = {
                    ...state.debugOptions,
                    ...action.payload,
                };
            } else {
                state.debugOptions = {};
            }
        },

        ...playModifierReducers,
    },
    extraReducers: (builder) =>
        builder
            .addCase(authActions.PurgeProfile.type, (state) => ({
                ...playEditorDefaultState,
                PlayConfig: state.PlayConfig,
            }))
            .addMatcher(
                (action): action is PayloadAction =>
                    Boolean(
                        playModifierReducers[
                            action.type.replace('playEditor/', '') as keyof typeof playModifierReducers
                        ]
                    ),
                (state) => {
                    state.playModified = true;

                    state.editHistory.unshift(state.play);

                    state.editHistory = state.editHistory.slice(0, 100);
                }
            ),
});

export { playEditorReducer, playEditorActions, playEditorDefaultState };
