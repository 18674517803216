import { DndProvider, createTransition, MouseTransition } from 'react-dnd-multi-backend';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import PointerBackend from 'react-dnd-touch-pointer-events-backend';

const isEdge = typeof navigator === 'undefined' ? null : /Edge/.test(navigator.userAgent);
const CustomTouchTransition = createTransition('touchstart', (event: any) => {
    return event.touches != null && !isEdge; // eslint-disable-line no-eq-null, eqeqeq
});

const CustomPointerTransition = createTransition('touchstart', (event: any) => {
    return event.touches != null && isEdge; // eslint-disable-line no-eq-null, eqeqeq
});

const HTML5toTouch = {
    backends: [
        // Repeating the HTML5Backend forces React-DnD to respect the 'preview' option
        {
            id: 'html5',
            backend: HTML5Backend,
        },
        {
            id: 'html5_preview',
            backend: HTML5Backend,
            preview: true,
            transition: MouseTransition,
        },
        {
            id: 'touch',
            backend: TouchBackend,
            options: { enableTouchEvents: true },
            preview: true,
            transition: CustomTouchTransition,
        },
        {
            id: 'pointer',
            backend: PointerBackend,
            preview: true,
            transition: CustomPointerTransition,
        },
    ],
};

const DnDContext: FC<any> = ({ children }) => {
    return <DndProvider options={HTML5toTouch}>{children}</DndProvider>;
};

export default DnDContext;
