import { ReactNode, RefObject } from 'react';

interface Props {
    className?: string;
    content: ReactNode;
    header?: ReactNode;
    footer?: ReactNode;
    noPadding?: boolean;
    tabs?: boolean;
    containerRef?: RefObject<HTMLDivElement>;
}

const Panel: FC<Props> = ({ content, className = '', header, tabs, footer, noPadding, containerRef }) => {
    const classes = ['panel-container'];

    if (className) classes.push(className);
    if (footer) classes.push('with-footer');
    if (tabs) classes.push('with-tabs');

    const panel = (
        <div className={classes.join(' ')} ref={containerRef}>
            {header}
            <div className={`panel-content ${noPadding ? 'no-padding' : ''}`}>{content}</div>
            {footer}
        </div>
    );

    return panel;
};

export default Panel;
