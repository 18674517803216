import { NCAASports, Sites } from './common.js';
import * as lacrosselab from './lacrosselab.js';
import * as hoopslab from './hoopslab.js';
import * as footballlab from './footballlab.js';

export const SiteConstants = {
    [Sites.lacrosselab]: lacrosselab,
    [Sites.hoopslab]: hoopslab,
    [Sites.footballlab]: footballlab,
};

export const NCAASportsMap = {
    [lacrosselab.Sports.MensLax]: NCAASports.MENS_LACROSSE,
    [lacrosselab.Sports.WomensLax]: NCAASports.WOMENS_LACROSSE,
    [hoopslab.Sports.MensCollegeBBall]: NCAASports.MENS_BASKETBALL,
    [hoopslab.Sports.WomensCollegeBBall]: NCAASports.WOMENS_BASKETBALL,
};

export * from './common.js';
export * as lacrosselab from './lacrosselab.js';
export * as hoopslab from './hoopslab.js';
export * as footballlab from './footballlab.js';
