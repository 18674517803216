import { useSelector } from 'react-redux';

import { RootState } from '../../store/index.js';
import { VerifyEmail, ResetPassword } from '../index.js';

const ActionModes = {
    VerifyEmail: 'verifyEmail',
    ResetPassword: 'resetPassword',
};

const EmailActionPage: FC = () => {
    const emailAction = useSelector((state: RootState) => state.auth.emailAction);
    const { mode, oobCode } = emailAction;

    if (!mode || !oobCode) {
        return (
            <div className="p-3">
                <div className="alert alert-danger">This link is invalid or expired</div>
            </div>
        );
    }

    if (mode === ActionModes.VerifyEmail) {
        return <VerifyEmail />;
    }

    if (mode === ActionModes.ResetPassword) {
        return <ResetPassword />;
    }

    return null;
};

export default EmailActionPage;
