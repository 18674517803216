import { useState, useEffect, createContext, useContext, useRef } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'd3-dispatch';
// eslint-disable-next-line import/no-extraneous-dependencies
import * as d3 from 'd3-selection';
import { draw, DrawEvent } from 'd3-draw';

import { useFieldContainer } from './fieldContainer.js';

const SHOW_STROKES = false;

type StrokeHandler = (stroke: Point[][], rawStroke: Point[]) => any;

export interface PenStrokeValue {
    activeStroke?: DrawEvent<Element, null>[];
    simplifiedStroke?: DrawEvent<Element, null>[][];
    addStrokeHandler?: (handler: StrokeHandler) => void;
    removeStrokeHandler?: (handler: StrokeHandler) => void;
}

export const PenStrokeContext = createContext<PenStrokeValue>(null);

export const usePenStroke = (): PenStrokeValue => {
    return useContext(PenStrokeContext) ?? {};
};

export const PenStrokeProvider: FC = ({ children }) => {
    const { containerRef } = useFieldContainer();
    const strokeHandlersRef = useRef([]);

    const [stroke, setStroke] = useState([]);
    const [simplifiedStroke, setSimplifiedStroke] = useState([]);
    const strokeHandler = useRef<AnyFunction>();
    const strokeEndHandler = useRef<AnyFunction>();
    const dragRef = useRef(false);

    strokeHandler.current = (event: DrawEvent<Element, null>) => {
        if (dragRef.current) return;

        setStroke(event.stroke.slice());
    };

    strokeEndHandler.current = (event: DrawEvent<Element, null>) => {
        const { current } = dragRef;
        dragRef.current = false;

        if (current) return;

        setStroke(SHOW_STROKES ? event.stroke : []);

        if (SHOW_STROKES) {
            setSimplifiedStroke(event.simplifiedStroke);
        }

        strokeHandlersRef.current.forEach((handler) => handler(event.simplifiedStroke, event.stroke));
    };

    // eslint-disable-next-line consistent-return
    useEffect(() => {
        if (containerRef.current) {
            const drawBehavior = draw()
                // Force touchable for all devices to fix Windows 10 touch
                .touchable(() => true)
                .filter(
                    (event: PointerEvent) =>
                        SHOW_STROKES || event.pointerType === 'pen' || event.pointerType === 'touch'
                )
                .on('move', (event: DrawEvent<Element, null>) => {
                    strokeHandler.current(event);
                })
                .on('end', (event: DrawEvent<Element, null>) => {
                    strokeEndHandler.current(event);
                });

            d3.select(containerRef.current).on('d3dragstart', () => {
                dragRef.current = true;
                setStroke([]);
            });

            d3.select(containerRef.current).call(drawBehavior);

            return () => {
                d3.select(containerRef.current).on('.draw', null);
            };
        }

        return () => {
            //
        };
    }, [containerRef.current]);

    const addStrokeHandler = (handler: StrokeHandler) => {
        strokeHandlersRef.current = [...strokeHandlersRef.current, handler];
    };

    const removeStrokeHandler = (handler: StrokeHandler) => {
        strokeHandlersRef.current = strokeHandlersRef.current.filter((h) => handler !== h);
    };

    return (
        <PenStrokeContext.Provider
            value={{
                activeStroke: stroke,
                simplifiedStroke,
                addStrokeHandler,
                removeStrokeHandler,
            }}
        >
            {children}
        </PenStrokeContext.Provider>
    );
};
