import { EnhancedStore } from '@reduxjs/toolkit';

import { GeneralErrors, createException } from '@labradorsports/utils';
import Logger from './logger.js';

async function tryJSON(response, functionName) {
    try {
        const result = await response.clone().json();

        return result;
    } catch (error) {
        if (error.message === 'The string did not match the expected pattern.' || error.name === 'SyntaxError') {
            const responseText = await response.clone().text();

            if (responseText === 'Unauthorized') {
                throw createException(GeneralErrors.NO_PERMISSIONS, {
                    nestedError: error,
                    details: functionName,
                });
            }

            throw createException(GeneralErrors.UNKNOWN_ERROR, {
                nestedError: error,
                details: `${functionName} invalid response: ${responseText}`,
            });
        }

        throw error;
    }
}

export class CFFetcher {
    env: EnvSpec;

    store: EnhancedStore;

    logger: Logger;

    constructor(env: EnvSpec, logger: Logger) {
        this.env = env;
        this.logger = logger;
    }

    setStore(store: EnhancedStore) {
        this.store = store;
    }

    // Any error thrown should be converted to an Exception by the caller
    async fetch(functionName: string, params?: any, body?: any, isJSON = true): Promise<any> {
        const url = `${this.env.apiUrl}/${functionName}`;

        let query = '';

        if (params) {
            query = `?${Object.keys(params)
                .filter((k) => {
                    return params[k] !== undefined;
                })
                .map((key) => {
                    return [key, params[key]].map(encodeURIComponent).join('=');
                })
                .join('&')}`;
        }

        const reqSettings: any = {
            headers: {},
            url: url + query,
        };

        if (body) {
            reqSettings.method = 'POST';
            reqSettings.headers.Accept = 'application/json';
            reqSettings.headers['Content-Type'] = 'application/json';
            reqSettings.body = JSON.stringify(body);
        }

        const state = this.store.getState();
        const { user } = state.auth;

        if (user) {
            const token = await user.getIdToken();
            reqSettings.headers.Authorization = `Bearer ${token}`;
        }

        const response = await fetch(reqSettings.url, reqSettings);

        this.logger.log(`${functionName} response`, { status: response.status });

        const result = isJSON ? await tryJSON(response, functionName) : await response.blob();

        if (response.status === 200) {
            return result;
        }

        if (result.error) {
            throw createException(result.error);
        } else {
            throw createException(GeneralErrors.UNKNOWN_ERROR, {
                nestedError: result,
                details: 'Non-200 response.',
            });
        }
    }
}

export default CFFetcher;
