import { useSelector } from 'react-redux';
import { Link, Navigate, useLocation } from 'react-router-dom';

import { TeamUnpaid } from '@labradorsports/components';
import { useSite } from '@labradorsports/utils';
import { useDispatcher } from '../../hooks/index.js';
import { RootState, Selectors, mainActions } from '../../../store/index.js';
import { Sites } from '@labradorsports/constants';

interface Props {
    requireSubscription?: boolean;
    excludePlans?: string[];
    fallback?: any;
    requireEditRights?: boolean;
}

const PrivateRoute: FC<Props> = ({ children, requireSubscription, excludePlans = [], requireEditRights, fallback }) => {
    const { Config } = useSite();
    const setDeeplink = useDispatcher(mainActions.SetDeeplink);
    const user = useSelector(Selectors.user);
    const profileLoaded = useSelector(Selectors.profileLoaded);
    const currentTeam = useSelector(Selectors.currentTeam);
    const teamEditable = useSelector(Selectors.teamEditable);
    const teamViewable = useSelector(Selectors.teamViewable);
    const currentPendingTeam = useSelector((state: RootState) =>
        state.teams.pendingJoinTeams?.find((team) => team.id === state.teams.activeTeam)
    );

    const location = useLocation();

    if (
        // No auth event yet
        user === undefined ||
        // User logged in, profile not loaded yet
        (user !== null && !profileLoaded)
    ) {
        return null;
    }

    const { pathname, search } = location;

    // User is trying to view a private route without being logged in - redirect them to the login page
    if (user === null) {
        // Save original destination path
        setTimeout(() => {
            setDeeplink(encodeURIComponent(`${pathname}${search}`));
        });

        return <Navigate to="/login" />;
    }

    if (Config.SiteConfig.Site === Sites.footballlab && profileLoaded && !currentTeam) {
        return <Navigate to="/beta-pending" />;
    }

    // User can't edit
    if (requireEditRights && !teamEditable) {
        return <Navigate to="/feed" />;
    }

    // Team subscription expired
    if (requireSubscription) {
        if (currentPendingTeam) {
            return (
                <div className="p-3">
                    <div className="alert alert-warning">
                        Your membership to this team is still pending. Talk to your coach about approving your access.
                    </div>
                </div>
            );
        }

        if (!currentTeam) {
            // If fallback UI is specified, render it
            if (fallback) {
                return fallback;
            }

            // Otherwise, redirect to home
            return <Navigate to="/feed" />;
        }

        if (!teamViewable) {
            return <TeamUnpaid />;
        }

        if (excludePlans.includes(currentTeam.plan)) {
            return (
                <div className="p-3">
                    <div className="alert alert-warning">
                        This team&apos;s subscription does not include this feature.{' '}
                        {currentTeam.ownerId === user.uid ? (
                            <span>
                                Visit the <Link to="/profile/teamadmin">MANAGE TEAMS</Link> page to upgrade this team.
                            </span>
                        ) : (
                            'Talk to your team admin to upgrade your team and have this feature enabled.'
                        )}
                    </div>
                </div>
            );
        }
    }

    // All checks passed
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
};

export default PrivateRoute;
