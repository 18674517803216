import { useAssets } from '@labradorsports/assets';

interface Props {
    tags?: string[];
    highlightTags?: string[];
}

const PlayTagDisplay: FC<Props> = ({ tags = [], highlightTags = [] }) => {
    const { TagIcon } = useAssets();

    if (tags.length === 0) {
        return null;
    }

    return (
        <div className="play-tag-display small text-muted">
            <img className="tag-icon" src={TagIcon} alt="tag icon" />
            {tags
                .reduce((acc, tag) => {
                    return [...acc, highlightTags.includes(tag) ? <em key={Math.random()}>{tag}</em> : tag, ', '];
                }, [])
                .slice(0, -1)}
        </div>
    );
};

export default PlayTagDisplay;
