import { SimpleModal } from '@labradorsports/components';

const JOINED_AS_MAP: {
    [k: string]: string;
} = {
    ADMIN: 'an admin',
    COACH: 'a coach',
    PLAYER: 'a player',
};

const JoinTeamSuccess: FC<any> = ({ joinResult, onClose }) => {
    if (!joinResult) return null;

    if (joinResult.pending) {
        return (
            <SimpleModal onClose={onClose} open>
                <p>Your coach needs to review your membership. Check back later.</p>
            </SimpleModal>
        );
    }

    const joinedEntities = joinResult.joinedTeams ?? joinResult.joinedPrograms;
    const entityId = joinedEntities ? Object.keys(joinedEntities)[0] : null;
    const entityName = joinedEntities[entityId];

    return (
        <SimpleModal onClose={onClose} open>
            <p>
                Successfully joined <em>{entityName}</em> as {JOINED_AS_MAP[joinResult.joinedAs]}
            </p>
        </SimpleModal>
    );
};

export default JoinTeamSuccess;
