import { ReactElement } from 'react';

import { ButtonsBar, Panel, Flyover } from '../index.js';

interface Props {
    header: ReactElement;
    buttons?: ReactElement;
    className?: string;
    in: boolean;
    position?: 'right' | 'bottom' | 'left';
}

const FlyoverPanel: FC<Props> = ({ in: animationIn, header, children, buttons, className, position = 'left' }) => {
    return (
        <Flyover in={animationIn} position={position}>
            <Panel
                noPadding
                className={className}
                header={header}
                content={children}
                footer={<ButtonsBar>{buttons}</ButtonsBar>}
            />
        </Flyover>
    );
};

export default FlyoverPanel;
