import { EnhancedStore } from '@reduxjs/toolkit';

import { Exception } from '@labradorsports/utils';
import { getSettings } from '../environment.js';
import { Selectors } from '../store/index.js';

const FLUSH_TIMER = 60 * 1000;

export class Logger {
    logs: {
        level: 'info' | 'warn';
        message: string;
        data?: Record<string, any>;
        route: string;
        uid: string;
        timestamp: string;
    }[] = [];

    flushTimeout: number;

    env: EnvSpec;

    store: EnhancedStore;

    constructor(env: EnvSpec) {
        this.env = env;
    }

    setStore(store: EnhancedStore) {
        this.store = store;
    }

    _log = (level: 'info' | 'warn', message: string, data?: Record<string, any>) => {
        // eslint-disable-next-line no-console
        if (!PROD) console.log(message, APP ? JSON.stringify(data) : data);

        const state = this.store.getState();

        this.logs.push({
            level,
            message,
            data,
            route: APP ? window.location.hash : window.location.pathname,
            uid: state?.auth.user?.uid,
            timestamp: new Date().toISOString().slice(11),
        });

        if (!this.flushTimeout) {
            this.startFlushTimer();
        }
    };

    log = (message: string, data: Record<string, any> = null) => {
        this._log('info', message, data);
    };

    warn = (message: string, data: Record<string, any> = null) => {
        this._log('warn', message, data);
    };

    startFlushTimer = () => {
        this.flushTimeout = window.setTimeout(this.flush, FLUSH_TIMER);
    };

    exception = (exc: Exception) => {
        if (exc.level === 'error') {
            this.error(exc);
        } else {
            this.warn(exc.fullMessage(), exc.toJSON(this.env.baseUrl));
        }
    };

    error = (error: Exception) => {
        // eslint-disable-next-line no-console
        if (APP) console.log(error.message ?? error);

        this.log(error.message);
        this.flush(error);

        // Send state and actionLog separately to make sure the error doesn't get lost
        this.log('state', Selectors.loggableState(this.store.getState()));
        this.log('actionLog', { actionLog: this.store.getState().main.actionLog });
        this.flush();
    };

    flush = (error?: Exception) => {
        if (!error && this.logs.length === 0) {
            this.startFlushTimer();
            return;
        }

        if (this.flushTimeout) {
            window.clearTimeout(this.flushTimeout);
        }

        const state = this.store.getState();
        const settings = this.env;

        const body: any = {
            userAgent: window.navigator.userAgent,
            route: window.location.pathname,
            uid: state?.auth.user?.uid,
            logs: this.logs,
            version: getSettings().version,
            buildId: BUILD,
        };

        if (error) {
            body.error = error.toJSON(settings.baseUrl);
        }

        const url = `${settings.apiUrl}/main/clientLog`;

        fetch(url, {
            method: 'POST',
            keepalive: true,
            body: JSON.stringify(body),
        });

        this.logs = [];
        this.startFlushTimer();
    };
}

export default Logger;
