import { Move } from '@labradorsports/play-rendering';
import { getCDNUrl } from '@labradorsports/utils';

export function linePlayerId(line: Move, i: number): number {
    return (line.id + 1) * 1000 + i;
}

export function getAssetUrl(getter: AssetUrlGetter | string): string {
    const domain = getCDNUrl(APP, PROD);

    return typeof getter === 'string' ? getter : getter(domain);
}

export function trackUserProperties(uid: string, properties: any) {
    window.StonlyWidget('identify', uid, properties);
}
